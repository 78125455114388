<template>
  <main>
    <div class="content">
      <div class="row">
        <div class="col-lg-6 wideTable">
          <div class="card-box">
            <div class="card-block">
              <h4 class="card-title">Hospital Patients Record</h4>
              <button class="add-mothers" @click="isCreateMother = true">
                Add Patients +
              </button>
              <div class="search-box">
                <input
                  type="text"
                  placeholder="Search for Mothers"
                  v-model="searchQuery"
                />
              </div>
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Tamela ID</th>
                      <th>Surname / Lastname</th>
                      <th>Firstname</th>
                      <th>Othername</th>
                      <th>Age</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Date Added</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="patients && patients.length >= 1">
                    <tr
                      v-for="(patient, index) in patients"
                      :key="index"
                      v-show="isMatch(patient)"
                    >
                      <td @click="selectedMotherData = patient">
                        {{ patient.tamelaID }}
                      </td>
                      <td>{{ patient.firstname }}</td>
                      <td>{{ patient.lastname }}</td>
                      <td>{{ patient.othername }}</td>
                      <td>{{ patient.age }}</td>
                      <td>{{ patient.city }}</td>
                      <td>{{ patient.state }}</td>
                      <td>
                        {{ moment(`${patient.date_added}`).format("DD MMM, YYYY") }}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-modal" v-if="selectedMotherData">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"
            ><svg
              @click="selectedMotherData = null"
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
            >
              <path
                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"
              /></svg
          ></span>
        </div>

        <div class="center-img">
          <div class="imgbox" v-if="selectedMotherData.mother_image">
            <img class="profile" :src="selectedMotherData.mother_image" alt="" />
          </div>
        </div>
        <div class="items">
          <h3 class="title">
            {{ selectedMotherData.lastname }} {{ selectedMotherData.firstname }}
            {{ selectedMotherData.othername }}
          </h3>

          <h3>
            Email Address:
            <span style="text-transform: lowercase"
              ><a :href="`mailto:${selectedMotherData.email}`">{{
                selectedMotherData.email
              }}</a></span
            >
          </h3>
          <h3>
            Phone Number: <span>{{ selectedMotherData.phone_number }}</span>
          </h3>
          <h3>
            Age: <span>{{ selectedMotherData.age }}</span>
          </h3>
          <h3>
            city: <span>{{ selectedMotherData.city }}</span>
          </h3>
          <h3>
            state: <span>{{ selectedMotherData.state }}</span>
          </h3>
          <h3>
            address: <span>{{ selectedMotherData.address }}</span>
          </h3>
          <h3>
            date added:
            <span>{{
              moment(`${selectedMotherData.date_added}`).format("DD MMM, YYYY")
            }}</span>
          </h3>
        </div>
        <div class="split-options" style="display:flex;justify-content: space-between;">
          <button @click="goToScan(selectedMotherData)">Upload Scan</button>
          <button @click="showScan(selectedMotherData)">View Scan History</button>
        </div>
      </div>
    </div>

    <div
      class="content-modal"
      style="z-index: 1000"
      v-if="isScanOpen && selectedMotherData"
    >
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"
            ><svg
              @click="isScanOpen = null"
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
            >
              <path
                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"
              /></svg
          ></span>
        </div>

        <div class="upload-div">
          <h3 class="title">Upload Ultrasound Scan for smart analysis</h3>

          <form class="form-data" id="upload-form" ref="scanform">
            <div class="field">
              <label for="">Name</label>
              <input
                type="text"
                name=""
                id=""
                :value="
                  selectedMotherData.lastname.toUpperCase() +
                  ' ' +
                  selectedMotherData.firstname.toUpperCase() +
                  ' ' +
                  selectedMotherData.othername.toUpperCase()
                "
                disabled
              />
            </div>
            <div class="field">
              <label for="">Tamela ID</label>
              <input
                type="text"
                name=""
                id=""
                :value="selectedMotherData.tamelaID"
                disabled
              />
            </div>
            <div class="field">
              <label for="">Foetus age in weeks</label>
              <input
                type="number"
                name=""
                id=""
                placeholder=""
                min="0"
                max="52"
                v-model="foetusAge"
                @input="foetusAge = foetusAge < 0 ? 0 : foetusAge > 52 ? 52 : foetusAge"
                required
              />
            </div>
            <div class="field">
              <label for="">Foetus label</label>
              <input type="text" name="" id="" value="" v-model="foetusLabel" />
            </div>

            <p>Image should not exceed {{ maxSize / (1024 * 1024) }}MB</p>
            <div class="field">
              <label for="">Ultrasound Image</label>
              <input
                type="file"
                accept="image/jpeg, image/png"
                name=""
                id=""
                value=""
                @change="handleImageChange"
                required
              />
            </div>
          </form>
          <div class="image-show" v-if="imageUrl">
            <img :src="imageUrl" alt="" />
          </div>
          <button @click="uploadImage()" :disabled="!image">Upload Image</button>
        </div>
      </div>
    </div>

    <div class="content-modal" v-if="isCreateMother">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"
            ><svg
              @click="isCreateMother = false"
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
            >
              <path
                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"
              /></svg
          ></span>
        </div>
        <div id="reg">
          <form
            class="add-mother-form"
            @submit.prevent="submitForm"
            id="add-mother-form"
            ref="addMotherForm"
          >
            <h3 class="title">Add Mothers</h3>
            <div class="field2">
              <input
                type="text"
                placeholder="Surname/Lastname"
                v-model="surname"
                required
              />
              <input type="text" placeholder="Firstname" v-model="firstname" required />
            </div>
            <div class="field2">
              <input type="text" placeholder="Othername" v-model="othername" />
              <input
                type="number"
                placeholder="Age"
                v-model="age"
                @input="age = age < 0 ? 0 : age"
                required
              />
            </div>
            <input
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="Phone Number"
              v-model="phoneNumber"
              required
            />
            <input
              type="email"
              placeholder="Email"
              autocomplete="off"
              v-model="email"
              required
            />
            <div class="field2">
              <input type="text" placeholder="City" v-model="city" required />
              <input type="text" placeholder="State" v-model="state" required />
            </div>
            <textarea
              name=""
              id=""
              cols="30"
              rows="3"
              placeholder="Home Address"
              v-model="homeAddress"
              required
            ></textarea>
            <input
              type="file"
              @change="handleFileChange"
              accept="image/jpeg, image/png, image/gif"
            />
          </form>
          <button @click="createMother">Add Mother +</button>
          <!--<button @click="
          addGoal('2',
          '34',
          'goal summary',
          'achievementApproachVALUE test',
          'required resources value',
          'success Criteria',
          'potential challenges',
          'the solution to challenges is money2',
          '2024-08-15',
          '2024-08-15',
          'progress metrics',
          'status',
          'feedback',
          imageFile,
          'admin',
          'admin',
          'admin')">test +</button>-->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
var moment = require("moment");

export default {
  name: "mothers",
  components: {},

  data() {
    return {
      patients: [],
      moment: moment,
      selectedMotherData: null,
      searchQuery: "",
      isScanOpen: false,
      foetusAge: "",
      foetusLabel: "",
      image: null,
      imageUrl: null,
      maxSize: 2 * 1024 * 1024,
      age: "",
      isCreateMother: false,
      surname: "",
      firstname: "",
      othername: "",
      phoneNumber: "",
      email: "",
      city: "",
      state: "",
      homeAddress: "",
      imageFile: null,
      formData: null,
      motherFormData: null,
    };
  },
  computed: {},
  mounted() {
    if (this.$store.state.doctor_username) {
      this.$router.push("/doctor");
    } else {
      document.title = "Tamela Dashboard | Mothers";

      this.$store.commit("currentPage", "mothers");
      document.querySelector(".page-wrapper").style.paddingTop = "50px";
      this.$root.loadScripts();
      this.$root.resetSideBar();
      this.getPatient();
      this.$root.showLoader = true;
    }
  },
  methods: {
    async getPatient(pk = null) {
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      if (!pk) {
        await axios
          .get("accounts/mothers", { headers })
          .then((response) => {
            this.patients = response.data.data;
            //console.log(response.data)
            this.$root.showLoader = false;
          })
          .catch((error) => {
            if (error.code != "ERR_NETWORK") {
              if (error.response.status === 401) {
                this.refreshToken(this.getPatient);
              }
            } else {
              console.error(error);
              const error_message =
                error.code === "ERR_NETWORK"
                  ? "Unable to Connect. Check your network connection."
                  : error.code;
              toast.error(error_message, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          });
      }
    },
    isMatch(patient) {
      const searchLowerCase = this.searchQuery.toLowerCase();
      return (
        patient.tamelaID.includes(this.searchQuery.toUpperCase()) ||
        patient.firstname.toLowerCase().includes(searchLowerCase) ||
        patient.lastname.toLowerCase().includes(searchLowerCase) ||
        patient.othername.toLowerCase().includes(searchLowerCase) ||
        patient.city.toLowerCase().includes(searchLowerCase) ||
        patient.state.toLowerCase().includes(searchLowerCase)
      );
    },
    async sendImage(formData) {
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      console.log(formData);
      await axios
        .post("api/v1/scan", formData, { headers })
        .then((response) => {
          console.log(response.data);
          if (response.data.status) {
            // Clear image and preview after successful upload
            toast.success("Scan Uploaded", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
            this.image = null;
            this.imageUrl = null;
            this.isScanOpen = false;
          }
        })
        .catch((error) => {
          //console.log('error',error)
          if (error.code != "ERR_NETWORK") {
            if (error.response.status === 401) {
              this.refreshToken(this.sendImage(formData));
            }
          } else {
            console.error(error);
            const error_message =
              error.code === "ERR_NETWORK"
                ? "Unable to Connect. Check your network connection."
                : error.code;
            toast.error(error_message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    },
    showScan(obj){
      this.$store.commit('selectPatient',obj);
      if(obj !== null){
        this.$router.push('/motherscans');
      }
      
    },
    goToScan(obj){
      this.$store.commit('currentPage','models')
      this.$store.commit('selectPatient',obj);
      this.$router.push('/models')
    },
    async uploadImage() {
      const scanForm = this.$refs.scanform;
      if (!scanForm.checkValidity()) {
        scanForm.reportValidity();
      } else {
        if (this.image) {
          const formData = new FormData();
          formData.append("image", this.image);
          formData.append("uid", this.selectedMotherData.uuid);
          formData.append("foetus_age", this.foetusAge);
          formData.append("foetus_label", this.foetusLabel);
          

          const accessToken = this.$store.state.access;
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data", // Change content type to multipart/form-data
          };

          try {
            // Use await instead of .then to handle async operations
            const response = await axios.post("api/v1/scan", formData, { headers });
            console.log(response.data);
            if (response.data.status) {
              // Clear image and preview after successful upload
              toast.success("Scan Uploaded", {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
              });
              this.image = null;
              this.imageUrl = null;
              this.isScanOpen = false;
            }
          } catch (error) {
            // Handle errors
            if (error.code != "ERR_NETWORK") {
              if (error.response.status === 401) {
                this.refreshToken(this.uploadImage);
              }
            } else {
              console.error(error);
              const error_message =
                error.code === "ERR_NETWORK"
                  ? "Unable to Connect. Check your network connection."
                  : error.code;
              toast.error(error_message, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        } else {
          console.error("No image selected.");
        }
      }
    },

    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > this.maxSize) {
          toast.error("Max file size exceeded. Please select a smaller image.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
        this.image = file;
        // Display image preview
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > this.maxSize) {
          toast.error("Max file size exceeded. Please select a smaller image.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
        this.imageFile = file;
      }
    },
    async refreshToken(func) {
      await axios
        .post("api/token/refresh", {
          refresh: this.$store.state.refresh,
        })
        .then((response) => {
          ////console.log(response.data)
          if (response.data.access) {
            this.$store.commit("setAccess", response.data.access);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.access;
            func();
          } else {
            //this.router.push('/login')
          }
        })
        .catch((error) => {
          //console.log(error)
        });
    },
    async sendNewMother() {
      const formData = this.motherFormData;
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      };

      await axios
        .post("accounts/mothers", this.motherFormData, { headers })
        .then((response) => {
          this.getPatient();
          if (response.data.status) {
            // Clear image and preview after successful upload

            toast.success("Mother Added", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });

            this.isCreateMother = false;
            this.surname = "";
            this.firstname = "";
            this.othername = "";
            this.phoneNumber = "";
            this.email = "";
            this.city = "";
            this.state = "";
            this.homeAddress = "";
            this.imageFile = null;
          } else {
            toast.error(response.data.message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          if (error.code != "ERR_NETWORK") {
            if (error.response.status === 401) {
              this.refreshToken(this.sendNewMother);
            }
          } else {
            console.error(error);
            const error_message =
              error.code === "ERR_NETWORK"
                ? "Unable to Connect. Check your network connection."
                : error.code;
            toast.error(error_message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    },
    createMother() {
      const addMotherForm = this.$refs.addMotherForm;
      if (!addMotherForm.checkValidity()) {
        addMotherForm.reportValidity();
      } else {
        const formData = new FormData();
        formData.append("lastname", this.surname);
        formData.append("firstname", this.firstname);
        formData.append("othername", this.othername);
        formData.append("age", this.age);
        formData.append("phone_number", this.phoneNumber);
        formData.append("email", this.email);
        formData.append("city", this.city);
        formData.append("state", this.state);
        formData.append("address", this.homeAddress);
        formData.append("image", this.imageFile);

        //console.log(formData)
        this.motherFormData = formData;
        this.sendNewMother();
      }
    },
    async addGoal(planTypeId, planTermId, goalSummary, achievementApproach, requiredResources, successCriteria, potentialChallenges, solutionToChallenges, targetCompletionDate, actualCompletionDate, progressMetrics, status, feedback, evidenceURL, createdBy, lastModifiedBy, recordOwner) {
  try {
    // Convert planTypeId and planTermId to integers if they are strings
    planTypeId = planTypeId === 'Select Type' ? null : parseInt(planTypeId);
    planTermId = planTermId === 'Select Term' ? null : parseInt(planTermId);

    const payload = {
      planTypeId,
      planTermId,
      goalSummary,
      achievementApproach,
      requiredResources,
      successCriteria,
      potentialChallenges,
      solutionToChallenges,
      targetCompletionDate,
      actualCompletionDate,
      progressMetrics,
      status,
      feedback,
      evidenceURL,
      createdBy,
      lastModifiedBy,
      recordOwner
    };

    // Log the payload to the console
    console.log('Submitting payload:', payload);

    const formData = new FormData();
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined && payload[key] !== null) {
        formData.append(key, payload[key]);
      }
    });

    const response = await axios.post("https://infracredit.pythonanywhere.com/api/v1/development-plans", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });

    console.log('Server response:', response);

    if (response.data) {
      // this.goals.push(response.data);
      console.log('Goal added:', response.data);
    } else {
      console.error('Server response data is empty');
    }
  } catch (error) {
    if (error.response) {
      console.error('Error adding goal:', error.response.data);
    } else {
      console.error('Error adding goal:', error);
    }
  }
},

  },
};
</script>

<style lang="scss" scoped>
.content-modal .modal-content .add-mother-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .field,
  .field2 {
    display: flex;
    width: 100%;
  }

  .split-options {
    display: flex;
    justify-content: space-between;

    
  }

  input,
  textarea {
    resize: none;
    width: 100%;
    margin: 0.2rem;
    padding: 0.5rem;
    border-radius: var(--border-radius-1);
    background: var(--light);
    color: var(--dark);
    margin-top: 0.5rem;
    border: 1px solid transparent;
  }
}

.card-title {
  font-size: 20px;
}
.search-box {
  margin-bottom: 1rem;
  input {
    background-color: var(--light);
    color: var(--dark);
    width: 100%;
    max-width: 40rem;
    border: 1px solid transparent;
    border-radius: var(--border-radius-1);
    padding: 0.5rem;
  }
}

.wideTable {
  width: 100% !important;
  max-width: fit-content;

  table th {
    color: var(--dark);
  }
  .table-responsive tbody tr:hover {
    background: var(--light);
  }
  tr td {
    text-transform: capitalize;
    color: var(--black);
  }
  tr td:first-child {
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
  }
}

.add-mothers {
  margin: 1rem 0;
  border-radius: var(--border-radius-1);
  border: 2px dotted rgb(224, 104, 58);
  padding: 0.5rem 1rem;
  font-size: 14px;
  color: var(--white);
  background: rgb(224, 104, 58);
  cursor: pointer;

  &:hover {
    color: rgb(224, 104, 58);
    background: var(--background);
  }
}

.content-modal {
  display: flex;
  justify-content: center;
  padding: 1rem;
  padding-top: 5rem;
  width: 100vw;
  height: 100vh;
  background: var(--modal-background);
  position: fixed;
  top: 0;
  z-index: 999;

  .modal-content {
    width: 100%;
    max-width: 30rem;
    height: fit-content;
    background: var(--white);
    padding: 1rem;
    border-radius: var(--card-border-radius);

    .close-button {
      .svg-icon {
        cursor: pointer;
        svg {
          fill: #e32;
          width: 2rem;
          height: 2rem;
        }
      }
    }

    .center-img {
      display: flex;
      width: 100%;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    button {
      border: 1px solid transparent;
      padding: 0.5rem 1rem;
      font-size: 16px;
      color: var(--white);
      background: var(--primary);
      border-radius: var(--card-border-radius);
      margin-top: 2rem;

      &:hover {
        background: var(--white);
        color: var(--primary);
        border: 1px solid var(--primary);
      }
    }

    .items {
      h3 {
        color: var(--dark);
        text-transform: capitalize;
        font-size: 14px;

        &.title {
          font-size: 20px;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;
        }
        span {
          color: var(--dark);
          font-weight: 600;
        }
      }
    }

    .imgbox {
      width: 10rem;
      height: 10rem;
      position: relative;
      overflow: hidden;
      border-radius: 50%;

      margin-bottom: 1rem;

      img.profile {
        width: 100%;

        object-fit: cover;
      }
    }
  }
}

button:disabled {
  opacity: 0.8;
}

.upload-div {
  .image-show {
    width: 100%;
    justify-content: center;
    display: flex;
    position: relative;

    img {
      width: 150px;
      height: 150px;

      object-fit: cover;
    }
  }

  .title {
    margin: 1rem 0 2rem 0;
    text-transform: capitalize;
    font-weight: 600;
    color: var(--dark);
  }

  p {
    color: var(--dark);
    margin-top: 2rem;
  }

  .form-data {
    width: 100%;
    position: relative;

    .field {
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 30% 70%;
      margin: 0.5rem 0;
      align-items: center;

      &:not(:last-child) {
      }

      input[type="text"],
      input[type="number"] {
        padding: 0.5rem;
        max-height: 40px;
        border: 1px solid transparent;
        border-radius: var(--border-radius-1);
        background: var(--light);
        color: var(--dark);

        :disabled {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    width: 100%;
    padding: 0;
  }
}
@media screen and (min-width: 800px) {
  .card-box {
    width: max-content;
  }
  .content {
    padding: 1rem !important;
  }
}
@media screen and (max-width: 600px) {
  .modal-content {
    #reg {
      overflow-y: auto;
      max-height: 35rem;
    }
    button {
      width: 100%;
    }
  }
  .content-modal .modal-content .add-mother-form {
    input {
      width: 100%;
    }
    .field {
      flex-direction: column;
      width: 100%;
    }
  }
}
</style>
