<template>
  <div class="main-wrapper">

    <div class="header" v-if="isAuthenticated">
			<div class="header-left">
				
			</div>

			<a id="toggle_btn" href="javascript:void(0);"><i class="fa fa-bars"></i></a>
            <a @click="sideBarOpen = !sideBarOpen" id="mobile_btn" class="mobile_btn float-left" href="#sidebar"><i class="fa fa-bars"></i></a>
            <ul class="nav user-menu float-right" >
              <li class="toggler" style="margin-right:1rem">
                        <div class="theme-toggler" :class="{'isDark':isDark}" @click="isDark = !isDark;setTheme()">
                          <div class="backgrounds">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM200-440H40v-80h160v80Zm720 0H760v-80h160v80ZM440-760v-160h80v160h-80Zm0 720v-160h80v160h-80ZM256-650l-101-97 57-59 96 100-52 56Zm492 496-97-101 53-55 101 97-57 59Zm-98-550 97-101 59 57-100 96-56-52ZM154-212l101-97 55 53-97 101-59-57Zm326-268Z"/></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z"/></svg>
                          </div>
                            <span class="toggle"></span>
                        </div>
                      </li>
                <li class="nav-item dropdown d-none d-sm-block" style="display:none !important">
                    <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown"><i class="fa fa-bell-o"></i> <span class="badge badge-pill bg-danger float-right secondary">3</span></a>
                    <div class="dropdown-menu notifications">
                        <div class="topnav-dropdown-header">
                            <span>Notifications</span>
                        </div>
                        <div class="drop-scroll">
                            <ul class="notification-list">
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
											<span class="avatar">
												<img alt="John Doe" v-if="userImage" :src="userImage" class="img-fluid">
												<img alt="John Doe" v-else :src="require('./assets/img/user.jpg')" class="img-fluid">
											</span>
											<div class="media-body">
												<p class="noti-details"><span class="noti-title">John Doe</span> added new task <span class="noti-title">Patient appointment booking</span></p>
												<p class="noti-time"><span class="notification-time">4 mins ago</span></p>
											</div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
											<span class="avatar">V</span>
											<div class="media-body">
												<p class="noti-details"><span class="noti-title">Tarah Shropshire</span> changed the task name <span class="noti-title">Appointment booking with payment gateway</span></p>
												<p class="noti-time"><span class="notification-time">6 mins ago</span></p>
											</div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
											<span class="avatar">L</span>
											<div class="media-body">
												<p class="noti-details"><span class="noti-title">Misty Tison</span> added <span class="noti-title">Domenic Houston</span> and <span class="noti-title">Claire Mapes</span> to project <span class="noti-title">Doctor available module</span></p>
												<p class="noti-time"><span class="notification-time">8 mins ago</span></p>
											</div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
											<span class="avatar">G</span>
											<div class="media-body">
												<p class="noti-details"><span class="noti-title">Rolland Webber</span> completed task <span class="noti-title">Patient and Doctor video conferencing</span></p>
												<p class="noti-time"><span class="notification-time">12 mins ago</span></p>
											</div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
											<span class="avatar">V</span>
											<div class="media-body">
												<p class="noti-details"><span class="noti-title">Bernardo Galaviz</span> added new task <span class="noti-title">Private chat module</span></p>
												<p class="noti-time"><span class="notification-time">2 days ago</span></p>
											</div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="topnav-dropdown-footer">
                            <a href="activities.html">View all Notifications</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown d-none d-sm-block" style="display:none !important">
                    <a href="javascript:void(0);" id="open_msg_box" class="hasnotifications nav-link"><i class="fa fa-comment-o"></i> <span class="badge badge-pill bg-danger float-right secondary">8</span></a>
                </li>
                <li class="nav-item dropdown has-arrow" >
                    <a href="#" class="dropdown-toggle nav-link user-link" data-toggle="dropdown">
                        <span class="user-img">
							<img class="rounded-circle" :src="userImage || require('./assets/img/user.jpg')" width="24" alt="Admin">
							<span class="status online"></span>
						</span>
						<span>{{user}}</span>
                    </a>
					<div class="dropdown-menu">
            <router-link to="/profile" class="dropdown-item">My Profile</router-link>
						<!--
            <a class="dropdown-item" href="edit-profile.html">Edit Profile</a>
						<a class="dropdown-item" href="settings.html">Settings</a>-->
						<router-link class="dropdown-item" to="/login">Logout</router-link>
					</div>
                </li>
            </ul>
            <div class="dropdown mobile-user-menu float-right">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                    <router-link to="/profile" class="dropdown-item">My Profile</router-link>
                    <!--
                    <a class="dropdown-item" href="edit-profile.html">Edit Profile</a>
                    <a class="dropdown-item" href="settings.html">Settings</a>-->
                    <router-link class="dropdown-item" to="/login">Logout</router-link>
                </div>
            </div>
      </div>

      <div class="sidebar" id="sidebar" v-if="isAuthenticated">
          <div class="sidebar-inner slimscroll">
              <div id="sidebar-menu" class="sidebar-menu">
                  <ul>
                      <li class="menu-title"><div class="logo">
                        <img v-if="!$store.state.isDark" :src="require('./assets/img/logo-main.png')" alt="" width="120">
                        <img v-else :src="require('./assets/img/logo-main-orange.png')" alt="" width="120">
                      </div></li>
                      <li class="toggler" style="margin-right:1rem">
                        <div class="theme-toggler" :class="{'isDark':isDark}" @click="isDark = !isDark;setTheme()">
                          <div class="backgrounds">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM200-440H40v-80h160v80Zm720 0H760v-80h160v80ZM440-760v-160h80v160h-80Zm0 720v-160h80v160h-80ZM256-650l-101-97 57-59 96 100-52 56Zm492 496-97-101 53-55 101 97-57 59Zm-98-550 97-101 59 57-100 96-56-52ZM154-212l101-97 55 53-97 101-59-57Zm326-268Z"/></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z"/></svg>
                          </div>
                            <span class="toggle"></span>
                        </div>
                      </li>

                      <li v-if="$store.state.usercat =='admin' || $store.state.usercat == 'hospitaladmin'" :class="{'active':$store.state.currentPage==='dashboard'}">
                          <router-link to="/">
                            <i class="fa-solid fa-table-columns"></i> <span>Dashboard</span>
                          </router-link>
                      </li>
                      <li v-if="$store.state.usercat =='hospitaladmin' || $store.state.usercat =='doctor'" :class="{'active':$store.state.currentPage==='models'}">
                          <router-link to="/models">
                            <i class="fa-solid fa-notes-medical"></i> <span>Models</span>
                          </router-link>
                      </li>
                   
                      <li v-if="$store.state.usercat =='hospitaladmin' || $store.state.usercat =='doctor'" :class="{'active':$store.state.currentPage==='mothers'}">
                         <router-link to="/mothers">
                          <i class="fa-solid fa-person-pregnant"></i> <span>Mothers</span>
                          </router-link>
                          
                      </li>
                      <li v-if="$store.state.usercat =='hospitaladmin' || $store.state.usercat =='doctor'" :class="{'active':$store.state.currentPage==='scans'}">
                          <router-link to="/scans">
                            <i class="fa-solid fa-expand"></i> <span>Scans</span>
                          </router-link>
                      </li>
                      <li v-if="$store.state.usercat =='hospitaladmin'" :class="{'active':$store.state.currentPage==='managedoctors'}">
                          <router-link to="/managedoctors">
                            <i class="fa-solid fa-user-doctor"></i> <span>Doctors</span>
                          </router-link>
                      </li>
                     
                      <li v-if="$store.state.usercat =='adminL'" :class="{'active':$store.state.currentPage==='staff'}">
                          <router-link to="/staff">
                            <i class="fa-solid fa-clipboard-user"></i> <span>Staff</span>
                          </router-link>
                      </li>
                      <li v-if="$store.state.usercat =='admin'" :class="{'active':$store.state.currentPage==='hospitals'}">
                          <router-link to="/hospitals">
                            <i class="fa fa-hospital-o"></i> <span>Hospitals</span>
                          </router-link>
                      </li>
                      <li v-if="$store.state.usercat =='adminL'" :class="{'active':$store.state.currentPage==='ngo'}">
                          <router-link to="/scans">
                            <i class="fa-solid fa-building-ngo"></i> <span>NGOs</span>
                          </router-link>
                      </li>
                      <li v-if="$store.state.usercat =='doctor' && $store.state.doctor_username" :class="{'active':$store.state.currentPage==='doctor'}">
                          <router-link to="/doctor">
                            <i class="fa-solid fa-expand"></i> <span>Scans</span>
                          </router-link>
                      </li>
                      <li v-if="$store.state.usercat =='doctor' && $store.state.doctor_username" :class="{'active':$store.state.currentPage==='history'}">
                          <router-link to="/history">
                            <i class="fa-solid fa-clock-rotate-left"></i> <span>Result History</span>
                          </router-link>
                      </li>
                      
                  </ul>
              </div>
          </div>
      </div>


    <div class="page-wrapper">

      <div class="modal ai" :class="{'show':ai_toggle}">
        <div class="modal-content">
          <div class="top-header">
            <span class="back" @click="ai_toggle=false"><i class="fa-solid fa-chevron-left"></i></span>
            <span class="img-profile"><i class="fa-solid fa-robot"></i></span>
            <span class="title">AI Buddy</span>
          </div>
          <div class="text-window" ref="text_window">
           
            <div v-if="chatMessages.length > 0">
              <div v-for="messages,index in chatMessages" :key="index">
                <div :class="{'user-text':messages.user=='user','sender-text':messages.user!='user'}"><span class="text">{{messages.message}}</span></div>
                
              </div>
              <div class="sender-text loader" v-if="is_thinking">
                
                  <span class="circle"></span>
                  <span class="circle"></span>
                  <span class="circle"></span>
              </div>
            </div>
          </div>
          <div class="sender-bar"  @keyup.enter="sendMessage()" >
            <input type="text" placeholder="Type here..." v-model="currentMessage">
            <button @click="sendMessage"><i class="fa-solid fa-paper-plane"></i></button>
          </div>
        </div>
      </div>
      <keep-alive>
        <router-view/>
      </keep-alive>
      <div v-if="$store.state.usercat =='hospitaladmin' || $store.state.usercat =='doctor'">
          <span class="ai-helper" @click="ai_toggle=true;scrollTextWindowUp();"><i class="fa-solid fa-robot"></i></span>
      </div>
    </div>

    <div class="loading-modal" :class="{'showform':showLoader}" id="loading" v-if="showLoader">
			<div class="loader"></div>
		</div>

    
    <div class="sidebar-overlay" data-reff="#sidebar"></div>
    
  </div>
  
</template>


<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { ref } from 'vue';
import store from './store';

export default {
  data() {
    return {
      isAuthenticated: false,
        showLoader: false,
        loadedScripts: false,
        sideBarOpen: false,
      currentPage: 'dashboard',
      isDark: this.$store.state.isDark || false,
      user: this.$store.state.user || '',
      userImage:this.$store.state.userImage || null,
      ai_toggle: false,
      chatMessages: [...this.$store.state.botText],
      currentMessage: '',
      is_thinking:false,
    }
  },
  setup(){
    const userImage = ref(null)
    userImage.value = store.state.userImage
  },
  mounted() {
    //this.loadScripts();
    this.setTheme();
    this.checkAuth();
    //this.speak('hello')
    
  },
  methods: {
    preloadImage(src) {
        const img = new Image();
        img.src = src;
      },
    toggleAIbutton(){
      this.ai_toggle = !this.ai_toggle
    },
    scrollTextWindowUp(){
      const text_window = document.querySelector('.text-window');//this.$refs.text_window;
      text_window.scrollTo(0, text_window.scrollHeight);
      console.log(text_window.scrollHeight)
    },
    async refreshToken(callback) {
      try {
        const response = await axios.post("api/token/refresh", {
          refresh: this.$store.state.refresh,
        });

        if (response.data.access) {
          this.$store.commit("setAccess", response.data.access);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.access;
          await callback();
        } else {
          this.$router.push("/login");
        }
      } catch (error) {
        console.error("Failed to refresh token", error);
        this.$router.push("/login");
      }
    },
    speak(text) {
      try{
        if ('speechSynthesis' in window) {
          const utterance = new SpeechSynthesisUtterance(text);

          // Optional: Set voice properties to sound more human-like
          utterance.pitch = 1.0; // Range: 0 (low) to 2 (high)
          utterance.rate = 1.0; // Range: 0.1 (slow) to 10 (fast)
          utterance.volume = 1.0; // Range: 0 (mute) to 1 (full volume)

          // Select a human-like voice, if available
          const voices = speechSynthesis.getVoices();
          utterance.voice = voices.find(voice => voice.name.includes('Google US English')) || voices[2];

          // Speak the text
          speechSynthesis.speak(utterance);
          

          // Ensure voices are loaded before selecting one
          window.speechSynthesis.onvoiceschanged = function() {
          const voices = speechSynthesis.getVoices();
          console.log(voices); // This logs available voices to the console
          };
      } else {
        alert('Sorry, your browser does not support text-to-speech.');
      }
      } catch(error){
        console.log(error);
      }
        },
    async sendMessage(text=null){

      let currentMessage = this.currentMessage ||  ""
      if(text){
        currentMessage=text;
      } else {
        currentMessage=this.currentMessage;
      }

      if(!currentMessage){
        return;
      }

      let messages = []
      for(let x=0;x < this.chatMessages.length;x++){
        let info = this.chatMessages[x];
        messages.push(`user:${info.user},message:${info.message}`)
        console.log(`user:${info.user},message:${info.message}`)
      }
      const data = {
        message: messages,
        text: currentMessage,
        usercat:this.$store.state.usercat
      }
      console.log(data);

      this.chatMessages.push({
        user:'user',
        message:currentMessage
      })

      

      this.$store.commit('setChatMessages',{
        user:'user',
        message:currentMessage
      })
      this.currentMessage = ""
      this.is_thinking = true;
      this.scrollTextWindowUp();
      
      
      try {
        const accessToken = this.$store.state.access;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

            // Use await instead of .then to handle async operations
            const response = await axios.post('api/v1/aiBuddy', data, { headers });
            console.log(response.data);
            if (response.data.status) {
              // Clear image and preview after successful upload
              this.is_thinking = false;
              //this.speak(response.data.message)

              this.chatMessages.push({
                user:'model',
                message:response.data.message
              })

              this.$store.commit('setChatMessages',{
                user:'model',
                message:response.data.message
              })
              
              //toast.success("Message Sent", { autoClose: 1500, position: toast.POSITION.TOP_RIGHT });
              
                //this.showVitalModal = false;
            }
      } catch (error) {
        console.error(error);
        // Handle errors
        if (error.code != 'ERR_NETWORK') {
          if (error.response.status === 401) {
            this.refreshToken(this.sendMessage(currentMessage));
          }
        } else {
          
          this.chatMessages.push({
                user:'model',
                message:"It Seems that you have a connection Error",
              })
          const error_message = error.code === 'ERR_NETWORK' ? 'Unable to Connect. Check your network connection.' : error.code;
          toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        }
      }

      
    },
    setTheme() {
      if (this.isDark) {
        document.body.classList.add('dark-theme-variables')
      } else {
        document.body.classList.remove('dark-theme-variables')
      }
      this.$store.commit('isDark', this.isDark)
    },
    logout() {
      this.$store.commit()
    },
    async checkRefresh() {
      const data = {
          token:this.$store.state.refresh
      }
      await axios
        .post("api/token/verify", data)
        .then(response => {
          if ((response.data && response.data.code) || response.status !== 200) {
              toast.error("Auth Expired, Login to Continue", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
              this.$router.push('/login')
          }
        })
        .catch(error => {
          if (error.code === 'ERR_BAD_REQUEST') {
              toast.error("Auth Expired, Login to Continue", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
              this.$router.push('/login')
          }
          else {
            console.error(error)
          }
        })
      
    },
    checkAuth() {
      this.isAuthenticated = this.$store.state.refresh !== null
      if (this.$store.state.refresh) {
        this.checkRefresh();
      }

      },
      resetSideBar() {

        document.querySelector('#sidebar').classList.remove('opened')
        document.querySelector('html').classList.remove('menu-opened')
        document.querySelector('.sidebar-overlay').classList.remove('opened')
        document.querySelector('.main-wrapper').classList.remove('slide-nav')
        document.body.classList.add('mini-sidebar')
      
    },
    sideBar() {
          
        },
    loadScripts() {
      const scripts = [
        "./assets/js/jquery-3.2.1.min.js",
        "./assets/js/popper.min.js",
        "./assets/js/bootstrap.min.js",
        "./assets/js/jquery.slimscroll.js",
        "./assets/js/Chart.bundle.js",
        
        "./assets/js/app.js"
      ];

        
        if (!this.loadedScripts) {
                scripts.forEach(script => {
            const scriptElement = document.createElement('script');
            scriptElement.src = script;
            scriptElement.async = false; // Ensure scripts are executed in order
            document.body.appendChild(scriptElement);
                });
            this.loadedScripts = true;
            }
           
        

        
      },
    
  }
};
</script>



<style lang="scss">
  @import "./assets/css/bootstrap.min.css";
  @import "./assets/css/font-awesome.min.css";
  @import "./assets/css/style.css";

  @font-face {
    font-family: "Avenir";
    src: url("./assets/fonts/Avenir.ttf");
  }

  
  
  @keyframes spin {
      
      100% {
          transform:rotate(360deg)
      }
  }


  #sidebar{
    z-index:99999;
  }
 

  .loading-modal {
      display:none;
      position: fixed;
      background-color:var(--modal-background);
      left: 0;
      padding: 1rem;
      top: 0;
      width: 100%;
      overflow: hidden;
      height: 100%;
      border-radius: 0;
      z-index:99;
      place-items: center;

      &.showform{
        display:grid;
      }

      .loader{
        height:5rem;
        width:5rem;
        border:5px solid var(--primary);
        border-top-color:var(--secondary);
        margin:auto;
        border-radius: 50%;
        animation: spin 1s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: all .3s ease;
      }
  }

  .secondary{
    color:#fff !important;
    background: var(--secondary) !important;
    font-size:12px !important;
  }
  .dropdown-item{
    color:var(--dark) !important;

    &:hover{
      background: var(--light) !important;
    }
  }


  .sidebar-menu .toggler{
    display:none;
  }

  .toggler{
    display:flex;
    justify-content: center;
    align-items: center;
    

    .backgrounds{
      display:flex;
      align-items: center;
      justify-content: space-around;
      padding:.2rem;
      width:100%;


      
    }
    .theme-toggler{
      background:var(--light);
      width:6rem;
      height:2rem;
      border-radius:var(--border-radius-1);
      position: relative;
      
      
      

      .toggle{
        display:flex;
        position: absolute;
        top:0;
        left:-0.1rem;
        width:3rem;
        height:2rem;
        background: var(--white);
        border-radius:var(--border-radius-1);
        transition:all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
	      -moz-transition: all .2s ease-in-out;
      }

      &.isDark{
        .toggle{
          left:3rem;

        }
        svg{
           fill:var(--info-light);
        }
      }

      svg{
        fill:var(--info-dark);
        z-index:9;
      }
    }
  }


  .sidebar-menu .toggler {
    margin:1rem 0;
    .theme-toggler{
      background:var(--light);
      .toggle{
        background: var(--primary);
      }
    }
  }

  .header-left {
    position: fixed;
    display:flex;
    justify-content: center;
     .toggler{
      display:none;
      position: fixed;
    }
  }


  .ai-helper{
    position: fixed;
    bottom:2rem;
    right:2rem;
    font-size:14px;
    background:var(--secondary);
    border-radius:var(--card-border-radius);
    padding:1rem;
    color:var(--info-dark);
    display:grid;
    place-items: center;
    cursor: pointer;

    i{
      font-size:36px ;
      color:#fff;
    }
  }

  .dark-theme-variables .modal.ai .modal-content{
    background-image:url("https://images.pexels.com/photos/2387793/pexels-photo-2387793.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-size: cover;

  }


  .modal.ai{
    display:flex;
    justify-content: center;
    width:100dvw;
    height:100dvh;
    background:var(--modal-background);
    position: fixed;
    top:50px;
    padding:.5rem;
    left:0;
    z-index:-1;
    opacity:0;

    &.show{
      opacity:1;
      z-index:1;

      .modal-content{
        animation:slidedown .3s ease forwards;
        animation-delay: .1s;
      }
    }
    

    .modal-content{
      width:100%;
      height:100%;
      max-width: 50rem;
      background:var(--white);
      
      max-height:40rem;
      border-radius:var(--card-border-radius);
      margin-top:-100dvh;
      

      .sender-bar{
        display:flex;
        align-items:center;
        justify-content: space-between;
        padding:.5rem 0;
        background:var(--light);
        border-radius:var(--card-border-radius);
        margin-bottom:0;
        box-shadow:0 2px 5px rgba(0,0,0,.1);
        position: absolute;
        bottom:0;
        width:100%;

        i{
          font-size:18px;
          color:#fff;
          cursor:pointer;
        }
        input{
          border:none;
          outline:none;
          background:var(--white);
          padding:.5rem 1rem;
          color:var(--dark);
          border-radius:var(--card-border-radius);
          width:100%;
          font-size:16px;
        }
        button{
          background:var(--primary);
          color:var(--white);
          border:none;
          padding:.5rem 1rem;
          border-radius:var(--card-border-radius);
          cursor:pointer;
        }
      }

      .text-window{
        padding:2rem 2rem 8rem 2rem;
        display:flex;
        flex-direction: column;
        width:100%;
        position: relative;
        height:100%;
        overflow-y:auto;

        .sender-text{
          width:100%;
          display:flex;
          flex-direction: column;
          .text{
            background:var(--info-light);
            width:fit-content;
            color:#333;
            max-width:80%;
            padding:10px;
            font-size:16px;
            border-radius:20px;
            border-bottom-left-radius:0%;
            margin:.5rem 0;

            opacity:0;
            animation:fadeinmodal .3s ease forwards;
          }
        }
        .user-text{
         width:100%;
         display: flex;
         flex-direction: column;
         align-items:flex-end;
         .text{
          background:var(--primary);
          color:#fff;
          width:fit-content;
          max-width: 80%;
          padding:10px;
          font-size:16px;
          border-radius:20px;
          border-bottom-right-radius:0%;
          margin:.5rem 0;
          opacity:0;
          animation:fadeinmodal .3s ease forwards;
         }
          
        }
      }
      .top-header{
        display:flex;
        align-items: center;
        padding:.5rem;
        background:var(--white);
        border-top-left-radius:var(--card-border-radius);
        border-top-right-radius:var(--card-border-radius);
        border-bottom:1px solid var(--dark);

        .back{
          display:flex;
          padding:.5rem 1rem;

          &:hover{
            background:var(--light);
            border-radius:var(--border-radius-1);
          }
          i{
            font-size:30px;
            color:var(--danger);
          }
        }
        .img-profile{
          height:3rem;
          width:3rem;
          border-radius:50%;
          overflow:hidden;
          margin-right:1rem;
         
          display:flex;
          justify-content: center;
          align-items:center;
          background:var(--light);

          i{
            font-size:30px;
            color:var(--primary);
          }
        }
       .title{
         font-size:18px;
         font-weight:600;
         color:var(--dark);
 
       }
      }
    }
  }

  .sender-text.loader{
    display:flex !important;
    flex-direction: row !important;
    background:var(--info-light);
    border-radius:20px;
    border-bottom-left-radius:0%;
    margin:.5rem 0 !important;
    padding:10px !important;
    width:fit-content !important;
    margin-bottom:2rem !important;

      .circle{
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin:0 5px;
        background: var(--primary);
        animation: hop 1.5s linear infinite;

        &:nth-child(2){
          animation-delay:.3s;
        }
        &:nth-child(3){
          animation-delay:.5s;
        }
      }
    
  }

  @keyframes hop{
    0%{
      transform: translateY(0);
    }
    15%{
      transform: translateY(-5px);
    }
    75%{
      transform: translateY(0);
    }
    100%{
      transform: translateY(0);
    }
  }

  @keyframes fadeinmodal {
    100%{
      opacity:1;
    }
  }
  @keyframes slidedown {
    100%{
      margin-top:1rem;
    }
  }

    @media screen and (max-width:479px) {
      .header-left .toggler{
        display:flex;
        justify-content:center;
      }
      .sidebar-menu .toggler{
        display:flex;
      }
     }

     

    

     @keyframes slidedown {
      100%{
        margin-top:0rem;
      }

      
    }
    @media screen and (min-width:786px) {
      .sidebar-menu li.active a {
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        
        
      }
     }

    @media screen and (max-width:600px) {
      .modal.ai {
        padding:0 !important;
        .modal-content{
          height:100dvh;
          max-height:100dvh;
          border-radius:0%;
          
          
          .sender-bar{
            padding:.2rem 1rem;
            border-radius:0%;
            padding:.5rem;
            background:var(--info-light);

            input{
              box-shadow:var(--box-shadow);
            }
   
          }
        }
       }
      
    }
  
</style>