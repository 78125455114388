<template>
  <main>
    <div class="content">

      <div class="row">
      
        <div class="wideTable" style="overflow:auto;width:100%;">
          <div class="card-box">
            <div class="card-block">
              <h4 class="card-title">AI Scan Results</h4>
              <button class="add-scan" style="display:none">Upload Scan</button>
              <div class="search-box">
                <input type="text" placeholder="Search for Scans" v-model="searchQuery">
              </div>
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Scan ID</th>
                      <th>Mother's ID</th>
                      <th>Mother's Name</th>
                      
                      <th>Scan Status</th>
                      <th>AI Model</th>
                     
                      <th>Foetus Label</th>
                     
                      <th>Scanned On</th>
                      <th>Issues Present?</th>
                      <th>Issues Priority</th>
                      <th>Model Used By:</th>
                      <th></th>
                     
                    </tr>
                  </thead>

                  <tbody v-if="scans && scans.length >= 1">
                    <tr v-for="(scan, index) in scans" :key="index" v-show="isMatch(scan)">
                      <td @click="selectedScanData = scan">{{ scan.scanID }}</td>
                      <td>{{ scan.mother_data.tamelaID }}</td>
                      <td>{{ scan.mother_data.lastname }} {{ scan.mother_data.firstname }} {{ scan.mother_data.othername
                        }}</td>
                      
                      <td :class="scan.status">{{scan.status}}</td>
                      <td>{{scan.model_name}}</td>
                      <td>{{scan.foetus_label}}</td>
                      
                      <td>{{ moment(`${scan.date_added}`).format("DD MMMM YYYY, HH:MM:SS") }}</td>
                      <td :class="{'success':!scan.issue_present,'failed':scan.issue_present}">{{scan.issue_present?"YES":'NONE'}}</td>
                      <td :class="{'failed':scan.issue_priority===1,'awaiting':scan.issue_priority===2,'plain':scan.issue_priority===3}" style="font-size:24px !important;text-align:center;">{{scan.issue_priority || "-"}}</td>
                       <template v-if="$store.state.usercat == 'doctor'">
                        <td  v-if="scan.scan_by.username === userData.staff_user_details.username" class="primary">You</td>
                        <td  v-else style="text-transform: Capitalize;">
                          <span v-if="scan.scan_by.usercat==='doctor'">Dr. {{scan.scan_by.name}}</span>
                          <span v-else>Dr. {{scan.scan_by.personal_name}}</span>
                        </td>
                       </template>
                       <template v-if="$store.state.usercat == 'hospitaladmin'">
                        <td  v-if="scan.scan_by.username === userData.contactEmail || scan.scan_by.name === userData.hospitalAdminInfo.perrsonal_name" class="primary" style="vertical-align: middle;">You</td>
                        <td  v-else style="text-transform: Capitalize;">
                          <span v-if="scan.scan_by.usercat==='hospitaladmin'">Dr. {{scan.scan_by.personal_name}}{{userData}}</span>
                          <span v-else>Dr. {{scan.scan_by.name}}</span>
                        </td>
                       </template>
                      <td><a target="_blank" :href="scan.scan_image" v-if="scan.scan_image">click to View Image</a></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="content-modal scans" v-if="selectedScanData">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon" @click="selectedScanData=null"><i class="fa-solid fa-angles-left"></i>&nbsp;<span>Back</span></span>
        
        </div>


        <div class="report" ref="content" id="scanReport">
          <div class="center-img">

            <div class="imgbox" style="">
              <img class="profile" :src="selectedScanData.mother_data.image" alt="">
            </div>
          </div>
          <div class="top-level">
            

            <div class="info">
              <h3>Tamela ID: <span class="primary">{{ selectedScanData.mother_data.tamelaID }}</span></h3>
              <h3 style="text-transform:uppercase">Name: <span>{{ selectedScanData.mother_data.lastname}} {{
                  selectedScanData.mother_data.firstname}} {{ selectedScanData.mother_data.othername}}</span></h3>
              <h3>Scan ID: <span class="primary">{{ selectedScanData.scanID }}</span></h3>
              <h3>Scan Date: <span>{{ moment(`${selectedScanData.date_added}`).format("DD MMM, YYYY") }}</span></h3>
              <h3>Scan Status: <span :class="selectedScanData.status" style="font-weight:400;">{{
                  selectedScanData.status }}</span></h3>
              
            </div>  
            <div class="info">
              <h3>Mother's Age: <span>{{ selectedScanData.mother_data.age }}</span></h3>
                <h3>Foetus age: <span>{{ selectedScanData.foetus_age_in_weeks }} Weeks</span></h3>
                <h3>city: <span>{{ selectedScanData.mother_data.city }}</span></h3>
                <h3>state: <span>{{ selectedScanData.mother_data.state || "None" }} State.</span></h3>
                <h3>Address: <span>{{ selectedScanData.mother_data.address }}</span></h3>
                
            </div>

          </div>

          
        </div>
          <div class="report-info">
          

          <div class="report-results" style="margin-top:1rem;border-top:1px solid var(--dark);padding-top:1rem;overflow-y:auto"></div>
            <h3 style="font-size:16px;text-align: center;text-transform:uppercase;margin-bottom:1rem;">SCAN MODEL: <span style="font-weight:600;color:var(--primary);font-size:12px">{{ selectedScanData.model_name}} </span></h3>
            <h3 style="font-size:16px;">Issues Found: <span style="font-weight:400;color:var(--info-dark);">{{ selectedScanData.issue_present?"ISSUES PRESENT":"NONE EXISTENT" }}</span> <span @click="setPriorityModal=true" v-if="!selectedScanData.issue_present" class="failed" style="text-decoration: underline;margin-left:2rem;cursor:pointer;">Report Issues</span></h3>
            <h3  style="font-size:16px;" v-if="selectedScanData.issue_present">Issues Priority <span style="font-weight:400;color:var(--info-dark);">{{selectedScanData.issue_priority }}</span></h3>
            <h3  style="font-size:16px;" v-if="selectedScanData.issue_present">Issues Description <span style="font-weight:400;color:var(--info-dark);">{{ selectedScanData.issue_details }}</span></h3>
            <h3  style="font-size:16px;margin-top:1rem;" v-if="selectedScanData.model_name!='all'"> Scan Result Summary: <span style="font-weight:400;color:var(--info-dark);">{{selectedScanData.model_result_summary }}</span></h3>
            <h3   style="font-size:20px;text-transform:uppercase;justify-content:center;text-align:center;color:var(--primary);margin-top:1rem;" v-if="selectedScanData.model_name=='all'">Detailed Scan Result Summary: </h3>
           <div class='model-result-table' v-if="selectedScanData.model_name=='all'">
            <table style="font-weight:400;color:var(--info-dark);" >
              <thead>
               <tr>
                 <th>AI Model</th>
                 <th>Predicted Class</th>
                 <th>Confidence</th>
               </tr>
              </thead>
               <tbody>
                 <tr v-for="text in Object.keys(JSON.parse(selectedScanData.results))" :key="text" style="">
                   <template v-if="text !== 'predicted_class' && text !== 'confidence'">
                     <td>{{text}}</td>
                     <td>{{JSON.parse(selectedScanData.results)[text]['predicted_class']}}</td>
                     <td>{{parseFloat(JSON.parse(selectedScanData.results)[text]['confidence']).toFixed(2)}}%</td>
                   
                   </template>
               </tr> 
              </tbody>
             </table>
           </div>
            <h3  style="font-size:16px;margin-top:1rem;">Most recent recorded vitals before scan: </h3>
            <span style="font-weight:400;color:var(--info-dark);">
              {{selectedScanData.vitals_data}}    
            </span>
            <!--<h3  style="font-size:16px;margin-top:1rem;" v-if="selectedScanData.model_name!='all'"> AI Scan Result Confidence: <span style="font-weight:400;color:var(--info-dark);" :class="{'success':selectedScanData.model_result_confidence>=80,'awaiting':selectedScanData.model_result_confidence>=50 && selectedScanData.model_result_confidence<80,'failed':selectedScanData.model_result_confidence<50}">{{selectedScanData.model_result_confidence }}%</span></h3>
            -->
            <h3  style="font-size:16px;margin-top:1rem;display:flex;flex-direction:column;" v-if="selectedScanData.gemini_recommendation"> <h3 style="font-size:20px;text-transform:uppercase;justify-content:center;text-align:center;color:var(--primary);">AI Report:</h3> <span style="font-weight:400;color:var(--info-dark);padding:.2rem;" v-for="text in selectedScanData.gemini_recommendation.split('\n')" :key="text">{{text}}</span></h3>
            <!--
            <a target="_blank" :href="selectedScanData.scan_image" v-if="selectedScanData.scan_image">Click to View Image Data</a>
            -->

            <span style="color:var(--secondary);font-size:16px;cursor:pointer;" @click="showImage = true">Click to View Image</span>
        </div>

        <button @click="saveAsPDF(selectedScanData.mother_data)">Download Report <i class="fa fa-download"></i></button>
      </div>
    </div>


    <div class="content-modal issue-modal" style="z-index:999;" v-if="setPriorityModal">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"><svg @click="setPriorityModal = false" xmlns="http://www.w3.org/2000/svg" height="48"
              viewBox="0 -960 960 960" width="48">
              <path
                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
            </svg></span>
        </div>


        <div class="report priority ">
          <div class="field">
            <label for="" style="margin-top:1rem;font-weight:bold;font-size:16px;">Priority</label>
            <select name="" id="" v-model="selectPriority">
              <option value="" selected disabled>Select a Priority</option>
              <option value="3">Low</option>
              <option value="2">Medium</option>
              <option value="1">High</option>
            </select>
          </div>
        </div>

        <div class="field" style="margin-top:1rem;">
          <textarea v-model="selectPriorityDescription" style="width:100%;background:var(--light)" name="" id="" cols="30" rows="10" placeholder="Description"></textarea>
        </div>

        <button :disabled="!selectPriority"  @click="updatePriority(selectedScanData)">Update Priority <i class="fa fa-spinner"></i></button>
      </div>
    </div>


    
    <div class="content-modal viewer-div" style="z-index:9999;" v-if="showImage">
      <div class="modal-content-viewer">
        <div class="close-button">
          <span class="svg-icon" @click="showImage=false"><i class="fa-solid fa-angles-left"></i>&nbsp;<span>Exit Viewer</span></span>
        
        </div>

        <div class="viewer" ref="viewer" id="dicomImage">
          <img :src="selectedScanData.scan_image" alt="" v-if="selectedScanData.scan_image">
          <h3 v-else>NO IMAGE AVAILABLE</h3>
        </div>

        

        
      </div>
    </div>




    <div class="notification-box" style="display:none">
      <div class="msg-sidebar notifications msg-noti">
        <div class="topnav-dropdown-header">
          <span>Messages</span>
        </div>
        <div class="drop-scroll msg-list-scroll" id="msg_list">
          <ul class="list-box">
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Richard Miles </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item new-message">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">John Doe</span>
                    <span class="message-time">1 Aug</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Tarah Shropshire </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Mike Litorus</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Catherine Manseau </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">D</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Domenic Houston </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">B</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Buster Wigton </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Rolland Webber </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Claire Mapes </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Melita Faucher</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Jeffery Lalor</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">L</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Loren Gatlin</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Tarah Shropshire</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <a href="chat.html">See all messages</a>
        </div>
      </div>
    </div>

    
  </main>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

var moment = require('moment')

export default {
  name: 'mothers',
  components: {},
  data() {
    return {
      scans: [],
      moment: moment,
      selectedScanData: null,
      searchQuery:'',
      setPriorityModal:false,
      userData:null,
      selectPriority:'',
      selectPriorityDescription:'',
      showImage:false,
    }
  },
  mounted() {

    if (this.$store.state.doctor_username) {
      this.$router.push('/doctor')
    } else {
      document.title = "Tamela Dashboard | Scans"
      this.$store.commit('currentPage', 'scans')
      document.querySelector(".page-wrapper").style.paddingTop = '70px';
      this.$root.loadScripts();
      this.$root.showLoader = true;
      this.$root.resetSideBar();
      this.getUserData();
      this.getScans();
    }
  },
  methods: {
    async getUserData() {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      try {
        const response = await axios.get(
          "accounts/userdata?user=current&usercat="+this.$store.state.usercat,
          { headers }
        );
        this.userData = response.data.data;
        console.log(this.userData);


        if(this.userData){
          this.$store.commit('setUsercat',this.userData.usercat)
          if(this.userData.usercat=='doctor'){
            this.$store.commit('setUserImage',this.userData.staff_user_details.image)
          }
          
        }


      } catch (error) {
        if (error.response) {
          // Error responses from the server
          const status = error.response.status;
          if (status === 401) {
            // Unauthorized access
            await this.refreshToken(this.getUserData);
          } else {
            // Handle other status codes
            console.error(`Error ${status}: ${error.response.data.message}`);
            toast.error(`Error ${status}: ${error.response.data.message}`, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (error.request) {
          // Request made but no response received
          console.error("No response received:", error.request);
          toast.error("No response received. Please try again later.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Other errors
          console.error("Error", error);
          toast.error(`Error: ${error}`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
    async updatePriority(data){
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      const valuedata = {
        uuid:data.uuid,
        priority:this.selectPriority,
        priority_description:this.selectPriorityDescription,
      }

      await axios
      .put('api/v1/scan/priority/update',valuedata, {headers})
                .then(response => { 
                    //console.log(response.data)
                    if(response.status){
                      this.getScans();
                      this.setPriorityModal = false;
                      toast.success('Priority Updated', {
                        autoClose: 3000,
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }
                }).catch(error => {
                    //console.log(error)
                })
    },
     async refreshToken(func) {
            await axios
                .post('api/token/refresh', {
                    refresh:this.$store.state.refresh
                })
                .then(response => { 
                    //console.log(response.data)
                    if (response.data.access) {
                        this.$store.commit('setAccess',response.data.access)
                        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.access
                        func();
                    } else {
                        //this.router.push('/login')
                    }
                }).catch(error => {
                    //console.log(error)
                })
        },
    isMatch(scan) {
      const searchLowerCase = this.searchQuery.toLowerCase();
      return (
        scan.mother_data.tamelaID.includes(this.searchQuery.toUpperCase()) ||
        scan.scanID.includes(this.searchQuery.toUpperCase()) ||
        scan.mother_data.lastname.toLowerCase().includes(searchLowerCase) ||
        scan.mother_data.firstname.toLowerCase().includes(searchLowerCase) ||
        scan.mother_data.othername.toLowerCase().includes(searchLowerCase) ||
        scan.foetus_label.toLowerCase().includes(searchLowerCase) 
        
      );
    },
    

async getScans(pk = null) {
  const accessToken = this.$store.state.access;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  if (!pk) {
    try {
      const response = await axios.get('api/v1/scan', { headers });
      this.scans = response.data.data;
      this.$root.showLoader = false;

    } catch (error) {
      if (error.response) {
        // Handle errors from the server
        const status = error.response.status;
        if (status === 401) {
          // Unauthorized access, refresh the token
          await this.refreshToken(this.getScans);
        } else {
          // Handle other status codes
          console.error(`Error ${status}: ${error.response.data.message}`);
          toast.error(`Error ${status}: ${error.response.data.message}`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else if (error.request) {
        // Request made but no response received
        console.error("No response received:", error.request);
        toast.error("No response received. Please try again later.", {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (error.code === "ERR_NETWORK") {
        // Handle network errors
        console.error("Network error:", error);
        toast.error("Unable to connect. Check your network connection.", {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        // Other errors
        console.error("Error", error);
        toast.error(`Error: ${error.message}`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }
},
    saveAsPDF(mother) {
      let HTML_Width = document.querySelector("#scanReport").offsetWidth;
      let HTML_Height = document.querySelector("#scanReport").offsetHeight;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;

      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas(document.querySelector("#scanReport")).then(function (canvas) {
          let imgData = canvas.toDataURL("image/jpeg", 1.0);
          let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
          pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
          for (let i = 1; i <= totalPDFPages; i++) { 
              pdf.addPage(PDF_Width, PDF_Height);
            pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
              let htmlText = document.querySelector("#scanReport").innerHTML
            pdf.text(20, HTML_Height + 20, htmlText); // Adjust the position as needed
            
        }
          const filename = `${mother.lastname}_${mother.firstname}_${mother.tamelaID}_SCAN`
          pdf.save(`${filename}.pdf`);
          //document.querySelector("#scanReport").hide();
      });
    },
    

  }
}
</script>

<style lang="scss" scoped>

.issue-modal {
  select, textarea{
    color:var(--dark);
  }
}
.model-result-table{
  justify-content:center;
  display:flex;
  tr{
    border-bottom:1px solid var(--light);
  }
  td,th{
    padding:.2rem .5rem;

  }
}
.card-title{
    font-size:20px;
  }
.add-scan {
    margin:1rem 0;
    border-radius:var(--border-radius-1);
    border:2px dotted rgb(224, 104, 58);
    padding:.5rem 1rem;
    font-size:14px;
    color:var(--white);
    background: rgb(224, 104, 58);
    outline:none;
    cursor: pointer;

    &:hover{
      color:rgb(224, 104, 58);
      background:var(--background);
    }
  }

  .search-box{
    margin-bottom:1rem;
    input{
      background-color:var(--light);
      color:var(--dark);
      width:100%;
      max-width:40rem;
      border:1px solid transparent;
      border-radius:var(--border-radius-1);
      padding:.5rem
    }
  }

  .wideTable{
    width:100% !important;
    max-width: fit-content;

    table th{
      color:var(--dark);
    }

    .table-responsive tbody tr:hover{
      background: var(--light);
    }
    tr td{
      text-transform: capitalize;
      color:var(--black);
    }
    tr td:first-child{
      color:var(--primary);
      font-weight:600;
      cursor:pointer;
    }
  }

  .content-modal{
    display: flex;
    justify-content: flex-start;
    padding:0;
    padding-top:3rem;
    padding-left:3rem;
    width: 100vw;
    height: 100vh;
    background: var(--modal-background);
    position: fixed;
    top:0;
    left:0;
    
    z-index:999;


    .modal-content{
      width:100%;
      display:flex;
      
      height:100%;
      background: var(--white);
      padding:2rem;
      border:1px solid var(--light);
      border-radius: var(--border-radius-1);
      z-index:999;
      overflow-y:auto;
      opacity: 0;
      animation:fadein .3s ease forwards;

      .report{
        position: relative;

        &.priority{
          select{
            width:100%;
            padding:.5rem;
            border-radius:var(--border-radius-1);
            background: var(--light);
            
          }

          .field{
            width:100%;
            display:flex;
            flex-direction: column;
            position: relative;
          }

        }

        .top-level{
          display:flex;
          flex-direction: column;
          

          .info{
            padding-left:2rem;
            margin-top:1rem;
            display:flex;

            h3{
              padding: 1rem;
            }

            &:nth-child(2){
              margin-top:-2rem;
            }
            
          }
          .center-img {
            padding:1rem;
            align-items: flex-start;
            
            .imgbox{

              /*
              background-image: url('http://192.168.0.132:8000/media/mothers/avatar-95fb4ee46aae3dcff7a9f901dc0dd0f6.jpg');
              background-size:100%;
              background-repeat: no-repeat;
              */

              flex:1;
              width:100%;
              height:100%;
              max-width: 10rem;
              border-radius: .5rem;
             
            }
          }
        }

        .report-info{
          padding-top:1rem;
          border-top:1px solid var(--info-dark);
          
        }
      }

      .close-button{
        .svg-icon{
          cursor:pointer;
          display:flex;
          align-items:center;
          background-color:#e32;
          width:fit-content;
          padding:.5rem 1rem;
          border-radius:var(--border-radius-1);
          span{
            font-size:16px;
            
          }

        }
      }

      .center-img{
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      button{
          border:1px solid transparent;
          padding:.5rem 1rem;
          font-size:16px;
          color:var(--white);
          background: var(--primary);
          border-radius:var(--border-radius-1);
          margin-top:2rem;

          &:hover{
            background:var(--white);
            color: var(--primary);
            border:1px solid var(--primary);
            
          }
        }

      .items, .info, .report-info, .report-results{
        h3{
          color:var(--dark);
          text-transform: capitalize;
          font-size:16px !important;
          font-weight:400;

          &.title{
            font-size:20px;
            font-weight:600;
            text-align: center;
            text-transform: uppercase;
          }
          span{
            color:var(--info-dark);
            font-size:16px !important;
            font-weight:600 !important;
          }
        }

      }

      .imgbox{
          width:10rem !important;
          height:10rem !important;
          position: relative;
          overflow: hidden;
          border-radius: 50% !important;
          background-color:var(--info-light);
          background:var(--info-light);
          margin-bottom:1rem;

          img.profile{
            width:100%;
            height:100%;
            background-color:var(--info-light);
            object-fit: cover;
            border-radius: 50% !important;
          }
        }
    }
  }


  button{
    cursor: pointer;

    &:disabled{
      opacity:.5
    }
  }

  .card-box{
    margin-left: 1rem;
  }

  .content-modal.viewer-div{
    width:100vw !important;
    top:50px;
    padding:0;
    
    .modal-content-viewer{
      width:100vw !important;
      height:100vh;
      background: var(--white);
      border-left:1px solid var(--modal-background);
      padding:2rem 1rem;
      margin:0;
      flex:1;

      .viewer{
        display:grid;
        background:#000;
        width:100%;
        margin-top:1rem;
        height:calc(100% - 6rem);
        border-radius:var(--border-radius-1);
        flex:1;
        position: relative;
        display:flex;
        justify-content: center;

        img{
          object-fit: cover;
          height:100%;
          
        }
      }

      .close-button{
        .svg-icon{
          cursor:pointer;
          display:flex;
          align-items:center;
          background-color:#e32;
          width:fit-content;
          padding:.5rem 1rem;
          border-radius:var(--border-radius-1);
          span{
            font-size:16px;
            
          }

        }
      }

    }
  }

@keyframes fadein {
  100%{
    opacity:1;
  }
  
}

  @media screen and (max-width:1080px) {
    .content-modal{
      padding-left:0;

      .info{
        flex-direction:column;
      }
      
    }
  }
  @media screen and (max-width:768px) {
    .content{
      width:100%;
      padding:0;
    }
  }
   @media screen and (min-width:800px) {
    .card-box{
      width:max-content;
    }
    .content{
      padding:1rem !important;
    }
  }
  @media screen and (max-width:600px) {
    .modal-content{
      
      button{
        width:100%;
      }
    }
  }

  .primary, span.primary{
    color:var(--primary) !important;
  }
  .secondary, span.secondary{
    color:var(--secondary) !important;
    background:var(--white) !important;
  }

  .awaiting, span.awaiting{
        color:var(--warning) !important;
    }
    .success, span.success{
        color:var(--success) !important;
        
    }
    .failed, span.failed{
        color:var(--danger) !important;
    }
    .plain, span.plain{
        color:var(--secondary) !important;
    }


    .side-by-side{
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 50% 50%;
    }
  
</style>