import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import MothersView from '@/views/MothersView'
import ScansView from '@/views/ScansView.vue'
import DoctorsView from '@/views/DoctorsView.vue'
import DoctorsScanHistory from '@/views/DoctorsScanHistory'
import store from '@/store'
import notFoundvue from '@/views/notFoundvue.vue'
import HospitalView from './../views/HospitalView.vue'
import StaffView from './../views/StaffView.vue'
import DoctorManageView from './../views/DoctorManageView.vue'
import HospitalPatientView from './../views/HospitalPatientView.vue'
import ModelView from './../views/ModelView.vue'
import MothersScanView from './../views/MothersScanView.vue'
import ProfileView from "./../views/ProfileView.vue"
import FileUploadView from './../views/FileUploadView.vue'
import FileDownloadView from './../views/FileDownloadView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/models',
    name: 'models',
    component: ModelView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/mothers',
    name: 'mothers',
    component: MothersView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/hospitaldashboard',
    name: 'hospitalpatient',
    component: HospitalPatientView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/doctors',
    name: 'doctors',
    component: DoctorsView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/managedoctors',
    name: 'managedoctors',
    component: DoctorManageView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/hospitals',
    name: 'hospitals',
    component: HospitalView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/staff',
    name: 'staff',
    component: StaffView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/scans',
    name: 'scans',
    component: ScansView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/file-upload',
    name: 'file-upload',
    component: FileUploadView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/file-download',
    name: 'file-download',
    component: FileDownloadView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/motherscans',
    name: 'motherscans',
    component: MothersScanView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/doctor',
    name: 'doctor',
    component: DoctorsView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/history',
    name: 'history',
    component: DoctorsScanHistory,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta:{
            requireLogin:true,
        }
       
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/:notFound',
    name: 'notFound',
    component: notFoundvue,
    
       
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/*

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireLogin) && (store.state.refresh === null)) {
        next({ name: 'login', query: { to: to.path } });
    } else {
       next()
    }
})



*/
router.beforeEach((to, from, next) => {
  // Check if the route requires login and the refresh token is null
  if (to.matched.some(record => record.meta.requireLogin) && (store.state.refresh === null)) {
    next({ name: 'login', query: { to: to.path } });
  } 
  // Redirect tamelaDoctor to /doctor when they try to access specific restricted routes
  else if (store.state.usercat === 'tamelaDoctor' && to.matched.some(record => record.meta.requireLogin)) {
    // Specify the routes tamelaDoctor is allowed to access
    const allowedForDoctor = ['doctor']; // Add other allowed routes for doctors
    if (!allowedForDoctor.includes(to.name)) {
      next({ name: 'doctor', query: { to: to.path } });
    } else {
      next(); // Allow access to allowed routes
    }
  } 
  else if (store.state.usercat === 'data-uploader' && to.matched.some(record => record.meta.requireLogin)) {
    // Specify the routes tamelaDoctor is allowed to access
    const allowedForUploader = ['file-upload']; // Add other allowed routes for doctors
    if (!allowedForUploader.includes(to.name)) {
      next({ name: 'file-upload', query: { to: to.path } });
    } else {
      next(); // Allow access to allowed routes
    }
  } 
  else if (store.state.usercat === 'ai-developer' && to.matched.some(record => record.meta.requireLogin)) {
    // Specify the routes tamelaDoctor is allowed to access
    const allowedForUploader = ['file-download']; // Add other allowed routes for doctors
    if (!allowedForUploader.includes(to.name)) {
      next({ name: 'file-download', query: { to: to.path } });
    } else {
      next(); // Allow access to allowed routes
    }
  } 
  // For other users or cases, proceed normally
  else {
    next();
  }
});

export default router
