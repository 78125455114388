<template>
  <main>
    <div class="content" >
                <div class="row">
                    
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div class="dash-widget">
							<span class="dash-widget-bg1"><i class="fa fa-stethoscope" aria-hidden="true"></i></span>
							<div class="dash-widget-info text-right">
								<h3>1</h3>
								<span class="widget-title1"><i class="fa fa-check" aria-hidden="true"></i></span>
							</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div class="dash-widget">
                            <span class="dash-widget-bg2"><i class="fa fa-user-o"></i></span>
                            <div class="dash-widget-info text-right">
                                <h3> {{ dashboardData.patientCount }} </h3>
                                <span class="widget-title2">Mothers <i class="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div class="dash-widget">
                            <span class="dash-widget-bg3"><i class="fa fa-user-md" aria-hidden="true"></i></span>
                            <div class="dash-widget-info text-right">
                                <h3>{{ dashboardData.hospitalCount }} </h3>
                                <span class="widget-title3">Hospitals <i class="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                        <div class="dash-widget">
                            <span class="dash-widget-bg4"><i class="fa fa-heartbeat" aria-hidden="true"></i></span>
                            <div class="dash-widget-info text-right">
                                <h3>{{ dashboardData.scanCount }}</h3>
                                <span class="widget-title4">Scans<i class="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="row">
					<div class="col-12 col-md-6 col-lg-6 col-xl-6">
						<div class="card">
							<div class="card-body">
								<div class="chart-title">
									<h4>Tamela Data</h4>
									<span class="float-right"><i class="fa fa-caret-up" aria-hidden="true" v-if="graph_sign_up"></i><i class="fa fa-caret-down" aria-hidden="true" v-else></i>{{ graph_month_remarks }}</span>
								</div>	
								<canvas id="linegraph"></canvas>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-6 col-xl-6">
						<div class="card">
							<div class="card-body">
								<div class="chart-title">
									<h4>Mothers Scan Data</h4>
									<div class="float-right">
										<ul class="chat-user-total" style="display:none">
											<li><i class="fa fa-circle current-users" aria-hidden="true"></i>ICU</li>
											<li><i class="fa fa-circle old-users" aria-hidden="true"></i> OPD</li>
										</ul>
									</div>
								</div>	
								<canvas id="bargraph"></canvas>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-md-6 col-lg-8 col-xl-8">
						<div class="card">
							<div class="card-header">
								<h4 class="card-title d-inline-block">Recent Patient Scans</h4> <router-link class="btn btn-primary float-right" to="/scans">View all</router-link>
							</div>
							<div class="card-body p-0">
								<div class="table-responsive">
									<table class="table mb-0">
										<thead class="d-none">
											<tr>
												<th>Mother's Name</th>
                                                <th>Date and Time</th>
												<th>Status</th>
												
												<th class="text-right">Status</th>
											</tr>
										</thead>
										<tbody v-if="scans && scans.length >= 1">
											<tr v-for="(scan, index) in scans" :key="index">
												<td style="min-width: 200px;">
													<p class="avatar"><img :src="scan.mother_data.image" alt=""></p>
													<h2 style="text-transform: capitalize;"><span class="mother-name">{{ scan.mother_data.lastname }} {{ scan.mother_data.firstname }} {{ scan.mother_data.othername }}<span>{{scan.mother_data.city}}, {{ scan.mother_data.state }}</span></span></h2>
												</td>                 
												<td>
													<h5 class="time-title p-0">Scan ID</h5>
													<p style="color:var(--primary)">{{ scan.scanID }}</p>
												</td>
												<td>
													<h5 class="time-title p-0">Scanned on</h5>
													<p>{{ moment(`${scan.date_added}`).fromNow() }}</p>
												</td>
												<td>
													<h5 class="time-title p-0">Status</h5>
													<p :class="scan.status"  style="text-transform: uppercase;">{{ scan.status }}</p>
												</td>
												<td class="text-right">
													<p class="btn btn-outline-secondary take-btn">View Details</p>
												</td>
											</tr>
											
										</tbody>
                                        
									</table>
								</div>
							</div>
						</div>
					</div>
                    <div class="col-12 col-md-6 col-lg-4 col-xl-4" style="display:none">
                        <div class="card member-panel">
							<div class="card-header bg-white">
								<h4 class="card-title mb-0">Doctors</h4>
							</div>
                            <div class="card-body">
                                <ul class="contact-list">
                                    <li>
                                        <div class="contact-cont">
                                            <div class="float-left user-img m-r-10">
                                                <a href="profile.html" title="John Doe"><img src="assets/img/user.jpg" alt="" class="w-40 rounded-circle"><span class="status online"></span></a>
                                            </div>
                                            <div class="contact-info">
                                                <span class="contact-name text-ellipsis">John Doe</span>
                                                <span class="contact-date">MBBS, MD</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="contact-cont">
                                            <div class="float-left user-img m-r-10">
                                                <a href="profile.html" title="Richard Miles"><img src="assets/img/user.jpg" alt="" class="w-40 rounded-circle"><span class="status offline"></span></a>
                                            </div>
                                            <div class="contact-info">
                                                <span class="contact-name text-ellipsis">Richard Miles</span>
                                                <span class="contact-date">MD</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="contact-cont">
                                            <div class="float-left user-img m-r-10">
                                                <a href="profile.html" title="John Doe"><img src="assets/img/user.jpg" alt="" class="w-40 rounded-circle"><span class="status away"></span></a>
                                            </div>
                                            <div class="contact-info">
                                                <span class="contact-name text-ellipsis">John Doe</span>
                                                <span class="contact-date">BMBS</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="contact-cont">
                                            <div class="float-left user-img m-r-10">
                                                <a href="profile.html" title="Richard Miles"><img src="assets/img/user.jpg" alt="" class="w-40 rounded-circle"><span class="status online"></span></a>
                                            </div>
                                            <div class="contact-info">
                                                <span class="contact-name text-ellipsis">Richard Miles</span>
                                                <span class="contact-date">MS, MD</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="contact-cont">
                                            <div class="float-left user-img m-r-10">
                                                <a href="profile.html" title="John Doe"><img src="assets/img/user.jpg" alt="" class="w-40 rounded-circle"><span class="status offline"></span></a>
                                            </div>
                                            <div class="contact-info">
                                                <span class="contact-name text-ellipsis">John Doe</span>
                                                <span class="contact-date">MBBS</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="contact-cont">
                                            <div class="float-left user-img m-r-10">
                                                <a href="profile.html" title="Richard Miles"><img src="assets/img/user.jpg" alt="" class="w-40 rounded-circle"><span class="status away"></span></a>
                                            </div>
                                            <div class="contact-info">
                                                <span class="contact-name text-ellipsis">Richard Miles</span>
                                                <span class="contact-date">MBBS, MD</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-footer text-center bg-white">
                                <a href="doctors.html" class="text-muted">View all Doctors</a>
                            </div>
                        </div>
                    </div>
				</div>
				<div class="row">
					<div class="col-12 col-md-6 col-lg-8 col-xl-8">
						<div class="card">
							<div class="card-header">
								<h4 class="card-title d-inline-block">Mothers</h4> <router-link to="/mothers" class="btn btn-primary float-right">View all</router-link>
							</div>
							<div class="card-block">
								<div class="table-responsive">
									<table class="table mb-0 new-patient-table">
										<tbody v-if="mothers && mothers.length >= 1">
											<tr v-for="(mother, index) in mothers" :key="index">
												<td>
													<img width="28" height="28" class="rounded-circle" :src="mother.mother_image || require('../assets/img/user.jpg')" alt=""> 
													<h2>{{ mother.lastname }} {{ mother.firstname }} {{ mother.othername }}</h2>
												</td>
                                                <td class="primary" style="color:var(--primary);">{{mother.tamelaID}}</td>
												<td>{{mother.city}}, {{ mother.state }}</td>
												
												
											</tr>
											
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-4 col-xl-4" style="display:none">
						<div class="hospital-barchart">
							<h4 class="card-title d-inline-block">Hospital Management</h4>
						</div>
						<div class="bar-chart">
							<div class="legend">
								<div class="item">
									<h4>Level1</h4>
								</div>
								
								<div class="item">
									<h4>Level2</h4>
								</div>
								<div class="item text-right">
									<h4>Level3</h4>
								</div>
								<div class="item text-right">
									<h4>Level4</h4>
								</div>
							</div>
							<div class="chart clearfix">
								<div class="item">
									<div class="bar">
										<span class="percent">16%</span>
										<div class="item-progress" data-percent="16">
											<span class="title">OPD Patient</span>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="bar">
										<span class="percent">71%</span>
										<div class="item-progress" data-percent="71">
											<span class="title">New Patient</span>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="bar">
										<span class="percent">82%</span>
										<div class="item-progress" data-percent="82">
											<span class="title">Laboratory Test</span>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="bar">
										<span class="percent">67%</span>
										<div class="item-progress" data-percent="67">
											<span class="title">Treatment</span>
										</div>
									</div>
								</div>
								<div class="item">
									<div class="bar">
										<span class="percent">30%</span>									
										<div class="item-progress" data-percent="30">
											<span class="title">Discharge</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					 </div>
				</div>
            </div>
            <div class="notification-box">
                <div class="msg-sidebar notifications msg-noti">
                    <div class="topnav-dropdown-header">
                        <span>Messages</span>
                    </div>
                    <div class="drop-scroll msg-list-scroll" id="msg_list">
                        <ul class="list-box">
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">R</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author">Richard Miles </span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item new-message">
                                        <div class="list-left">
                                            <span class="avatar">J</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author">John Doe</span>
                                            <span class="message-time">1 Aug</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">T</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author"> Tarah Shropshire </span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">M</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author">Mike Litorus</span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">C</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author"> Catherine Manseau </span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">D</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author"> Domenic Houston </span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">B</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author"> Buster Wigton </span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">R</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author"> Rolland Webber </span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">C</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author"> Claire Mapes </span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">M</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author">Melita Faucher</span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">J</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author">Jeffery Lalor</span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">L</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author">Loren Gatlin</span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="chat.html">
                                    <div class="list-item">
                                        <div class="list-left">
                                            <span class="avatar">T</span>
                                        </div>
                                        <div class="list-body">
                                            <span class="message-author">Tarah Shropshire</span>
                                            <span class="message-time">12:28 AM</span>
                                            <div class="clearfix"></div>
                                            <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="topnav-dropdown-footer">
                        <a href="chat.html">See all messages</a>
                    </div>
                </div>
            </div>
       

  </main>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import axios from 'axios';
var moment = require('moment')
import { Chart } from '../assets/js/Chart.bundle';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      dashboardData: {
        patientCount:'...',
            scanCount: '...',
            lineChartData:[0,0,0,0,0,0,0,0,0,0,0,0],
            lineChartData2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            hospitalCount: '...',
        },
        userData:{},   
        graph_sign_up:true,
        graph_month_remarks:'',
        moment:moment,
      scans:[],
      mothers:[],
    }
  },
    mounted() { 
        document.title = "Tamela Dashboard"
        if (this.$store.state.doctor_username) {
            this.$router.push('/doctor')
        } else {
            this.getUserData();
            this.$store.commit('currentPage', 'hospitaldashboard')
            document.querySelector(".page-wrapper").style.paddingTop = '70px';
            this.$root.loadScripts();
            this.$root.resetSideBar();
            this.getScans();
            this.getMothers();
            this.getDashboardData();
            this.setChartData();
            this.$root.showLoader = true;

        }
    
        
  },
    methods: {
        async refreshToken(func) {
            await axios
                .post('api/token/refresh', {
                    refresh:this.$store.state.refresh
                })
                .then(response => { 
                    //console.log(response.data)
                    if (response.data.access) {
                        this.$store.commit('setAccess',response.data.access)
                        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.access
                        func();
                    } else {
                        //this.router.push('/login')
                    }
                }).catch(error => {
                    //console.log(error)
                })
        },
      setChartData() {
        //this.dashboardData.lineChartData = [10,22,31,34,13,53,63,23,13,42,55,23]
          let currentMonth = new Date().getMonth();
            let prevMonth = currentMonth >= 1 ? currentMonth - 1 : 11;
            let currentMonthValue = this.dashboardData.lineChartData[currentMonth];
            let prevMonthValue = this.dashboardData.lineChartData[prevMonth]==0?1:this.dashboardData.lineChartData[prevMonth];

            // Calculate the percentage raise or decrease
            let percentageRaise = ((currentMonthValue - prevMonthValue) * 100) / prevMonthValue;

            // Use Math.abs() to get the absolute value of the percentage raise
            percentageRaise = Math.abs(percentageRaise);

            //console.log(percentageRaise);

            if (currentMonthValue >= prevMonthValue) {
                this.graph_month_remarks = `${percentageRaise.toFixed(2)}% increase from last month`;
                this.graph_sign_up = true;
            } else {
                this.graph_month_remarks = `${percentageRaise.toFixed(2)}% decrease from last month`;
                this.graph_sign_up = false;
            }
          let lineChartData = {
		    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              datasets: [
                  {
                    label: "Number of Scans",
                    backgroundColor: "rgba(42, 45, 116, 0.5)",
                    data: this.dashboardData.lineChartData
                  },
                    {
                label: "No of Patients",
                backgroundColor: "rgba(224, 104, 58, 0.7)",
                fill: true,
                data: this.dashboardData.lineChartData2 //[10,22,31,34,13,53,63,23,13,42,55,23]
                  }
                   
              ]


              
	};
	
        let linectx = document.getElementById('linegraph').getContext('2d');
        window.myLine = new Chart(linectx, {
            type: 'line',
            data: lineChartData,
            options: {
                responsive: true,
                legend: {
                    display: false,
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                }
            }
        });
	




        var barChartData = {
		labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
		datasets: [{
			label: 'Dataset 1',
			backgroundColor: 'rgba(0, 158, 251, 0.5)',
			borderColor: 'rgba(0, 158, 251, 1)',
			borderWidth: 1,
			data: [35, 59, 80, 81, 56, 55, 40]
		}, {
			label: 'Dataset 2',
			backgroundColor: 'rgba(255, 188, 53, 0.5)',
			borderColor: 'rgba(255, 188, 53, 1)',
			borderWidth: 1,
			data: [28, 48, 40, 19, 86, 27, 90]
		}]
	};

	var ctx = document.getElementById('bargraph').getContext('2d');
	window.myBar = new Chart(ctx, {
		type: 'bar',
		data: barChartData,
		options: {
			responsive: true,
			legend: {
				display: false,
			}
		}
	});
    },
    async getUserData(){
        await axios
            .get("accounts/userdata?user="+this.username)
            .then(response => {
                this.userData = response.data.data;
            })
            .catch(error => {
              if (error.code != 'ERR_NETWORK') {
                  if (error.response.status === 401) {
                    this.refreshToken(this.getUserData)
                  }
            } else {
                  console.error(error)
                  const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                    toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
              }

            
          })
    },
    async getDashboardData() {
        if (this.$root.checkAuth) {
        const accessToken = this.$store.state.access;
        const headers = { Authorization: `Bearer ${accessToken}` };

        await axios
          .get('api/v1/dashboard/data', { headers })
          .then(response => {
              const data = response.data.data
            
            this.dashboardData.patientCount = data.patient_count
              this.dashboardData.scanCount = data.scan_count
            this.dashboardData.lineChartData = data.scan_count_list;
              this.dashboardData.lineChartData2 = data.patient_count_list;
              this.dashboardData.hospitalCount = data.hospitalCount;

            

              this.setChartData();
              this.$root.showLoader = false;
            
          })
          .catch(error => {
              if (error.code != 'ERR_NETWORK') {
                  if (error.response.status === 401) {
                    this.refreshToken(this.getDashboardData)
                  }
            } else {
                  console.error(error)
                  const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                    toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
              }

            
          })
      }
        },
      isIOS() {
        const userAgent = navigator.userAgent;
        return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
        },
        async getScans(mother = null) {
            const accessToken = this.$store.state.access;
            const headers = { Authorization: `Bearer ${accessToken}` };

          await axios
              .get('api/v1/scan', { headers })
              .then(response => {
                  this.scans = response.data.data;
                  this.$root.showLoader = false;
                
              }).catch(error => {
                 if (error.code != 'ERR_NETWORK') {
                  if (error.response.status === 401) {
                    this.refreshToken(this.getScans)
                  }
            } else {
                  console.error(error)
                  const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                    toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                }
            })
        },
        async getMothers(mother = null) {
            const accessToken = this.$store.state.access;
            const headers = { Authorization: `Bearer ${accessToken}` };

          await axios
              .get('accounts/mothers', { headers })
              .then(response => {
                  this.mothers = response.data.data;
                  this.$root.showLoader = false;
                
              }).catch(error => {
                 if (error.code != 'ERR_NETWORK') {
                  if (error.response.status === 401) {
                    this.refreshToken(this.getMothers)
                  }
            } else {
                  console.error(error)
                  const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                    toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                }
            })
        },
  }
}
</script>

<style lang="scss" scoped>

td{
    color:var(--dark);
}
    .awaiting{
        color:var(--primary);
    }
    .success{
        color:var(--success);
    }
    .failed{
        color:var(--danger);
    }

    .card {
        background:var(--white) !important;
        border-radius: var(--card-border-radius) !important;
        box-shadow:var(--box-shadow);
    }

    table {
        border:1px solid var(--light);

        .mother-name{
            font-size:14px;
            color:var(--dark);
            cursor: pointer;

            &:hover{
                color:var(--primary);
            }
        }
      

        th, tbody tr, tbody td{
            border-top:1px solid var(--light) !important;
        }
        
        h5{
            color:var(--dark)
        }
              
    }

    

    @media screen and (min-width:600px) {
        main{
            padding: 1rem;
        }
    }
    @media screen and (max-width:600px) {
        .content{
            padding:0 1rem;

            
        }
        
    }
    @media screen and (max-width:400px) {
        .content{
            padding:0 1rem;
        }

        
    }
</style>