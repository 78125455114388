<template>
    <main>
      <div class="content">
        <h2 class="card-title" style="color:var(--primary);font-weight: 600;">Tamela Staff Management </h2>
        <div class="row">
        
          <div class="col-lg-6 wideTable">
            <div class="card-box">
              <div class="card-block">
                
                <button class="add-scan" style="" @click="staffFormModal=true">Add User +</button>
                <div class="search-box">
                  <input type="text" placeholder="Search for Users" v-model="searchQuery">
                </div>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead>
                      <tr>
                        <th>Tamela Staff ID</th>
                        <th>Full Name</th>
                        <th>Email Address</th>
                        <th>Phone Number</th>
                        

                        <th></th>
                      </tr>
                    </thead>
  
                    <tbody v-if="users && users.length >= 1">
                      <tr v-for="(user, index) in users" :key="index" v-show="isMatch(user)">
                        <td @click="selectedUserData = user">{{ user.tamelaID }}</td>
                        <td>{{ user.name }}</td>
                        <td style="text-transform: lowercase;">{{user.email}}</td>
                        <td>{{user.phone_number}}</td>
                       
                        
                        <td></td>
                      </tr>
  
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
  
      <div class="content-modal" v-if="selectedHospitalData">
        <div class="modal-content">
          <div class="close-button">
            <span class="svg-icon"><svg @click="selectedHospitalData = null" xmlns="http://www.w3.org/2000/svg" height="48"
                viewBox="0 -960 960 960" width="48">
                <path
                  d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
              </svg></span>
          </div>
  
  
          <div class="report">
            
            <div class="report-info">
              <h3>Hospital Name: <span>{{ selectedHospitalData.name }}</span></h3>
              <h3>Hospital Tamela ID: <span>{{ selectedHospitalData.hospital_id }}</span></h3>
              <h3>Hospital Contact Phone Number: <span><a :href="'tel:'+selectedHospitalData.contact_phone_number">{{ selectedHospitalData.contact_phone_number }}</a></span></h3>
              <h3>Hospital Contact Email: <span style="text-transform: lowercase;"><a :href="'mailto: ' + selectedHospitalData.contactEmail">{{selectedHospitalData.contactEmail}}</a></span></h3>
              <h3>Status <span :class="{'failed':selectedHospitalData.isActive!==true,'success':selectedHospitalData.isActive}"> {{selectedHospitalData.isActive?"Active":"Inactive"}} </span> <span class="set-active" v-if="selectedHospitalData.isActive" @click="setHospitalStatus(selectedHospitalData.uuid, false)">Set as Inactive</span> <span class="set-active success" v-else @click="setHospitalStatus(selectedHospitalData.uuid, true)">Set as Active</span></h3>
              <h3>Address <span>{{ selectedHospitalData.address }}</span></h3>
              <h3>LGA: <span>{{ selectedHospitalData.lga }}</span></h3>
              <h3>state: <span>{{ selectedHospitalData.state || "None" }} State.</span></h3>
              <h3>Current Addmitted Mothers: <span>{{ selectedHospitalData.currentAddmittedMothers || 'None' }}</span></h3>
            </div>
            <div class="options">
             <button @click="selectedHospital = {
              hospital_id:selectedHospitalData.hospital_id,
              name:selectedHospitalData.name,
              address:selectedHospitalData.address,
            };getPatients();">Check Current Admitted Mothers</button>
            </div>
  
          </div>
        </div>
      </div>
  
  
  
      <div class="floating-modal" v-if="selectedHospital">
        <div class="modal-content">
  
          <div class="content">
            <h2 class="card-title" style="color:var(--primary);font-weight: 600;">{{selectedHospital.name}} Patients</h2>
            <h4 class="card-title" style="color:var(--primary);font-size:16px;margin-top:-.5rem;">{{selectedHospital.address}}</h4>
            <div class="row">
            
              <div class="col-lg-6 wideTable">
                <div class="card-box">
                  <div class="card-block">
                    <div class="back">
                      <button class="add-scan" style="" @click="selectedHospital=null">Back</button>
                    </div>
                    
                    <div class="search-box">
                      <input type="text" placeholder="Search for Mothers" v-model="searchQuery">
                    </div>
                    <div class="table-responsive">
                      <table class="table mb-0">
                        <thead>
                            <tr>
                                <th>Tamela ID</th>
                                <th>Surname / Lastname</th>
                                <th>Firstname</th>
                                <th>Othername</th>
                                <th>Othername</th>
                                <th>Age</th>
                                <th>City</th>
                                <th>State</th>
                                
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="patients && patients.length >= 1">
                            <tr v-for="(patient, index) in patients" :key="index" v-show="isMatchMother(patient)">
                                <td @click="selectedMotherData = patient">{{ patient.tamelaID }}</td>
                                <td>{{ patient.firstname }}</td>
                                <td>{{ patient.lastname }}</td>
                                <td>{{ patient.othername }}</td>
                                <td>{{ patient.age }}</td>
                                <td>{{ patient.city }}</td>
                                <td>{{ patient.state }}</td>
                                
                                <td></td>
                            </tr>

                        </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
        </div>
      </div>
      

       
      <div class="content-modal create" v-if="createHospitalmodal">
        <div class="modal-content">
          <div class="close-button">
              <span class="svg-icon"><svg @click="isCreateMother = false"
                      xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                      <path
                          d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                  </svg></span>
            </div>
          <div id="reg">
            <form class="add-mother-form" @submit.prevent="submitForm" id="add-mother-form" ref="addMotherForm">
<h3 class="title">Add Hospital</h3>
<div class="field2">
<input type="text" placeholder="Hospital Name" v-model="hospitalInfo.name" required>

</div>
<input type="text" pattern="[0-9]*" inputmode="numeric" placeholder="Contact Phone Number" v-model="hospitalInfo.phoneNumber" required>

<input type="email" placeholder="Contact Email" autocomplete="off" v-model="hospitalInfo.email" required>
<div class="field2">
<input type="text" placeholder="L.G.A" v-model="hospitalInfo.lga" required>
<input type="text" placeholder="State" v-model="hospitalInfo.state" required>
</div>
<textarea name="" id="" cols="30" rows="3" placeholder="Hospital Address" v-model="hospitalInfo.address" required></textarea>

</form>
          <button @click="createHospital()">Add Hospital +</button>
          </div>
        </div>
      </div>

  
  
      <div class="notification-box" style="display:none">
        <div class="msg-sidebar notifications msg-noti">
          <div class="topnav-dropdown-header">
            <span>Messages</span>
          </div>
          <div class="drop-scroll msg-list-scroll" id="msg_list">
            <ul class="list-box">
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">R</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author">Richard Miles </span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item new-message">
                    <div class="list-left">
                      <span class="avatar">J</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author">John Doe</span>
                      <span class="message-time">1 Aug</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">T</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author"> Tarah Shropshire </span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">M</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author">Mike Litorus</span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">C</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author"> Catherine Manseau </span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">D</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author"> Domenic Houston </span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">B</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author"> Buster Wigton </span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">R</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author"> Rolland Webber </span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">C</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author"> Claire Mapes </span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">M</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author">Melita Faucher</span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">J</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author">Jeffery Lalor</span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">L</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author">Loren Gatlin</span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="chat.html">
                  <div class="list-item">
                    <div class="list-left">
                      <span class="avatar">T</span>
                    </div>
                    <div class="list-body">
                      <span class="message-author">Tarah Shropshire</span>
                      <span class="message-time">12:28 AM</span>
                      <div class="clearfix"></div>
                      <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <a href="chat.html">See all messages</a>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  import axios from 'axios'
  import jsPDF from 'jspdf';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  
  var moment = require('moment')
  
  export default {
    name: 'staff',
    components: {},
    data() {
      return {
        users: [],
        moment: moment,
        selectedUser: null,
        selectedUserData: null,
        searchQuery:'',
        hospitalPatientsModal:false,
        patients:[],
        staffInfo:{
          name:"",
          address:"",
          phoneNumber:"",
          email:"",
        },
        createUsermodal:false,
      }
    },
    mounted() {
  
      if (this.$store.state.doctor_username || this.$store.state.usercat === 'doctor') {
        this.$router.push('/doctor')
      } 
      else {
        document.title = "Tamela Dashboard | Staff Management"
        this.$store.commit('currentPage', 'staff')
        document.querySelector(".page-wrapper").style.paddingTop = '70px';
        this.$root.loadScripts();
        this.$root.showLoader = true;
        this.$root.resetSideBar();
        this.getUsers();
      }
    },
    methods: {
       async refreshToken(func) {
              await axios
                  .post('api/token/refresh', {
                      refresh:this.$store.state.refresh
                  })
                  .then(response => { 
                      //console.log(response.data)
                      if (response.data.access) {
                          this.$store.commit('setAccess',response.data.access)
                          axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.access
                          func();
                      } else {
                          //this.router.push('/login')
                      }
                  }).catch(error => {
                      //console.log(error)
                  })
          },
      isMatch(user) {
        const searchLowerCase = this.searchQuery.toLowerCase();
        return (
          user.tamelaID.includes(this.searchQuery.toUpperCase()) ||
          user.name.includes(searchLowerCase) ||
          user.address.includes(searchLowerCase)
        );
      },
      isMatchMother(patient) {
      const searchLowerCase = this.searchQuery.toLowerCase();
      return (
        patient.tamelaID.includes(this.searchQuery.toUpperCase()) ||
        patient.firstname.toLowerCase().includes(searchLowerCase) ||
        patient.lastname.toLowerCase().includes(searchLowerCase) ||
        patient.othername.toLowerCase().includes(searchLowerCase) ||
        patient.city.toLowerCase().includes(searchLowerCase) ||
        patient.state.toLowerCase().includes(searchLowerCase)
      );
    },
      async getPatients(pk = null) {

        const accessToken = this.$store.state.access;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        if (!pk) {
          await axios
            .get('accounts/mothers' + "?hospital=" + this.selectedHospital.hospital_id, { headers })
            .then(response => {

              this.patients = response.data.data;
              //console.log(response.data)
              this.$root.showLoader = false;
            })
            .catch(error => {
              if (error.code != 'ERR_NETWORK') {
                    if (error.response.status === 401) {
                      this.refreshToken(this.getPatient)
                    }
              } else {
                    console.error(error)
                    const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                      toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                  }
            })
        }
        },
      async getUsers(pk = null) {

        const accessToken = this.$store.state.access;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
  
        if (!pk) {
          this.$root.showLoader = true;
          await axios
            .get('accounts/userdata'+ "?usercat=staff", { headers })
            .then(response => {
  
              this.users = response.data.data;
              console.log(this.users);
              this.$root.showLoader = false;
            })
            .catch(error => {
               if (error.code != 'ERR_NETWORK') {
                    if (error.response.status === 401) {
                      this.refreshToken(this.getUsers)
                    }
              } else {
                    console.error(error)
                    const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                      toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                  }
              //this.$root.showLoader = false;
            })
            
        }
      },
      async setHospitalStatus(uuid, status) {
        const accessToken = this.$store.state.access;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const data = {
          uuid: uuid,
          status: status,
        };
        console.log(data)
  
        if (uuid) {
          this.$root.showLoader = true;
          await axios
            .put('accounts/hospitals/status/change',data, { headers })
            .then(response => {
  
              this.hospitals = response.data.data;
              //console.log(this.hospitals);
              this.getHospitals();
              this.selectedHospitalData = null;
              if(response.data.status){
                toast.success(response.data.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
               
              }
              this.$root.showLoader = false;
            })
            .catch(error => {
               if (error.code != 'ERR_NETWORK') {
                    if (error.response.status === 401) {
                      this.refreshToken(this.getHospitals)
                    }
              } else {
                    console.error(error)
                    const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                      toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                  }
              this.$root.showLoader = false;
            })
            
        }
      },
      async createHospital(){
        const accessToken = this.$store.state.access;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const data = {
          name: this.hospitalInfo.name,
          address: this.hospitalInfo.address,
          phone_number: this.hospitalInfo.phoneNumber,
          email: this.hospitalInfo.email,
          lga: this.hospitalInfo.lga,
          state: this.hospitalInfo.state,
        };
        console.log(data)
        await axios
          .post('accounts/hospitals',data, { headers })
          .then(response => {
            this.hospitalInfo = {
              name:"",
              address:"",
              phoneNumber:"",
              email:"",
              lga:"",
              state:""
            }
            this.createHospitalmodal = false;
            this.getHospitals();
            toast.success(response.data.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
            this.$root.showLoader = false;
          })
          .catch(error => {
            if (error.code != 'ERR_NETWORK') {
                    if (error.response.status === 401) {
                      this.refreshToken(this.createHospital)
                    }
              } else {
                    console.error(error)
                    const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                      toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                  }
            this.$root.showLoader = false;
          })
      }
  
    }
  }
  </script>
  
  <style lang="scss" scoped>

  .content-modal.create{
    left:0;
  }

  .content-modal .modal-content .add-mother-form{
    margin-top:1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    .field, .field2{
      display: flex;
      width:100%;
    }
    
    input, textarea{
      resize: none;
      width:100%;
      margin:.2rem;
      padding: .5rem;
      border-radius: var(--border-radius-1);
      background: var(--light);
      color:var(--dark);
      margin-top:.5rem;
      border:1px solid transparent;
    }
  
  }

  .card-title{
      font-size:20px;
      padding-left:1rem;
      text-transform: capitalize;
    }
  .add-scan {
      margin:1rem 0;
      border-radius:var(--border-radius-1);
      border:2px dotted rgb(224, 104, 58);
      padding:.5rem 1rem;
      font-size:14px;
      color:var(--white);
      background: rgb(224, 104, 58);
      outline:none;
      cursor: pointer;
  
      &:hover{
        color:rgb(224, 104, 58);
        background:var(--background);
      }
    }
  
    .search-box{
      margin-bottom:1rem;
      input{
        background-color:var(--light);
        color:var(--dark);
        width:100%;
        max-width:40rem;
        border:1px solid transparent;
        border-radius:var(--border-radius-1);
        padding:.5rem
      }
    }
  
    .wideTable{
      width:100% !important;
      max-width: fit-content;
  
      table th{
        color:var(--dark);
      }
  
      .table-responsive tbody tr:hover{
        background: var(--light);
      }
      tr td{
        text-transform: capitalize;
        color:var(--black);
        
        &.linkify{
          color:var(--secondary);
          font-weight: 600;
        }
      }
      tr td:first-child{
        color:var(--primary);
        font-weight:600;
        cursor:pointer;
      }
    }
  
    .content-modal{
      display: flex;
      justify-content: center;
      padding:1rem;
      padding-top:5rem;
      width: 100vw;
      height: 100vh;
      background: var(--modal-background);
      position: fixed;
      top:0;
  
  
      .modal-content{
        width:100%;
        max-width:30rem;
        height:fit-content;
        background: var(--white);
        padding:1rem;
        border-radius: var(--card-border-radius);

        
  
        .report{
          position: relative;
          .top-level{
            display:grid;
            grid-auto-flow: column;
            grid-template-columns: 40% 60%;
  
            .info{
              padding-left:2rem;
              margin-top:1rem;
              border-left:1px solid var(--info-dark);
            }
            .center-img {
              padding:1rem;
              align-items: flex-start;
              
              .imgbox{
  
                /*
                background-image: url('http://192.168.0.132:8000/media/mothers/avatar-95fb4ee46aae3dcff7a9f901dc0dd0f6.jpg');
                background-size:100%;
                background-repeat: no-repeat;
                */
  
                flex:1;
                width:100%;
                height:10rem;
                max-width: 10rem;
                border-radius: .5rem;
              }
            }
          }
  
          .report-info{
            padding-top:1rem;
           
          }
        }
  
        .close-button{
          
  
          .svg-icon{
            cursor: pointer;
            svg{
              fill:#e32;
              width:2rem;
              height:2rem;
            }
          }
        }
  
        .center-img{
          display: flex;
          width: 100%;
          justify-content: center;
          text-align: center;
          align-items: center;
        }
  
        button{
            border:1px solid transparent;
            padding:.5rem 1rem;
            font-size:16px;
            color:var(--white);
            background: var(--primary);
            border-radius:var(--border-radius-1);
            margin-top:2rem;
  
            &:hover{
              background:var(--white);
              color: var(--primary);
              border:1px solid var(--primary);
              
            }
          }
  
        .items, .info, .report-info{
          h3{
            color:var(--dark);
            text-transform: capitalize;
            font-size:16px;
            font-weight:400;

            &:first-child{
              font-size:18px !important;
              font-weight: 600;
              span{
                font-size: 18px !important;
                font-weight: 600;
              }
            }
  
            &.title{
              font-size:20px;
              font-weight:600;
              text-align: center;
              text-transform: uppercase;
            }
            span{
              color:var(--info-dark);
              font-size:14px !important;
              font-weight:400 !important;

              

              &.set-active{
                margin-left:1rem;
                text-decoration: underline;
                color:var(--danger);
                cursor: pointer;
                font-weight: 600;

                &.success{
                  color:var(--success);
                }
              }
            }
          }
  
        }
  
        .imgbox{
            width:10rem;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            background-color:var(--info-light);
            background:var(--info-light);
            margin-bottom:1rem;
  
            img.profile{
              width:100%;
              background-color:var(--info-light);
              object-fit: cover;
            }
          }

          
      }
    }
  
    button{
      cursor: pointer;
    }
  
  
    @media screen and (max-width:768px) {
      .content{
        width:100%;
        padding:0;
      }

      .card-title{
        margin-top: 2rem;
        
      }
      .floating-modal {
        .modal-content{
          padding:0 !important;

          
        }
      }
    }
     @media screen and (min-width:800px) {
      .card-box{
        width:max-content;
      }
      .content{
        padding:1rem !important;
      }

    }
    @media screen and (max-width:600px) {
      .modal-content{
        
        button{
          width:100%;
        }
      }
    }
  
    .primary, span.primary{
      color:var(--primary) !important;
    }
    .secondary, span.secondary{
      color:var(--secondary) !important;
      background:var(--white) !important;
    }
  
    .awaiting, span.awaiting{
          color:var(--warning) !important;
      }
      .success, span.success{
          color:var(--success) !important;
          
      }
      .failed, span.failed{
          color:var(--danger) !important;
      }
    


      .floating-modal{
        display: flex;
        width:100vw;
        height: 100vh;
        position: fixed;
        
        top:0;
        left:0;
        z-index:1000;
        background: rgba(0,0,0,.5);
        .modal-content{
          padding:4rem;
          position: relative;
          background:var(--background);
          .back{
            display: flex;
            width: 100%;
            position: relative;
            justify-content: flex-end;
            
          }
          .back button{
           
            
            
            border-radius:var(--border-radius-1);
            background:var(--danger);
            border:1px solid var(--danger);
            color:var(--white);

            &:hover{
              background:var(--white);
              color:var(--danger);
              border:1px solid var(--danger);
            }

          }

          .content{
            margin-top:3rem;
          }
        }

      }


    
  </style>