<template>
  <main>
    <div class="content">
      <div class="row">
        <div class="col-lg-6 wideTable">
          <div class="card-box">
            <div class="card-block">
              <h4 class="card-title">Submitted Ultrasound Scans Results</h4>
              <button class="add-scan" style="display: none">
                Upload Scan
              </button>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Scan ID</th>
                      <th>Foetus Age in Weeks</th>
                      <th>Scanned On</th>
                      <th>Submitted on:</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody v-if="scans && scans.length >= 1">
                    <template v-for="(scan, index) in scans" :key="index">
                      <tr
                        v-if="!docNotExist(scan.doctor_scan_results)"
                        :id="`SCAN${scan.uuid}`"
                      >
                        <td>{{ scan.scanID.slice(0, 6) }}XXXXX</td>
                        <td>{{ scan.foetus_age_in_weeks }} Weeks</td>
                        <td>{{ moment(scan.date_added).fromNow() }}</td>
                        <td>
                          {{
                            moment(
                              getSubDate(scan.doctor_scan_results)
                            ).fromNow()
                          }}
                        </td>
                        <td>
                          <button
                            v-if="docDateVerify(scan.doctor_scan_results)"
                            @click="
                              setDataInputs(scan.doctor_scan_results);
                              selectedScanData = scan;
                            "
                            style="
                              background-color: var(--primary);
                              color: var(--white);
                              padding: 0.5rem 1rem;
                              border: none;
                              border-radius: var(--border-radius-1);
                            "
                          >
                            Edit Result
                          </button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-modal" v-if="selectedScanData">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"
            ><svg
              @click="selectedScanData = null"
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 -960 960 960"
              width="48"
            >
              <path
                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"
              /></svg
          ></span>
        </div>

        <div class="report">
          <div class="center-img">
            <div class="imgbox" style="">
              <img class="profile" :src="selectedScanData.scan_image" alt="" />
            </div>
          </div>
          <div class="item">
            <label>Select Orientation Class</label>
            <select name="" id="" v-model="orientation">
              <option value="" selected disabled>select Orientation</option>
              <option value="hdvb">hdvb</option>
              <option value="hdvf">hdvf</option>
              <option value="huvb">huvb</option>
              <option value="huvf">huvf</option>
            </select>
          </div>
          <div class="item">
            <label>Select Anatomy Class</label>
            <select name="" id="" v-model="anatomy">
              <option value="" selected disabled>select Anatomy Class</option>
              <option value="abdomen_h">abdomen_h</option>
              <option value="abdomen_v">abdomen_v</option>
              <option value="arms_h">arms_h</option>
              <option value="head_h">head_h</option>
              <option value="head_v">head_v</option>
              <option value="leg_h">leg_h</option>
              <option value="leg_v">leg_v</option>
            </select>
          </div>
          <div class="item">
            <label>Select Planes Class</label>
            <select name="" id="" v-model="planes">
              <option value="" selected disabled>select Planes Class</option>
              <option value="AC_PLANE">AC_PLANE</option>
              <option value="BPD_PLANE">BPD_PLANE</option>
              <option value="FL_PLANE">FL_PLANE</option>
              <option value="NO_PLANE">NO_PLANE</option>
            </select>
          </div>
          <div class="item">
            <label>Select Fetal Planes Class</label>
            <select name="" id="" v-model="fetal_planes">
              <option value="" selected disabled>
                select Fetal Planes Class
              </option>
              <option value="fetal abdomen">fetal abdomen</option>
              <option value="fetal brain">fetal brain</option>
              <option value="fetal femur">fetal femur</option>
              <option value="fetal thorax">fetal thorax</option>
              <option value="maternal cervix">maternal cervix</option>
              <option value="other">other</option>
            </select>
          </div>
        </div>
        <button
          @click="
            selected_scan_id = selectedScanData.scanID;
            submitReport();
          "
        >
          Update Report <i class="fa fa-edit"></i>
        </button>
      </div>
    </div>

    <div class="notification-box" style="display: none">
      <div class="msg-sidebar notifications msg-noti">
        <div class="topnav-dropdown-header">
          <span>Messages</span>
        </div>
        <div class="drop-scroll msg-list-scroll" id="msg_list">
          <ul class="list-box">
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Richard Miles </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item new-message">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">John Doe</span>
                    <span class="message-time">1 Aug</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Tarah Shropshire </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Mike Litorus</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Catherine Manseau </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">D</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Domenic Houston </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">B</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Buster Wigton </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Rolland Webber </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Claire Mapes </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Melita Faucher</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Jeffery Lalor</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">L</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Loren Gatlin</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Tarah Shropshire</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <a href="chat.html">See all messages</a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

var moment = require("moment");

export default {
  name: "mothers",
  components: {},
  data() {
    return {
      scans: [],
      moment: moment,
      selectedScanData: null,
      searchQuery: "",
      orientation: "",
      anatomy: "",
      planes: "",
      fetal_planes: "",
      selected_scan_id: null,
    };
  },
  mounted() {
    if (!this.$store.state.doctor_username) {
      this.$router.push("/");
    } else {
      document.title = "Tamela Dashboard | Scans";
      this.$store.commit("currentPage", "history");
      document.querySelector(".page-wrapper").style.paddingTop = "70px";
      this.$root.loadScripts();
      this.$root.showLoader = true;
      this.$root.resetSideBar();
      this.getScans();
    }
  },
  methods: {
    docNotExist(arr) {
      console.log(arr);
      const doc_uid = this.$store.state.doctor_username;
      if (!arr) {
        return true;
      }
      return arr.findIndex((item) => item.doctor_username === doc_uid) === -1;
    },
    isWithin48HoursFromNow(isoDateTime) {
      // Parse ISO datetime string into a Date object
      const targetDate = new Date(isoDateTime);
      // Get the current date and time
      const currentDate = new Date();
      // Calculate the difference in milliseconds between the target date and the current date
      const differenceInMilliseconds = targetDate - currentDate;
      // Convert milliseconds to hours
      const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
      // Check if the difference is within 48 hours (48 * 60 minutes * 60 seconds * 1000 milliseconds)
      return Math.abs(differenceInHours) <= 48;
    },
    docDateVerify(arr) {
      const doc_uid = this.$store.state.doctor_username;
      if (arr) {
        let index = arr.findIndex((item) => item.doctor_username === doc_uid);
        if (index > -1) {
          const scan_result = arr[index];
          if (scan_result.date_submitted) {
            return this.isWithin48HoursFromNow(scan_result.date_submitted);
          }
        }
      }
      return false;
    },
    getSubDate(arr) {
      const doc_uid = this.$store.state.doctor_username;
      if (arr) {
        let index = arr.findIndex((item) => item.doctor_username === doc_uid);
        if (index > -1) {
          const scan_result = arr[index];
          if (scan_result.date_submitted) {
            return scan_result.date_submitted || null;
          }
        }
      }
      return null;
    },
    setDataInputs(arr) {
      const doc_uid = this.$store.state.doctor_username;
      if (arr) {
        let index = arr.findIndex((item) => item.doctor_username === doc_uid);
        if (index > -1) {
          const scan_result = arr[index];
          this.orientation = scan_result.orientation;
          this.anatomy = scan_result.anatomy;
          this.planes = scan_result.planes;
          this.fetal_planes = scan_result.fetal_planes;
        }
      }
    },
    async refreshToken(func) {
      await axios
        .post("api/token/refresh", {
          refresh: this.$store.state.refresh,
        })
        .then((response) => {
          //console.log(response.data)
          if (response.data.access) {
            this.$store.commit("setAccess", response.data.access);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.access;
            func();
          } else {
            //this.router.push('/login')
          }
        })
        .catch((error) => {
          //console.log(error)
        });
    },
    isMatch(scan) {
      const searchLowerCase = this.searchQuery.toLowerCase();
      return (
        scan.mother_data.tamelaID.includes(this.searchQuery.toUpperCase()) ||
        scan.scanID.includes(this.searchQuery.toUpperCase()) ||
        scan.mother_data.lastname.toLowerCase().includes(searchLowerCase) ||
        scan.mother_data.firstname.toLowerCase().includes(searchLowerCase) ||
        scan.mother_data.othername.toLowerCase().includes(searchLowerCase) ||
        scan.foetus_label.toLowerCase().includes(searchLowerCase)
      );
    },
    async getScans(pk = null) {
      this.$root.showLoader = true;
      function findDoctorIndex(doctorScanResults, doctorUsername) {
        if (!doctorScanResults) return -1; // Return -1 if doctorScanResults is null or undefined
        return doctorScanResults.findIndex(
          (result) => result.doctor_username === doctorUsername
        );
      }

      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      if (!pk) {
        await axios
          .get("api/v1/scan", { headers })
          .then((response) => {
            const data = response.data.data;

            console.log(data);

            const targetDoctorUsername = this.$store.state.doctor_username;
            data.sort((a, b) => {
              // Find the index of the doctor_scan_results object with the target doctor_username
              const doctorIndexA = findDoctorIndex(
                a.doctor_scan_results,
                targetDoctorUsername
              );
              const doctorIndexB = findDoctorIndex(
                b.doctor_scan_results,
                targetDoctorUsername
              );

              // Get the date_submitted values from the doctor_scan_results objects at the found indexes
              const dateA =
                doctorIndexA !== -1
                  ? new Date(a.doctor_scan_results[doctorIndexA].date_submitted)
                  : null;
              const dateB =
                doctorIndexB !== -1
                  ? new Date(b.doctor_scan_results[doctorIndexB].date_submitted)
                  : null;

              // Sort in descending order (latest to earliest)
              return dateB - dateA;
            });

            this.scans = data;

            this.$root.showLoader = false;
          })
          .catch((error) => {
            console.log(error);
            if (error.code == "ERR_BAD_REQUEST") {
              this.refreshToken(this.getScans);
            } else if (error.code != "ERR_NETWORK") {
            } else {
              console.error(error);
              const error_message =
                error.code === "ERR_NETWORK"
                  ? "Unable to Connect. Check your network connection."
                  : error.code;
              toast.error(error_message, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            //this.$root.showLoader = false;
          });
      }
    },
    async submitReport() {
      this.$root.showLoader = true;
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const uuid = this.selectedScanData.uuid;

      const data = {
        scanID: this.selected_scan_id,
        results: {
          orientation: this.orientation,
          anatomy: this.anatomy,
          planes: this.planes,
          fetal_planes: this.fetal_planes,
          doctor_username: this.$store.state.doctor_username,
          date_submitted: new Date().toISOString(),
        },
      };

      if (this.selected_scan_id) {
        await axios
          .post("api/v1/doctors/scan", data, { headers })
          .then((response) => {
            if (response.data.status) {
              console.log(response.data);
              toast.success("Updated Successfully", {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
              });
              this.selectedScanData = null;
              this.$root.showLoader = false;
              this.getScans();
            } else {
              toast.error(response.data.message, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
              });
              this.$root.showLoader = false;
            }
          })
          .catch((error) => {
            if (error.code != "ERR_NETWORK") {
              if (error.code == "ERR_BAD_REQUEST") {
                this.refreshToken(this.getScans);
              }
            } else {
              console.error(error);
              const error_message =
                error.code === "ERR_NETWORK"
                  ? "Unable to Connect. Check your network connection."
                  : error.code;
              toast.error(error_message, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            //this.$root.showLoader = false;
          });
      }
    },
    saveAsPDF(mother) {
      let HTML_Width = document.querySelector("#scanReport").offsetWidth;
      let HTML_Height = document.querySelector("#scanReport").offsetHeight;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + top_left_margin * 2;
      let PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;

      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas(document.querySelector("#scanReport")).then(function (
        canvas
      ) {
        let imgData = canvas.toDataURL("image/jpeg", 1.0);
        let pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );
        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(
            imgData,
            "JPG",
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          );
          let htmlText = document.querySelector("#scanReport").innerHTML;
          pdf.text(20, HTML_Height + 20, htmlText); // Adjust the position as needed
        }
        const filename = `${mother.lastname}_${mother.firstname}_${mother.tamelaID}_SCAN`;
        pdf.save(`${filename}.pdf`);
        //document.querySelector("#scanReport").hide();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-size: 20px;
}
.add-scan {
  margin: 1rem 0;
  border-radius: var(--border-radius-1);
  border: 2px dotted rgb(224, 104, 58);
  padding: 0.5rem 1rem;
  font-size: 14px;
  color: var(--white);
  background: rgb(224, 104, 58);
  outline: none;
  cursor: pointer;

  &:hover {
    color: rgb(224, 104, 58);
    background: var(--background);
  }
}

.search-box {
  margin-bottom: 1rem;
  input {
    background-color: var(--light);
    color: var(--dark);
    width: 100%;
    max-width: 40rem;
    border: 1px solid transparent;
    border-radius: var(--border-radius-1);
    padding: 0.5rem;
  }
}

.wideTable {
  width: 100% !important;
  max-width: fit-content;

  table th {
    color: var(--dark);
  }

  .table-responsive tbody tr:hover {
    background: var(--light);
  }
  tr td {
    text-transform: capitalize;
    color: var(--black);
  }
  tr td:first-child {
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
  }
}

.content-modal {
  display: flex;
  justify-content: center;
  padding: 1rem;
  padding-top: 5rem;
  width: 100vw;
  height: 100vh;
  background: var(--modal-background);
  position: fixed;
  top: 0;

  .modal-content {
    width: 100%;
    max-width: 30rem;
    height: fit-content;
    background: var(--white);
    padding: 1rem;
    border-radius: var(--card-border-radius);

    .report {
      position: relative;
      .top-level {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 40% 60%;

        .info {
          padding-left: 2rem;
          margin-top: 1rem;
          border-left: 1px solid var(--info-dark);
        }
        .center-img {
          padding: 1rem;
          align-items: flex-start;

          .imgbox {
            /*
              background-image: url('http://192.168.0.132:8000/media/mothers/avatar-95fb4ee46aae3dcff7a9f901dc0dd0f6.jpg');
              background-size:100%;
              background-repeat: no-repeat;
              */

            flex: 1;
            width: 100%;
            height: 15rem;
            max-width: 15rem;
            border-radius: 0.5rem;
          }
        }
      }

      .report-info {
        padding-top: 1rem;
        border-top: 1px solid var(--info-dark);
      }
    }

    .close-button {
      .svg-icon {
        cursor: pointer;
        svg {
          fill: #e32;
          width: 2rem;
          height: 2rem;
        }
      }
    }

    .center-img {
      display: flex;
      width: 100%;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    button {
      border: 1px solid transparent;
      padding: 0.5rem 1rem;
      font-size: 16px;
      color: var(--white);
      background: var(--primary);
      border-radius: var(--border-radius-1);
      margin-top: 2rem;

      &:hover {
        background: var(--white);
        color: var(--primary);
        border: 1px solid var(--primary);
      }
    }

    .items,
    .info,
    .report-info {
      h3 {
        color: var(--dark);
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 400;

        &.title {
          font-size: 20px;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;
        }
        span {
          color: var(--info-dark);
          font-size: 14px !important;
          font-weight: 600 !important;
        }
      }
    }

    .imgbox {
      width: 10rem;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      background-color: var(--info-light);
      background: var(--info-light);
      margin-bottom: 1rem;

      img.profile {
        width: 100%;
        background-color: var(--info-light);
        object-fit: cover;
      }
    }
  }
}

button {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .content {
    width: 100%;
    padding: 0;
  }
}
@media screen and (min-width: 800px) {
  .card-box {
    width: max-content;
  }
  .content {
    padding: 1rem !important;
  }
}
@media screen and (max-width: 600px) {
  .modal-content {
    button {
      width: 100%;
    }
  }
}

.primary,
span.primary {
  color: var(--primary) !important;
}
.secondary,
span.secondary {
  color: var(--secondary) !important;
  background: var(--white) !important;
}

.awaiting,
span.awaiting {
  color: var(--warning) !important;
}
.success,
span.success {
  color: var(--success) !important;
}
.failed,
span.failed {
  color: var(--danger) !important;
}

.item select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: var(--border-radius-1);
}
.item label {
  text-transform: capitalize;
  font-size: 1rem;
  margin-top: 1rem;
}
</style>
