<template>
    <main>
      <div class="content">
        <h2 class="card-title" style="color:var(--primary);font-weight: 600;">My Profile</h2>
        <div class="row">
        
          <div class="col-lg-6 wideTable">
            <div class="card-box">
              <div class="card-block" v-if="userData" style="justify-content:center;display:flex;flex-direction:column;align-items:center;">
                
                <div style="width:8rem;
                    height:8rem;position:relative;overflow-hidden;display:flex;justify-content:center;">
                      <img v-if="userData.staff_user_details.image" :src="userData.staff_user_details.image" style="width:100%;height:100%;object-fit:cover;border-radius:50%;">
                      <img v-else src="https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_640.png" style="width:100%;height:100%;object-fit:cover;border-radius:50%;">
                    
                    </div>
                    <span style="margin-top:1rem;color:var(--secondary);cursor:pointer;" v-if="userData.staff_user_details.image">Change Image</span>
                    <span style="margin-top:1rem;color:var(--secondary);cursor:pointer;" v-else>Set Image</span>
                
                <div class="table-responsive" style="margin-top:1rem;">
                  
                  <table class="table mb-0">
                   
                    
                    <tbody v-if="userData.usercat=='doctor'">
                     
                      <tr>
                        <td>Full Name</td>
                        <td>{{userData.staff_user_details.name}}</td>
                      </tr>
                      <tr>
                        <td>Tamela ID</td>
                        <td>{{userData.staff_user_details.tamelaID}}</td>
                      </tr>
                      <tr>
                        <td>Role</td>
                        <td>{{userData.role}}</td>
                      </tr>
  
                      <tr>
                        <td>Hospital</td>
                        <td>{{userData.hospitalAdminInfo.name}}</td>
                      </tr>
                      <tr>
                        <td>Contact</td>
                        <td style="text-transform:lowercase">{{userData.staff_user_details.email}}</td>
                        <td style="text-transform:lowercase">{{userData.staff_user_details.phone_number}}</td>
                      </tr>
  
                    </tbody>
                  </table>
                  <button class="add-scan" style="margin-top:2rem" @click="showChangePasswordForm=true" v-if="!showChangePasswordForm">Change Password</button>
                
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 wideTable" v-if="showChangePasswordForm">
            <div class="card-box">
              <div class="card-block" v-if="userData" style="justify-content:center;display:flex;flex-direction:column;align-items:center;">
                
               
                <div class="table-responsive change-password" style="margin-top:1rem;position:relative;">
                  
                  <div class="close-button">
                    <span class="svg-icon" style="background:#e32;padding:.2rem .5rem;align-items:center;display:flex;width:fit-content;cursor:pointer;border-radius:var(--border-radius-1)" @click="showChangePasswordForm=false"><span>Close</span><svg style="width:1.5rem;height:1.5rem;fill:#fff;"
                            xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                            <path
                                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                        </svg></span>
                  </div>
                  <h3 class="title" style="font-size:18px;color:var(--primary);margin-top:1rem;">Change Password</h3>
                  <form class="field change-password" style="display:flex;flex-direction:column;">
                    <input type="hidden" name="username" placeholder="username">
                    <input type="hidden" name="password" placeholder="password">
                    <input type="text"  class="blank"  autocomplete="off" spellcheck="false" placeholder="Old Password" v-model="oldPassword"></input>
                    <input type="text" class="blank"  autocomplete="off"  spellcheck="false" placeholder="New Password"  v-model="newPassword"></input>
                    <input type="text"  class="blank" autocomplete="off" spellcheck="false" placeholder="Confirm Password"  v-model="confirmPassword"></input>
                   
                  </form>
                    
                    
                  
                  <button type="button" class="add-scan" style="margin-top:2rem" @click="changePassword()">Change Password</button>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
  
      
  
  
      
    </main>
  </template>
  
  <script>
  import axios from 'axios'
  import jsPDF from 'jspdf';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  
  var moment = require('moment')
  
  export default {
    name: 'staff',
    components: {},
    data() {
      return {
        users: [],
        moment: moment,
        selectedUser: null,
        selectedUserData: null,
        searchQuery:'',
        hospitalPatientsModal:false,
        patients:[],
        staffInfo:{
          name:"",
          address:"",
          phoneNumber:"",
          email:"",
        },
        createUsermodal:false,
        userData:null,
        showChangePasswordForm:false,
        oldPassword:'',
        newPassword:'',
        confirmPassword:'',
        hospitalAdminInfo:null,
      }
    },
    mounted() {

      document.title = "Tamela Dashboard | Staff Management"
        this.$store.commit('currentPage', 'profile')
        document.querySelector(".page-wrapper").style.paddingTop = '70px';
        this.$root.loadScripts();
        this.getUserData();
        this.$root.showLoader = true;
        this.$root.resetSideBar();
      /*
      if (this.$store.state.doctor_username || this.$store.state.usercat === 'doctor') {
        this.$router.push('/doctor')
      } 
      else {
        document.title = "Tamela Dashboard | Staff Management"
        this.$store.commit('currentPage', 'staff')
        document.querySelector(".page-wrapper").style.paddingTop = '70px';
        this.$root.loadScripts();
        this.$root.showLoader = true;
        this.$root.resetSideBar();
        this.getUsers();
      }
      */
    },
    methods: {
      async getUserData() {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      try {
        const response = await axios.get(
          "accounts/userdata?user=current&usercat="+this.$store.state.usercat,
          { headers }
        );
        this.userData = response.data.data;
        console.log(this.userData);

        if (
          this.userData.usercat === "hospitaladmin" ||
          this.userData.usercat === "doctor" 
        ) {
          this.$store.commit('setUsercat',this.userData.usercat)
          document.querySelector(".page-wrapper").style.paddingTop = "70px";
          this.$root.loadScripts();
          this.$root.resetSideBar();
          // Uncomment the following lines if these methods are needed
          //this.getScans();
          //this.getMothers();
          //this.getDashboardData();
          //this.setChartData();
          this.$root.showLoader = false;
        } else {
          this.$root.showLoader = false;
          this.$router.push("/login");
        }


      } catch (error) {
        if (error.response) {
          // Error responses from the server
          const status = error.response.status;
          if (status === 401) {
            // Unauthorized access
            await this.refreshToken(this.getUserData);
          } else {
            // Handle other status codes
            console.error(`Error ${status}: ${error.response.data.message}`);
            toast.error(`Error ${status}: ${error.response.data.message}`, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (error.request) {
          // Request made but no response received
          console.error("No response received:", error.request);
          toast.error("No response received. Please try again later.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Other errors
          console.error("Error", error);
          toast.error(`Error: ${error}`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
    async refreshToken(callback) {
      try {
        const response = await axios.post("api/token/refresh", {
          refresh: this.$store.state.refresh,
        });

        if (response.data.access) {
          this.$store.commit("setAccess", response.data.access);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.access;
          await callback();
        } else {
          this.$router.push("/login");
        }
      } catch (error) {
        console.error("Failed to refresh token", error);
        this.$router.push("/login");
      }
    },
    async changePassword(){
      if (this.newPassword.length < 8){
        toast.error(`Password Must Be At Least 8 Characters Long`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        return;
      }

      else if(this.newPassword !== this.confirmPassword){
        toast.error(`Passwords Must Match`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
      } else {
        try {
            // Use await instead of .then to handle async operations
            const accessToken = this.$store.state.access;
            const headers = {
              Authorization: `Bearer ${accessToken}`,
            };
            const data = {
              'old_password':this.oldPassword,
              'new_password':this.newPassword,
            }
            this.$root.showLoader = true;
            const response = await axios.post(`accounts/password/change`,data, { headers });
            console.log(response.data);
            if (response.data.status) {
              // Clear image and preview after successful upload
              //toast.success("Vitals Uploaded", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
              
              toast.success("Password changed Successfully", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
              this.oldPassword = ''
              this.newPassword=''
              this.confirmPassword=''
              this.showChangePasswordForm = false;
            } 
            else {
              toast.success(response.data.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
              this.oldPassword = ''
              this.newPassword=''
              this.confirmPassword=''
              this.showChangePasswordForm = false;
            }
            this.$root.showLoader = false;
      } catch (error) {
        // Handle errors
        console.log(error)
       
        if (error.code != 'ERR_NETWORK') {
          if (error.response.status === 401) {
            this.$root.showLoader = true;
            this.refreshToken(this.changePassword);
          }
          if(error.code === "ERR_BAD_REQUEST" && error.response.status === 400) {
              if(error.response.data){
              let keys=Object.keys(error.response.data)
              this.$root.showLoader = false;
              for(let x=0;x<keys.length;x++) {
                console.log(error.response.data[keys[x]][0])
                toast.error(error.response.data[keys[x]][0], { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
              
              }
            } else {
              this.$root.showLoader = false;
              toast.error(error.code, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
            
            }
          }
        } else {
          console.error(error);
          this.$root.showLoader = false;
          const error_message = error.code === 'ERR_NETWORK' ? 'Unable to Connect. Check your network connection.' : error.code;
          toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
          this.$root.showLoader = false;
        }
      }
      }
    },
  
    }
  }
  </script>
  
  <style lang="scss" scoped>


  .wideTable:nth-child(2){
    opacity:0;
    animation:fadein .3s ease forwards;
  }

  @keyframes fadein {
    100%{
      opacity: 1;
    }
    
  }
  .content-modal.create{
    left:0;
  }

  .table-responsive.change-password{
    width:25rem;

  }

  .field.change-password{
    width:100%;
    overflow-x:hidden;
    
    input{
      width:100%;
      padding:.2rem .5rem;
      font-size:16px;
      margin:.2rem;

      &.blank {
        -webkit-text-security: disc !important; /* For WebKit-based browsers */
        text-security: disc !important; 
        
    }
    }



      
  }
  .content-modal .modal-content .add-mother-form{
    margin-top:1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    .field, .field2{
      display: flex;
      width:100%;
    }
    
    input, textarea{
      resize: none;
      width:100%;
      margin:.2rem;
      padding: .5rem;
      border-radius: var(--border-radius-1);
      background: var(--light);
      color:var(--dark);
      margin-top:.5rem;
      border:1px solid transparent;
    }
  
  }

  .card-title{
      font-size:20px;
      padding-left:1rem;
      text-transform: capitalize;
    }
  .add-scan {
      margin:1rem 0;
      border-radius:var(--border-radius-1);
      border:2px dotted rgb(224, 104, 58);
      padding:.5rem 1rem;
      font-size:14px;
      color:var(--white);
      background: rgb(224, 104, 58);
      outline:none;
      cursor: pointer;
  
      &:hover{
        color:rgb(224, 104, 58);
        background:var(--background);
      }
    }
  
    .search-box{
      margin-bottom:1rem;
      input{
        background-color:var(--light);
        color:var(--dark);
        width:100%;
        max-width:40rem;
        border:1px solid transparent;
        border-radius:var(--border-radius-1);
        padding:.5rem
      }
    }
  
    .wideTable{
      width:100% !important;
      max-width: fit-content;
  
      table th{
        color:var(--dark);
      }
  
      .table-responsive tbody tr:hover{
        background: var(--light);
      }
      tr td{
        text-transform: capitalize;
        color:var(--black);
        
        &.linkify{
          color:var(--secondary);
          font-weight: 600;
        }
      }
      tr td:first-child{
        color:var(--primary);
        font-weight:600;
        cursor:pointer;
      }
    }
  
    .content-modal{
      display: flex;
      justify-content: center;
      padding:1rem;
      padding-top:5rem;
      width: 100vw;
      height: 100vh;
      background: var(--modal-background);
      position: fixed;
      top:0;
  
  
      .modal-content{
        width:100%;
        max-width:30rem;
        height:fit-content;
        background: var(--white);
        padding:1rem;
        border-radius: var(--card-border-radius);

        
  
        .report{
          position: relative;
          .top-level{
            display:grid;
            grid-auto-flow: column;
            grid-template-columns: 40% 60%;
  
            .info{
              padding-left:2rem;
              margin-top:1rem;
              border-left:1px solid var(--info-dark);
            }
            .center-img {
              padding:1rem;
              align-items: flex-start;
              
              .imgbox{
  
                /*
                background-image: url('http://192.168.0.132:8000/media/mothers/avatar-95fb4ee46aae3dcff7a9f901dc0dd0f6.jpg');
                background-size:100%;
                background-repeat: no-repeat;
                */
  
                flex:1;
                width:100%;
                height:10rem;
                max-width: 10rem;
                border-radius: .5rem;
              }
            }
          }
  
          .report-info{
            padding-top:1rem;
           
          }
        }
  
        .close-button{
          
  
          .svg-icon{
            cursor: pointer;
            svg{
              fill:#e32;
              width:2rem;
              height:2rem;
            }
          }
        }
  
        .center-img{
          display: flex;
          width: 100%;
          justify-content: center;
          text-align: center;
          align-items: center;
        }
  
        button{
            border:1px solid transparent;
            padding:.5rem 1rem;
            font-size:16px;
            color:var(--white);
            background: var(--primary);
            border-radius:var(--border-radius-1);
            margin-top:2rem;
  
            &:hover{
              background:var(--white);
              color: var(--primary);
              border:1px solid var(--primary);
              
            }
          }
  
        .items, .info, .report-info{
          h3{
            color:var(--dark);
            text-transform: capitalize;
            font-size:16px;
            font-weight:400;

            &:first-child{
              font-size:18px !important;
              font-weight: 600;
              span{
                font-size: 18px !important;
                font-weight: 600;
              }
            }
  
            &.title{
              font-size:20px;
              font-weight:600;
              text-align: center;
              text-transform: uppercase;
            }
            span{
              color:var(--info-dark);
              font-size:14px !important;
              font-weight:400 !important;

              

              &.set-active{
                margin-left:1rem;
                text-decoration: underline;
                color:var(--danger);
                cursor: pointer;
                font-weight: 600;

                &.success{
                  color:var(--success);
                }
              }
            }
          }
  
        }
  
        .imgbox{
            width:10rem;
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            background-color:var(--info-light);
            background:var(--info-light);
            margin-bottom:1rem;
  
            img.profile{
              width:100%;
              background-color:var(--info-light);
              object-fit: cover;
            }
          }

          
      }
    }
  
    button{
      cursor: pointer;
    }
  
  
    @media screen and (max-width:768px) {
      .table-responsive.change-password{
        width:100vw;
        padding:1rem;
    
      }
    
      .field.change-password{
        width:100%;
        overflow-x:hidden;
        
        input{
          width:100%;
          padding:.2rem .5rem;
          font-size:16px;
          margin:.2rem;
          border-radius:var(--border-radius-1);
        }

        button{
          width:100%;
        }
      }

      .content{
        width:100%;
        padding:0;
      }

      .card-title{
        margin-top: 2rem;
        
      }
      .floating-modal {
        .modal-content{
          padding:0 !important;

          
        }
      }
    }
     @media screen and (min-width:800px) {
      .card-box{
        width:max-content;
      }
      .content{
        padding:1rem !important;
      }

    }
    @media screen and (max-width:600px) {
      .modal-content{
        
        button{
          width:100%;
        }
      }
    }
  
    .primary, span.primary{
      color:var(--primary) !important;
    }
    .secondary, span.secondary{
      color:var(--secondary) !important;
      background:var(--white) !important;
    }
  
    .awaiting, span.awaiting{
          color:var(--warning) !important;
      }
      .success, span.success{
          color:var(--success) !important;
          
      }
      .failed, span.failed{
          color:var(--danger) !important;
      }
    


      .floating-modal{
        display: flex;
        width:100vw;
        height: 100vh;
        position: fixed;
        
        top:0;
        left:0;
        z-index:1000;
        background: rgba(0,0,0,.5);
        .modal-content{
          padding:4rem;
          position: relative;
          background:var(--background);
          .back{
            display: flex;
            width: 100%;
            position: relative;
            justify-content: flex-end;
            
          }
          .back button{
           
            
            
            border-radius:var(--border-radius-1);
            background:var(--danger);
            border:1px solid var(--danger);
            color:var(--white);

            &:hover{
              background:var(--white);
              color:var(--danger);
              border:1px solid var(--danger);
            }

          }

          .content{
            margin-top:3rem;
          }
        }

      }


    
  </style>