<template>
  <main>
    <div class="content">

      <div class="row" v-if="selectedPatient">
      
        <div class="col-lg-6 wideTable">
          <div class="card-box">
            <div class="card-block">
              <h4 class="card-title">AI Scan Results for <span style="text-transform: capitalize;color:var(--primary);">{{selectedPatient.lastname}} {{selectedPatient.firstname}} {{selectedPatient.othername}}</span></h4>
                
              <div class="side-by-side" style="display:flex;align-items:center;justify-content: space-between;margin-bottom:1rem;">
               <router-link to="/mothers" style="background-color: var(--danger);padding:.5rem 1rem;border:none;cursor:pointer;border-radius:var(--border-radius-1);color:#fff;">Back</router-link>
                
                <div class="imgbox selectedPatient" v-if="selectedPatient" style="width:5rem;height:5rem;overflow:hidden;border-radius: 50%;">
                <img :src="selectedPatient.mother_image" alt="" style="object-fit: cover;width:100%">
              </div>
             
              </div>
               <button class="add-scan" style="display:none">Upload Scan</button>
               <button class="add-scan" style="display:block;background:var(--primary);border:none;" @click="inputDataModal=true">Download Raw Input Data <i class="fa-solid fa-download"></i></button>
               <div class="search-box">
                <input
                  type="text"
                  placeholder="Filter by AI Model or Result"
                  v-model="searchQuery2"
                />
              </div>
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Scan ID</th>
                      <th>Mother's ID</th>
                      <th>Mother's Name</th>
                      
                      <th>Scan Status</th>
                      <th>AI Model</th>
                      <th>AI Model Result Summary</th>
                      <th>Ai Model Confidence</th>
                      <th>Foetus Label</th>
                      <th>Foetus Age in Weeks</th>
                      <th>Scan Date</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody v-if="scans && scans.length >= 1">
                    <tr v-for="(scan, index) in scans" :key="index" v-show="isMatch(scan) && isMatch2(scan)">
                      <td @click="selectedScanData = scan">{{ scan.scanID }}</td>
                      <td>{{ scan.mother_data.tamelaID }}</td>
                      <td>{{ scan.mother_data.lastname }} {{ scan.mother_data.firstname }} {{ scan.mother_data.othername
                        }}</td>
                      
                      <td :class="scan.status">{{scan.status}}</td>
                      <td>{{scan.model_name}}</td>
                      <td>{{scan.model_result_summary}}</td>
                      <td class="success">{{scan.model_result_confidence}}%</td>
                      <td>{{scan.foetus_label}}</td>
                      <td>{{scan.foetus_age_in_weeks}}</td>
                      <td>{{ moment(`${scan.date_added}`).format("MMMM DD, YYYY. HH:MM:SS") }}</td>
                      <td></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="content-modal" v-if="selectedScanData">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"><svg @click="selectedScanData = null" xmlns="http://www.w3.org/2000/svg" height="48"
              viewBox="0 -960 960 960" width="48">
              <path
                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
            </svg></span>
        </div>


        <div class="report" ref="content" id="scanReport">
          <div class="top-level">
            <div class="center-img">

              <div class="imgbox" style="">
                <img class="profile" :src="selectedScanData.mother_data.image" alt="">
              </div>
            </div>

            <div class="info">
              <h3>Tamela ID: <span class="primary">{{ selectedScanData.mother_data.tamelaID }}</span></h3>
              <h3 style="text-transform:uppercase">Name: <span>{{ selectedScanData.mother_data.lastname}} {{
                  selectedScanData.mother_data.firstname}} {{ selectedScanData.mother_data.othername}}</span></h3>
              <h3>Scan ID: <span class="primary">{{ selectedScanData.scanID }}</span></h3>
              <h3>Scan Date: <span>{{ moment(`${selectedScanData.date_added}`).format("DD MMM, YYYY") }}</span></h3>
              <h3>Scan Status: <span :class="selectedScanData.status" style="font-weight:400;">{{
                  selectedScanData.status }}</span></h3>
              
            </div>

          </div>
          <div class="report-info">
           <div class="side-by-side">
            <h3>Mother's Age: <span>{{ selectedScanData.mother_data.age }}</span></h3>
            <h3>Foetus age: <span>{{ selectedScanData.foetus_age_in_weeks }} Weeks</span></h3>
           </div>
           <div class="side-by-side">
            <h3>city: <span>{{ selectedScanData.mother_data.city }}</span></h3>
            <h3>state: <span>{{ selectedScanData.mother_data.state || "None" }} State.</span></h3>
         
           </div>
            <h3>Address: <span>{{ selectedScanData.mother_data.address }}</span></h3>
            </div>

          <div class="report-results" style="margin-top:1rem;border-top:1px solid var(--dark);padding-top:1rem;overflow-y:auto"></div>
            <h3 style="font-size:16px;text-align: center;text-transform:uppercase;margin-bottom:1rem;">SCAN MODEL: <span style="font-weight:600;color:var(--primary);">{{ selectedScanData.model_name}}</span></h3>
            <h3 style="font-size:16px;">Issues Found: <span style="font-weight:400;color:var(--info-dark);">{{ selectedScanData.issue_present?"ISSUES PRESENT":"NONE EXISTENT" }}</span> <span @click="setPriorityModal=true" v-if="!selectedScanData.issue_present" class="failed" style="text-decoration: underline;margin-left:2rem;cursor:pointer;">Report Issues</span></h3>
            <h3  style="font-size:16px;" v-if="selectedScanData.issue_present">Issues Priority <span style="font-weight:400;color:var(--info-dark);">{{selectedScanData.issue_priority }}</span></h3>
            <h3  style="font-size:16px;" v-if="selectedScanData.issue_present">Issues Description <span style="font-weight:400;color:var(--info-dark);">{{ selectedScanData.issue_details }}</span></h3>
            <h3  style="font-size:16px;margin-top:1rem;"> Scan Result Summary: <span style="font-weight:400;color:var(--info-dark);">{{selectedScanData.model_result_summary }}</span></h3>
            <h3  style="font-size:16px;margin-top:1rem;">Detailed Scan Result Summary: <span style="font-weight:400;color:var(--info-dark);">{{selectedScanData.results }}</span></h3>

            <h3  style="font-size:16px;margin-top:1rem;"> AI Scan Result Confidence: <span style="font-weight:400;color:var(--info-dark);" :class="{'success':selectedScanData.model_result_confidence>=80,'awaiting':selectedScanData.model_result_confidence>=50 && selectedScanData.model_result_confidence<80,'failed':selectedScanData.model_result_confidence<50}">{{selectedScanData.model_result_confidence }}%</span></h3>
            <a target="_blank" :href="selectedScanData.scan_image" v-if="selectedScanData.scan_image">Click to View Image Data</a>
        </div>

        <button @click="saveAsPDF(selectedScanData.mother_data)">Download Report <i class="fa fa-download"></i></button>
      </div>
    </div>


    <div class="content-modal" style="z-index:999;" v-if="setPriorityModal">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"><svg @click="setPriorityModal = false" xmlns="http://www.w3.org/2000/svg" height="48"
              viewBox="0 -960 960 960" width="48">
              <path
                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
            </svg></span>
        </div>


        <div class="report priority">
          <div class="field">
            <label for="" style="margin-top:1rem;font-weight:bold;font-size:16px;">Priority</label>
            <select name="" id="" v-model="selectPriority">
              <option value="" selected disabled>Select a Priority</option>
              <option value="3">Low</option>
              <option value="2">Medium</option>
              <option value="1">High</option>
            </select>
          </div>
        </div>

        <div class="field" style="margin-top:1rem;">
          <textarea v-model="selectPriorityDescription" style="width:100%;background:var(--light)" name="" id="" cols="30" rows="10" placeholder="Description"></textarea>
        </div>

        <button :disabled="!selectPriority"  @click="updatePriority(selectedScanData)">Update Priority <i class="fa fa-spinner"></i></button>
      </div>
    </div>

    <div class="content-modal" style="z-index:999;" v-if="inputDataModal">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"><svg @click="inputDataModal = false" xmlns="http://www.w3.org/2000/svg" height="48"
              viewBox="0 -960 960 960" width="48">
              <path
                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
            </svg></span>
        </div>


        <div class="report priority">
          <div class="field">
            <label for="" style="margin-top:1rem;font-weight:bold;font-size:16px;">Raw data for:</label>
            <select name="" id="" v-model="selectModel">
              <option value="" selected disabled>Select a Model</option>
              <option value="gestational_diabetes">Gestational Diabetes</option>
              <option value="planes">Planes</option>
              <option value="orientation">Orientation</option>
              <option value="fetal_planes">Fetal Planes</option>
              <option value="fetal_planes">MHR</option>
              <option value="fetal_planes">Anatomy</option>
              <option value="fetal_planes">Doppler</option>
            </select>
          </div>
        </div>

        

        <button :disabled="!selectPriority">Download Data <i class="fa fa-download"></i></button>
      </div>
    </div>





    <div class="notification-box" style="display:none">
      <div class="msg-sidebar notifications msg-noti">
        <div class="topnav-dropdown-header">
          <span>Messages</span>
        </div>
        <div class="drop-scroll msg-list-scroll" id="msg_list">
          <ul class="list-box">
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Richard Miles </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item new-message">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">John Doe</span>
                    <span class="message-time">1 Aug</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Tarah Shropshire </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Mike Litorus</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Catherine Manseau </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">D</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Domenic Houston </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">B</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Buster Wigton </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Rolland Webber </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Claire Mapes </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Melita Faucher</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Jeffery Lalor</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">L</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Loren Gatlin</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Tarah Shropshire</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <a href="chat.html">See all messages</a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

var moment = require('moment')

export default {
  name: 'mothers',
  components: {},
  data() {
    return {
      scans: [],
      moment: moment,
      selectedScanData: null,
      searchQuery:'',
      searchQuery2:'',
      selectedPatient: this.$store.state.selectedPatient || null,
      setPriorityModal:false,
      userData:null,
      selectPriority:'',
      selectPriorityDescription:'',
      selectModel:'',
      inputDataModal:false,
    }
  },
  mounted() {
    this.$store.commit('currentPage', 'mothers');
    if(!this.selectedPatient){
      this.$router.push('/mothers')
    }
    if (this.$store.state.doctor_username) {
      this.$router.push('/doctor')
    } else {
      document.title = "Tamela Dashboard | Scans"
      
      document.querySelector(".page-wrapper").style.paddingTop = '70px';
      this.$root.loadScripts();
      this.$root.showLoader = true;
      this.$root.resetSideBar();
      this.getScans();
      if(this.selectedPatient){
        this.searchQuery = this.selectedPatient.tamelaID
      }
    }
  },
  methods: {
     async refreshToken(func) {
            await axios
                .post('api/token/refresh', {
                    refresh:this.$store.state.refresh
                })
                .then(response => { 
                    //console.log(response.data)
                    if (response.data.access) {
                        this.$store.commit('setAccess',response.data.access)
                        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.access
                        func();
                    } else {
                        //this.router.push('/login')
                    }
                }).catch(error => {
                    //console.log(error)
                })
        },
    isMatch(scan) {
      const searchLowerCase = this.searchQuery.toLowerCase();
      return (
        scan.mother_data.tamelaID.includes(this.searchQuery.toUpperCase())
      );
    },
    async updatePriority(data){
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      const valuedata = {
        uuid:data.uuid,
        priority:this.selectPriority,
        priority_description:this.selectPriorityDescription,
      }

      await axios
      .put('api/v1/scan/priority/update',valuedata, {headers})
                .then(response => { 
                    //console.log(response.data)
                    if(response.status){
                      this.getScans();
                      this.setPriorityModal = false;
                      toast.success('Priority Updated', {
                        autoClose: 3000,
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }
                }).catch(error => {
                    //console.log(error)
                })
    },
    isMatch2(scan) {
      const searchLowerCase = this.searchQuery2.toLowerCase().trim() || null;
      if(!searchLowerCase){
        return true;
      } 
      return (
        scan.model_name.toLowerCase().includes(searchLowerCase) ||
        scan.model_result_confidence.toLowerCase().includes(searchLowerCase) ||
        scan.model_result_summary.toLowerCase().includes(searchLowerCase) 
      );
    },
    async getScans(pk = null) {
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      if (!pk) {
        await axios
          .get('api/v1/scan', { headers })
          .then(response => {

            this.scans = response.data.data;

            this.$root.showLoader = false;
          })
          .catch(error => {
             if (error.code != 'ERR_NETWORK') {
                  if (error.response.status === 401) {
                    this.refreshToken(this.getScans)
                  }
            } else {
                  console.error(error)
                  const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                    toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                }
            //this.$root.showLoader = false;
          })
          
      }
    },
    saveAsPDF(mother) {
      let HTML_Width = document.querySelector("#scanReport").offsetWidth;
      let HTML_Height = document.querySelector("#scanReport").offsetHeight;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;

      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas(document.querySelector("#scanReport")).then(function (canvas) {
          let imgData = canvas.toDataURL("image/jpeg", 1.0);
          let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
          pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
          for (let i = 1; i <= totalPDFPages; i++) { 
              pdf.addPage(PDF_Width, PDF_Height);
            pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
              let htmlText = document.querySelector("#scanReport").innerHTML
            pdf.text(20, HTML_Height + 20, htmlText); // Adjust the position as needed
            
        }
          const filename = `${mother.lastname}_${mother.firstname}_${mother.tamelaID}_SCAN`
          pdf.save(`${filename}.pdf`);
          //document.querySelector("#scanReport").hide();
      });
    }

  }
}
</script>

<style lang="scss" scoped>
.card-title{
    font-size:20px;
  }
.add-scan {
    margin:1rem 0;
    border-radius:var(--border-radius-1);
    border:2px dotted rgb(224, 104, 58);
    padding:.5rem 1rem;
    font-size:14px;
    color:var(--white);
    background: rgb(224, 104, 58);
    outline:none;
    cursor: pointer;

    &:hover{
      color:rgb(224, 104, 58);
      background:var(--background);
    }
  }

  .search-box{
    margin-bottom:1rem;
    input{
      background-color:var(--light);
      color:var(--dark);
      width:100%;
      max-width:40rem;
      border:1px solid transparent;
      border-radius:var(--border-radius-1);
      padding:.5rem
    }
  }

  .wideTable{
    width:100% !important;
    max-width: fit-content;

    table th{
      color:var(--dark);
    }

    .table-responsive tbody tr:hover{
      background: var(--light);
    }
    tr td{
      text-transform: capitalize;
      color:var(--black);
    }
    tr td:first-child{
      color:var(--primary);
      font-weight:600;
      cursor:pointer;
    }
  }

  .content-modal{
    display: flex;
    justify-content: center;
    padding:1rem;
    padding-top:5rem;
    width: 100vw;
    height: 100vh;
    background: var(--modal-background);
    position: fixed;
    top:0;


    .modal-content{
      width:100%;
      max-width:30rem;
      height:fit-content;
      background: var(--white);
      padding:1rem;
      border-radius: var(--card-border-radius);

      .report{

        &.priority{
          select{
            width:100%;
            padding:.5rem;
            border-radius:var(--border-radius-1);
            background: var(--light);
            
          }

          .field{
            width:100%;
            display:flex;
            flex-direction: column;
            position: relative;
          }

        }
        position: relative;
        .top-level{
          display:grid;
          grid-auto-flow: column;
          grid-template-columns: 40% 60%;

          .info{
            padding-left:2rem;
            margin-top:1rem;
            border-left:1px solid var(--info-dark);
          }
          .center-img {
            padding:1rem;
            align-items: flex-start;
            
            .imgbox{

              /*
              background-image: url('http://192.168.0.132:8000/media/mothers/avatar-95fb4ee46aae3dcff7a9f901dc0dd0f6.jpg');
              background-size:100%;
              background-repeat: no-repeat;
              */

              flex:1;
              width:100%;
              height:10rem;
              max-width: 10rem;
              border-radius: .5rem;
            }
          }
        }

        .report-info{
          padding-top:1rem;
          border-top:1px solid var(--info-dark);
        }
      }

      .close-button{
        

        .svg-icon{
          cursor: pointer;
          svg{
            fill:#e32;
            width:2rem;
            height:2rem;
          }
        }
      }

      .center-img{
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      button{
          border:1px solid transparent;
          padding:.5rem 1rem;
          font-size:16px;
          color:var(--white);
          background: var(--primary);
          border-radius:var(--border-radius-1);
          margin-top:2rem;

          &:hover{
            background:var(--white);
            color: var(--primary);
            border:1px solid var(--primary);
            
          }
        }

      .items, .info, .report-info{
        h3{
          color:var(--dark);
          text-transform: capitalize;
          font-size:14px;
          font-weight:400;

          &.title{
            font-size:20px;
            font-weight:600;
            text-align: center;
            text-transform: uppercase;
          }
          span{
            color:var(--info-dark);
            font-size:14px !important;
            font-weight:600 !important;
          }
        }

      }

      .imgbox{
          width:10rem;
          position: relative;
          overflow: hidden;
          border-radius: 50%;
          background-color:var(--info-light);
          background:var(--info-light);
          margin-bottom:1rem;

          img.profile{
            width:100%;
            background-color:var(--info-light);
            object-fit: cover;
          }
        }
    }
  }

  button{
    cursor: pointer;
  }


  @media screen and (max-width:768px) {
    .content{
      width:100%;
      padding:0;
    }
  }
   @media screen and (min-width:800px) {
    .card-box{
      width:max-content;
    }
    .content{
      padding:1rem !important;
    }
  }
  @media screen and (max-width:600px) {
    .modal-content{
      
      button{
        width:100%;
      }
    }
  }

  .primary, span.primary{
    color:var(--primary) !important;
  }
  .secondary, span.secondary{
    color:var(--secondary) !important;
    background:var(--white) !important;
  }

  .awaiting, span.awaiting{
        color:var(--warning) !important;
    }
    .success, span.success{
        color:var(--success) !important;
        
    }
    .failed, span.failed{
        color:var(--danger) !important;
    }
  
  
</style>