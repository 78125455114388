<template>
    <main>
      <div class="content">
  
        <div class="row" >
        
          <div class="col-lg-6 wideTable" >
            <div class="card-box" >
              <div class="card-block" >
               
               
                <h4 class="card-title">Uploaded Files</h4>
                <div style="display:flex;flex-direction: column;">
                    
                    <input type="file" @change="handleFileChange" />
                    <input type="text" v-model="fileDescription" style="background:var(--light);border:none;padding:.5rem 1rem;border-radius:.2rem;" placeholder="File tItle">
                   
                 
                  </div>
                <button class="add-scan" @click="uploadChunks" :disabled="uploading" >Upload File</button>
               
               <div class="progressbar" style="margin-top:1rem;display:flex;flex-direction: column;" v-if="uploading || paused">
                <label for="file">Upload progress: {{progress}}%</label>
                    <progress :value="progress" max="100" style="height:30px;width:100%; max-width:45rem;" :class="{'progress-complete':progress == 100}"></progress>
               </div>
               <div  class="pause_resume" style="margin-top:1rem;margin-bottom:2rem;" >
                <button @click="pauseUpload" v-if="uploading">Pause</button>
                <button @click="resumeUpload" v-if="paused">Resume</button>
               </div>

  
                <div class="table-responsive">
                  <h3 v-if="scans && scans.pagination">Page {{scans.pagination.current_page}} of {{  scans.pagination.total_pages }} </h3>
                  <table class="table mb-0">
                    <thead>
                      <tr>
                        <th>File Description</th>
                        <th>Filename/Title</th>
                        <th>Date Added</th>
                        <th></th>
                      </tr>
                    </thead>
  
                    <tbody v-if="scans && scans.data && scans.data.length >= 1">
                      <template v-for="(scan, index) in scans.data" :key="index">
                        <tr :id="`SCAN${scan.uuid}`">
                          <td @click="selectedScanData = scan">{{scan.description}}</td>
                          <td>{{scan.title}}</td>
                          <td>{{moment(scan.date_added).format("DD MMM YYYY h:m A")}}</td>
                          <td><a :href="scan.getFilePath" :download="scan.title">Download</a></td>
                        </tr>
  
                      </template>
  
                    
                    </tbody>
                  </table>
  
                  <div class="pagination" v-if="scans">
                    <ul v-if="scans.pagination">
                      <li v-if="scans.pagination.current_page>1"><i class="fas fa-chevron-left" @click="getScans(scans.pagination.current_page - 1)" :class="{'disabled':scans.pagination.current_page<=1}"></i> </li>
                      <li v-else  style="cursor:default" ><i class="fas fa-chevron-left":class="{'disabled':scans.pagination.current_page<=1}"></i> </li>
    
                      
                        <li v-for="num, index in scans.pagination.total_pages" :key="num" :class="{'active':scans.pagination.current_page==num}" @click="getScans(num)">
                          <span >{{ num }}</span>
                        </li>
                     
                      
                      
                      <li v-if="scans.pagination.current_page<scans.pagination.total_pages"><i class="fas fa-chevron-right" @click="getScans(scans.pagination.current_page + 1)" :class="{'disabled':scans.pagination.current_page>=scans.pagination.total_pages}" :disabled="scans.pagination.current_page>=scans.pagination.total_pages"></i> </li>
                      <li v-else style="cursor:default" ><i class="fas fa-chevron-right"  :class="{'disabled':scans.pagination.current_page>=scans.pagination.total_pages}" :disabled="scans.pagination.current_page>=scans.pagination.total_pages"></i> </li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      
  
  
  
    </main>
  </template>
  
  <script>
  import axios from 'axios'
  import jsPDF from 'jspdf';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  
  var moment = require('moment')
  
  export default {
    name: 'mothers',
    components: {},
    data() {
      return {
        scans: [],
        moment: moment,
        selectedScanData: null,
        searchQuery: '',
        orientation: '',
        anatomy: '',
        planes: '',
        placenta_location: '',
        liquor_volume: '',
        presentation: '',
        fetal_planes: '',
        selected_scan_id:null,
        isabnormalityFound:false,
        abnormality_name:'',
        abnormality_severity:'',
        prefetchAllImages: false,
        page_tick_count:0,
        file: null,
        chunkSize: 1 * 1024 * 1024, // 1 MB chunk size
        currentChunk: 0,
        totalChunks: 0,
        uploading: false,
        paused: false,
        progress: 0,
        fileDescription: '',
        uuid: null,
       
      }
    },
    mounted() {
  
    
        document.title = "Tamela Dashboard | Upload Files"
        this.$store.commit('currentPage', 'file-upload')
        document.querySelector(".page-wrapper").style.paddingTop = '70px';
        this.$root.loadScripts();
        this.$root.showLoader = true;
        this.$root.resetSideBar();
        this.getUploadedFiles();
        console.log('var')
      
    },
    methods: {
      
      docNotExist(arr) {
        console.log(arr)
        const doc_uid = this.$store.state.doctor_username;
        if (!arr) {
          return true
        }
        return arr.findIndex(item => item.doctor_username === doc_uid) === -1
      },
      async refreshToken(callback) {
                try {
                    const response = await axios.post('api/token/refresh', {
                        refresh: this.$store.state.refresh,
                    });
  
                    if (response.data.access) {
                        this.$store.commit('setAccess', response.data.access);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
                        await callback();  // Call the callback function after token refresh
                    } else {
                        this.$router.push('/login');  // Redirect to login if no access token is received
                    }
                } catch (error) {
                    console.error('Failed to refresh token', error);
                    this.$router.push('/login');  // Redirect to login on token refresh failure
                }
            },
      isMatch(scan) {
        const searchLowerCase = this.searchQuery.toLowerCase();
        return (
          scan.mother_data.tamelaID.includes(this.searchQuery.toUpperCase()) ||
          scan.scanID.includes(this.searchQuery.toUpperCase()) ||
          scan.mother_data.lastname.toLowerCase().includes(searchLowerCase) ||
          scan.mother_data.firstname.toLowerCase().includes(searchLowerCase) ||
          scan.mother_data.othername.toLowerCase().includes(searchLowerCase) ||
          scan.foetus_label.toLowerCase().includes(searchLowerCase) 
          
        );
      },
      async prefetchImages(){
        if(!this.areImagesPrefetched){
          this.areImagesPrefetched = true;
          for(let x=0; x < this.scans.data.length; x++){
            //console.log(this.scans[x])
            let data = this.scans.data[x]
            this.$root.preloadImage(data.image)
          }
        }
      },
      async getUploadedFiles(page = null) {
        const accessToken = this.$store.state.access;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        try {
          let params = {
            role:this.$store.state.userdata.role
          };
          const total_pages = this.scans ? this.scans.pagination ? this.scans.pagination.total_pages : 1 : 1  
          if(page && page <= total_pages){
            params.page = page
          }
                    this.$root.showLoader = true;
                    const response = await axios.get("api/v1/uploaded-files",{params}, {headers}, );
                    this.scans = response.data;
                    
                    //this.prefetchImages();
                    this.$root.showLoader = false;
                } catch (error) {
                  this.$root.showLoader = false;
                    if (error.response) {
                        const status = error.response.status;
                        if (status === 401) {
                            // Unauthorized access, attempt to refresh token
                            await this.refreshToken(this.getUploadedFiles);
                        } else {
                            // Handle other status codes
                            console.error(`Error ${status}: ${error.response.data.message}`);
                            toast.error(`Error ${status}: ${error.response.data.message}`, {
                                autoClose: 3000,
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    } else if (error.request) {
                        // Request made but no response received
                        console.error('No response received:', error.request);
                        toast.error('No response received. Please try again later.', {
                            autoClose: 3000,
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        // Other errors
                        console.error('Error', error);
                        toast.error(`Error: ${error.message}`, {
                            autoClose: 3000,
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                }
        
      },
      async submitReport() {
        
        const accessToken = this.$store.state.access;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const uuid = this.selectedScanData.uuid
  
        const data = {
          image_name: this.selectedScanData.image_name,
          results: {
            orientation: this.orientation || null,
            //anatomy: this.anatomy  || null,
            //planes: this.planes || null,
            fetal_planes: this.fetal_planes || null,
            presentation: this.presentation || null,
            liquor_volume: this.liquor_volume || null,
            placenta_location: this.placenta_location || null,
            isabnormalityFound: this.isabnormalityFound || null,
            abnormality_name:this.abnormality_name || null,
            abnormality_severity: this.abnormality_severity || null,
          }
        }
       
          try{
            await axios
            .post('api/v1/double-blinded-study', data, { headers })
            .then(response => {
              if (response.data.status) {
                document.getElementById(`SCAN${uuid}`).remove();
                
  
                try{
                  this.page_tick_count += 1;
                const scanCount = this.scans ? this.scans.data.length: null
                if(pageCount){
                  if((this.page_tick_count/scanCount) * 100 >= 50){
                    this.getScans(this.scans.pagination.current_page || null)
                  }
                }
                } catch (e){
                  console.error(e)
                }
  
  
                console.log(response.data)
                toast.info(response.data.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                this.selectedScanData = null;
                this.abnormality_name = '';
                this.abnormality_severity='';
                this.isabnormalityFound=false;
                this.$root.showLoader = false;
              } else {
                toast.error(response.data.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                this.$root.showLoader = false;
              }
            })
          } catch (error){
            if (error.response) {
                        const status = error.response.status;
                        if (status === 401) {
                            // Unauthorized access, attempt to refresh token
                            await this.refreshToken(this.submitReport);
                        } else {
                            // Handle other status codes
                            console.error(`Error ${status}: ${error.response.data.message}`);
                            toast.error(`Error ${status}: ${error.response.data.message}`, {
                                autoClose: 3000,
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    } else if (error.request) {
                        // Request made but no response received
                        console.error('No response received:', error.request);
                        toast.error('No response received. Please try again later.', {
                            autoClose: 3000,
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        // Other errors
                        console.error('Error', error);
                        toast.error(`Error: ${error.message}`, {
                            autoClose: 3000,
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
          }
          
            
            
        
      },
      saveAsPDF(mother) {
        let HTML_Width = document.querySelector("#scanReport").offsetWidth;
        let HTML_Height = document.querySelector("#scanReport").offsetHeight;
        let top_left_margin = 15;
        let PDF_Width = HTML_Width + (top_left_margin * 2);
        let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
        let canvas_image_width = HTML_Width;
        let canvas_image_height = HTML_Height;
  
        let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
  
        html2canvas(document.querySelector("#scanReport")).then(function (canvas) {
            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
            for (let i = 1; i <= totalPDFPages; i++) { 
                pdf.addPage(PDF_Width, PDF_Height);
              pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
                let htmlText = document.querySelector("#scanReport").innerHTML
              pdf.text(20, HTML_Height + 20, htmlText); // Adjust the position as needed
              
          }
            const filename = `${mother.lastname}_${mother.firstname}_${mother.tamelaID}_SCAN`
            pdf.save(`${filename}.pdf`);
            //document.querySelector("#scanReport").hide();
        });
      },
      handleFileChange(event) {
        this.file = event.target.files[0];
    this.totalChunks = Math.ceil(this.file.size / this.chunkSize);
    this.currentChunk = 0;
    this.uuid = this.generateUUID(); 
        
    },
    async uploadChunks() {
        if (!this.file) return;
        if (!this.fileDescription){
            toast.error(`Please Enter a Title`, {
                            autoClose: 3000,
                            position: toast.POSITION.TOP_RIGHT,
                        });
            return;
        }

        this.uploading = true;
        this.paused = false;

        while (this.currentChunk < this.totalChunks && !this.paused) {
            const chunk = this.file.slice(
                this.currentChunk * this.chunkSize,
                (this.currentChunk + 1) * this.chunkSize
            );

            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('filename', this.file.name);
            console.log("name", this.file.name)
            formData.append('chunk_number', this.currentChunk + 1);
            formData.append('total_chunks', this.totalChunks);
            formData.append('uuid', this.uuid);
            formData.append('file_description', this.fileDescription);
            if (this.uuid) {
                formData.append('uuid', this.uuid); // reuse file_id for subsequent chunks
            }

            try {
                const response = await axios.post('/api/v1/upload/', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                
                if (!this.uuid) {
                    // Assign file_id on the first response
                    this.uuid = response.data.file_id;
                }
                
                this.currentChunk++;
                this.progress = Math.floor((this.currentChunk / this.totalChunks) * 100);

            } catch (error) {
                this.$root.showLoader = false;
                this.uploading = false;
                if (error.response) {
                    const status = error.response.status;
                    if (status === 401) {
                        // Unauthorized access, attempt to refresh token
                        await this.refreshToken(this.uploadChunks);
                    } else {
                        // Handle other status codes
                        this.pauseUpload();
                        console.error(`Error ${status}: ${error.response.data.message}`);
                        toast.error(`Error ${status}: ${error.response.data.message}`, {
                            autoClose: 3000,
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } else if (error.request) {
                    // Request made but no response received
                    console.error('No response received:', error.request);
                    toast.error('No response received. Please try again later.', {
                        autoClose: 3000,
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    // Other errors
                    console.error('Error', error);
                    toast.error(`Error: ${error.message}`, {
                        autoClose: 3000,
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
      }

      if (this.currentChunk >= this.totalChunks) {
        this.progress = 100;
        this.uploading = false;
        this.file=null;
        this.fileDescription = '';
        this.progress = 0;
        toast.success(`File Uploaded Successfully`, {
                            autoClose: 3000,
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        this.getUploadedFiles();

      }
    },
    pauseUpload() {
      this.paused = true;
      this.uploading = false;
    },
    resumeUpload() {
      this.paused = false;
      this.uploadChunks();
    },
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    }
  }
  </script>
  
  <style lang="scss" scoped>

  .progress-complete{
    background-color:var(--success);
  }
  .pause_resume{
    display:flex;
    justify-content: space-between;
    button{
        border:1px solid transparent;
        padding:.5rem 1rem;
        border-radius:.5rem;
        background-color:var(--success);
        color:#fff;
        &:first-child{
            background-color:var(--danger);
        }
    }
  }
  .loading-table{
    width:100%;
    height:100%;
    display: grid;
    place-items: center;
    background:rgba(0,0,0,.05);
    position:absolute;
    top:0;
    left:0;
  
    .spinner{
      width:45px;    height:45px;
      border-radius:50%;
      position: relative;
      border:5px solid var(--primary);
      border-color:var(--primary) transparent var(--primary) transparent;
      animation: loading 1s infinite;
    }
  }
  
  .pagination {
    margin-top: 30px;
    text-align: center;
  }
  
  .pagination ul {
    display: inline-block;
    display: flex;
  }
  
  .pagination ul li {
    display: inline-block;
    margin-right: 30px;
    width: 24px;
    height: 24px;
  }
  
  .pagination .active {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    color: white;
  }
  .pagination li{
    cursor:pointer;
  }
  
  .abnormality-bar{
    input, select{
      width:100%;
      padding:.5rem;
      font-size:16px;
      border:1px solid var(--dark);
      border-radius:var(--border-radius-1);
      margin-bottom:.5rem;
      &:focus{
        border:1px solid var(--primary);
      }
  
      &::placeholder{
        font-size:16px;
      }
    }
  }
  
  .card-title{
      font-size:20px;
    }
  .add-scan {
      margin:1rem 0;
      border-radius:var(--border-radius-1);
      border:2px dotted rgb(224, 104, 58);
      padding:.5rem 1rem;
      font-size:14px;
      color:var(--white);
      background: rgb(224, 104, 58);
      outline:none;
      cursor: pointer;
  
      &:hover{
        color:rgb(224, 104, 58);
        background:var(--background);
      }
    }
  
    .search-box{
      margin-bottom:1rem;
      input{
        background-color:var(--light);
        color:var(--dark);
        width:100%;
        max-width:40rem;
        border:1px solid transparent;
        border-radius:var(--border-radius-1);
        padding:.5rem
      }
    }
  
    .wideTable{
      width:100% !important;
      max-width: fit-content;
  
      table th{
        color:var(--dark);
      }
  
      .table-responsive tbody tr:hover{
        background: var(--light);
      }
      tr td{
        text-transform: capitalize;
        color:var(--black);
      }
      tr td:first-child{
        color:var(--primary);
        font-weight:600;
        cursor:pointer;
      }
    }
  
    .content-modal{
      display: flex;
      justify-content: center;
      padding:1rem;
      padding-top:5rem;
      width: 100vw;
      height: 100vh;
      background: var(--modal-background);
      position: fixed;
      top:0;
  
  
      .modal-content{
        width:100%;
        max-width:60rem !important;
        height:fit-content;
        max-height: 90% !important;
        background: var(--white);
        padding:1rem;
        border-radius: var(--card-border-radius);
        overflow-y: auto !important;
  
        .report{
          position: relative;
          display: flex;
          overflow-y: auto !important;
          .left{
            display:flex;
            justify-content: center;
            align-items: center;
            width:70%;
            
            background: var(--light);
            margin:.5rem;
           
            border-radius: .5rem;
            position: relative;
  
            img.profile {
                width: 100%;
                background-color: var(--info-light);
                object-fit: cover;
              }
          }
          .right{
            width:30%;
            overflow-y: scroll !important;
            .item{
              
            }
          }
          .top-level{
            display:grid;
            grid-auto-flow: column;
            grid-template-columns: 40% 60%;
  
            .info{
              padding-left:2rem;
              margin-top:1rem;
              border-left:1px solid var(--info-dark);
            }
            .center-img {
              padding:1rem;
              align-items: flex-start;
              
              .imgbox{
  
                /*
                background-image: url('http://192.168.0.132:8000/media/mothers/avatar-95fb4ee46aae3dcff7a9f901dc0dd0f6.jpg');
                background-size:100%;
                background-repeat: no-repeat;
                */
  
                flex:1;
                width:100%;
                height:100%;
                position: relative;
                border-radius: 0%;
              }
            }
          }
  
          .report-info{
            padding-top:1rem;
            border-top:1px solid var(--info-dark);
          }
        }
  
        .close-button{
          
  
          .svg-icon{
            cursor: pointer;
            svg{
              fill:#e32;
              width:2rem;
              height:2rem;
            }
          }
        }
  
        .center-img{
          display: flex;
          width: 100%;
          justify-content: center;
          text-align: center;
          align-items: center;
        }
  
        button{
            border:1px solid transparent;
            padding:.5rem 1rem;
            font-size:16px;
            color:var(--white);
            background: var(--primary);
            border-radius:var(--border-radius-1);
            margin-top:2rem;
  
            &:hover{
              background:var(--white);
              color: var(--primary);
              border:1px solid var(--primary);
              
            }
          }
  
        .items, .info, .report-info{
          h3{
            color:var(--dark);
            text-transform: capitalize;
            font-size:14px;
            font-weight:400;
  
            &.title{
              font-size:20px;
              font-weight:600;
              text-align: center;
              text-transform: uppercase;
            }
            span{
              color:var(--info-dark);
              font-size:14px !important;
              font-weight:600 !important;
            }
          }
  
        }
  
        .imgbox{
            width:100%;
            position: relative;
            overflow: hidden;
            border-radius: 0%;
            background-color:var(--info-light);
            background:var(--info-light);
            margin-bottom:1rem;
  
            img.profile{
              width:100%;
              background-color:var(--info-light);
              object-fit: contain;
            }
          }
      }
    }
  
    button{
      cursor: pointer;
    }
  
  
    
  
    @media screen and (max-width:768px) {
      .content{
        width:100%;
        padding:0;
      }
    }
     @media screen and (min-width:800px) {
      .card-box{
        width:max-content;
      }
      .content{
        padding:1rem !important;
      }
    }
    @media screen and (max-width:1024px) {
      .modal-content{
        overflow-y: auto !important;
        max-height:80dvh;
        button{
          width:100%;
        }
        .report{
          flex-direction: column;
          
          .left{
            width:fit-content;
            display:flex;
            justify-content: center;
            align-items: center;
            background: transparent !important;
            text-align: center;
            //margin-left:-2rem;
            width:100% !important;
            padding:0;
            margin:0;
            
            
            .center-img{
              width: 15rem !important;
            }
            
          }
          .right{
            width:100% !important;
          }
          img.profile{
            margin-left:-1rem;
          }
        }
      }
    }
    
    @media screen and (min-width:768px) {
      *{
        font-size:1rem;
      }
    }
  
    
    
    .primary, span.primary{
      color:var(--primary) !important;
    }
    .secondary, span.secondary{
      color:var(--secondary) !important;
      background:var(--white) !important;
    }
  
    .awaiting, span.awaiting{
          color:var(--warning) !important;
      }
      .success, span.success{
          color:var(--success) !important;
          
      }
      .failed, span.failed{
          color:var(--danger) !important;
      }
  
  
    .item select{
      width:100%;
      padding:.5rem;
      font-size:1rem;
      border-radius: var(--border-radius-1);
    }
    .item label{
      text-transform: capitalize;
      font-size: 1rem;
      margin-top: 1rem;
    }
    
    
  </style>