<template>
  <main>
    <div class="content">

      <div class="row" >
      
        <div class="col-lg-6 wideTable" >
          <div class="card-box" >
            <div class="card-block" >
              <h4 class="card-title" v-if="$store.state.userdata && $store.state.userdata.role === 'radiologist'">Pending Ultrasound Results</h4>
              <h4 class="card-title" v-else-if="$store.state.userdata && $store.state.userdata.role === 'obstetrician'">Pending CTG Results</h4>
              <h4 class="card-title" v-else>Pending Results</h4>
              <button class="add-scan" style="display:none">Upload Scan </button>

              <div class="table-responsive">
                <h3 v-if="scans && scans.pagination">Page {{scans.pagination.current_page}} of {{  scans.pagination.total_pages }} </h3>
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Scan ID</th>
                    
                      <th>Date Added</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody v-if="scans && scans.data && scans.data.length >= 1">
                    <template v-for="(scan, index) in scans.data" :key="index">
                      <tr :id="`SCAN${scan.uuid}`">
                        <td @click="selectedScanData = scan">Image_{{scan.image_name.split('_')[0]}}</td>
                        
                        <td><button @click="selectedScanData = scan"
                            style="background-color:var(--primary);color:var(--white);padding:.5rem 1rem;border:none;border-radius:var(--border-radius-1)">Set
                            Result</button></td>
                      </tr>

                    </template>

                  
                  </tbody>
                </table>

                <div class="pagination" v-if="scans">
                  <ul v-if="scans.pagination">
                    <li v-if="scans.pagination.current_page>1"><i class="fas fa-chevron-left" @click="getScans(scans.pagination.current_page - 1)" :class="{'disabled':scans.pagination.current_page<=1}"></i> </li>
                    <li v-else  style="cursor:default" ><i class="fas fa-chevron-left":class="{'disabled':scans.pagination.current_page<=1}"></i> </li>
  
                    
                      <li v-for="num, index in scans.pagination.total_pages" :key="num" :class="{'active':scans.pagination.current_page==num}" @click="getScans(num)">
                        <span >{{ num }}</span>
                      </li>
                   
                    
                    
                    <li v-if="scans.pagination.current_page<scans.pagination.total_pages"><i class="fas fa-chevron-right" @click="getScans(scans.pagination.current_page + 1)" :class="{'disabled':scans.pagination.current_page>=scans.pagination.total_pages}" :disabled="scans.pagination.current_page>=scans.pagination.total_pages"></i> </li>
                    <li v-else style="cursor:default" ><i class="fas fa-chevron-right"  :class="{'disabled':scans.pagination.current_page>=scans.pagination.total_pages}" :disabled="scans.pagination.current_page>=scans.pagination.total_pages"></i> </li>
                  </ul>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    



    <div class="content-modal" v-if="selectedScanData" style="left:0;z-index:99999">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"><svg @click="selectedScanData = null" xmlns="http://www.w3.org/2000/svg" height="48"
              viewBox="0 -960 960 960" width="48">
              <path
                d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
            </svg></span>
        </div>


        <div class="report">
          <div class="left">
                <img class="profile" :src="selectedScanData.get_image_path" alt="">

           
          </div>
         
          <div class="right" style="overflow:auto !important;" v-if="selectedScanData.study_category=='ultrasound'">

            <div class="item">
              <label>Orientation</label>
              <select name="" id="" v-model="orientation">
                <option value="" selected disabled>select Orientation</option>
                <option value="other">other/Image has nothing to do with Orientation</option>
                <option value="hdvb">hdvb</option>
                <option value="hdvf">hdvf</option>
                <option value="huvb">huvb</option>
                <option value="huvf">huvf</option>
              </select>
            </div>
            <div class="item">
              <label>Presentation</label>
              <select name="" id="" v-model="presentation">
                <option value="" selected disabled>select Presentation</option>
                <option value="other">none/Image has nothing to do with Presentation</option>
                <option value="cephalic">cephalic</option>
                <option value="breech">breech</option>
                <option value="transverse">Transverse</option>
              </select>
            </div>
            <!--
            <div class="item">
              <label>Select Anatomy Class</label>
              <select name="" id="" v-model="anatomy">
                <option value="" selected disabled>select Anatomy Class</option>
                <option value="other">other/none</option>
                <option value="abdomen_h">abdomen_h</option>
                <option value="abdomen_v">abdomen_v</option>
                <option value="arms_h">arms_h</option>
                <option value="head_h">head_h</option>
                <option value="head_v">head_v</option>
                <option value="leg_h">leg_h</option>
                <option value="leg_v">leg_v</option>
              </select>
            </div>-->
           <!-- <div class="item">
              <label>Select Planes Class</label>
              <select name="" id="" v-model="planes">
                <option value="" selected disabled>select Planes Class</option>
                <option value="other">other/none</option>
                <option value="AC_PLANE">AC_PLANE</option>
                <option value="BPD_PLANE">BPD_PLANE</option>
                <option value="FL_PLANE">FL_PLANE</option>
                <option value="NO_PLANE">NO_PLANE</option>
              </select>
            </div>-->
            <div class="item">
              <label>Fetal Planes</label>
              <select name="" id="" v-model="fetal_planes">
                <option value="" selected disabled>select Fetal Planes</option>
                <option value="other">other/none</option>
                <option value="fetal abdomen">fetal abdomen</option>
                <option value="fetal brain">fetal brain</option>
                <option value="fetal femur">fetal femur</option>
                <option value="fetal thorax">fetal thorax</option>
                <option value="maternal cervix">maternal cervix</option>
                
              </select>
            </div>

            <div class="item">
              <label>Liquor Volume</label>
              <select name="" id="" v-model="liquor_volume">
                <option value="" selected disabled>select Liquor Volume</option>
                <option value="other">other/none</option>
                <option value="adequate">Adequate</option>
                <option value="excess">excess</option>
                <option value="reduced">reduced</option>
           
              </select>
            </div>

          

            <div class="item">
              <label>Placenta Location</label>
              <select name="placenta_location" id="placenta_location" v-model="placenta_location">
                <option value="" selected disabled>Select Placenta Location</option>
                <option value="other">other/none</option>
                <option value="Anterior">Anterior</option>
                <option value="Anterior Low lying">Anterior Low lying</option>
                <option value="Anterofundal">Anterofundal</option>
                <option value="Fundal">Fundal</option>
                <option value="Left Lateral">Left Lateral</option>
                <option value="Low lying">Low lying</option>
                <option value="Posterior">Posterior</option>
                <option value="Posterofundal">Posterofundal</option>
                <option value="Posterolateral">Posterolateral</option>
                <option value="Previa Type III">Previa Type III</option>
                <option value="Right lateral">Right lateral</option>
              </select>
            </div>
            <div style="margin-top:1rem;">
              <label for="" style="font-size:16px;">Was an abnormality found?</label>
              <input type="checkbox" name="" id="" style="width:1.5rem;height:1.5rem;margin-left:1rem;" v-model="isabnormalityFound">
            </div>
            <div style="margin-top: .5rem;" class="abnormality-bar" v-if="isabnormalityFound">
              
              <input type="text" name="" id="" placeholder="Abnormality Name" v-model="abnormality_name">
              <select name="" id="" v-model="abnormality_severity">
                <option value="">Select Abnormality Severity</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
            </div>
            
            <button @click="submitReport()">Submit Report <i
                class="fa fa-send-o"></i></button>


          </div>


          <div class="right" style="overflow:auto !important;" v-if="selectedScanData.study_category=='ctg'">
            <div class="item">
              <label>Baseline FHR</label>
              <select name="" id="" v-model="baseline">
                <option value="" selected disabled>select Baseline FHR</option>
                <option value="rn_or_a">RN or A</option>
                <option value="reassuring">Reassuring</option>
                <option value="non-reassuring">Non-Reassuring</option>
                <option value="abnormal">Abnormal</option>
               
              </select>
            </div>
            <div class="item">
              <label>Baseline Variability</label>
              <select name="" id="" v-model="variablility">
                <option value="" selected disabled>select Baseline Variability</option>
                <option value="rn_or_a">RN or A</option>
                <option value="reassuring">Reassuring</option>
                <option value="non-reassuring">Non-Reassuring</option>
                <option value="abnormal">Abnormal</option>
               
              </select>
            </div>
            <div class="item">
              <label>Acceleration</label>
              <select name="" id="" v-model="acceleration">
                <option value="" selected disabled>select Acceleration</option>
                <option value="rn_or_a">RN or A</option>
                <option value="reassuring">Reassuring</option>
                <option value="non-reassuring">Non-Reassuring</option>
                <option value="abnormal">Abnormal</option>
               
              </select>
            </div>
            <div class="item">
              <label>Deceleration Early Late Variable</label>
              <select name="" id="" v-model="deceleration">
                <option value="" selected disabled>select Deceleration</option>
                <option value="rn_or_a">RN or A</option>
                <option value="reassuring">Reassuring</option>
                <option value="non-reassuring">Non-Reassuring</option>
                <option value="abnormal">Abnormal</option>
               
              </select>
            </div>
            <div class="item">
              <label>Contraction</label>
              <select name="" id="" v-model="contraction">
                <option value="" selected disabled>select Contraction</option>
                <option value="present">present</option>
                <option value="absent">absent</option>
              
              </select>
            </div>

            <div style="margin-top:1rem;">
              <label for="" style="font-size:16px;">Was another abnormality found?</label>
              <input type="checkbox" name="" id="" style="width:1.5rem;height:1.5rem;margin-left:1rem;" v-model="isabnormalityFound">
            </div>
            <div style="margin-top: .5rem;" class="abnormality-bar" v-if="isabnormalityFound">
              
              <input type="text" name="" id="" placeholder="Abnormality Name" v-model="abnormality_name">
              <select name="" id="" v-model="abnormality_severity">
                <option value="">Select Abnormality Severity</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
            </div>
            
            <button @click="submitReport()">Submit Report <i
                class="fa fa-send-o"></i></button>
          </div>



        </div>

        

      </div>
    </div>







    <div class="notification-box" style="display:none">
      <div class="msg-sidebar notifications msg-noti">
        <div class="topnav-dropdown-header">
          <span>Messages</span>
        </div>
        <div class="drop-scroll msg-list-scroll" id="msg_list">
          <ul class="list-box">
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Richard Miles </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item new-message">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">John Doe</span>
                    <span class="message-time">1 Aug</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Tarah Shropshire </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Mike Litorus</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Catherine Manseau </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">D</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Domenic Houston </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">B</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Buster Wigton </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Rolland Webber </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Claire Mapes </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Melita Faucher</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Jeffery Lalor</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">L</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Loren Gatlin</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Tarah Shropshire</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <a href="chat.html">See all messages</a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

var moment = require('moment')

export default {
  name: 'mothers',
  components: {},
  data() {
    return {
      scans: [],
      moment: moment,
      selectedScanData: null,
      searchQuery: '',
      orientation: '',
      anatomy: '',
      planes: '',
      placenta_location: '',
      liquor_volume: '',
      presentation: '',
      fetal_planes: '',
      selected_scan_id:null,
      isabnormalityFound:false,
      abnormality_name:'',
      abnormality_severity:'',
      prefetchAllImages: false,
      page_tick_count:0,
      baseline:'',
      variablility:'',
      contraction:'',
      acceleration:'',
      deceleration:'',
     
    }
  },
  mounted() {

    if (!this.$store.state.doctor_username) {
      this.$router.push('/')
    } else {
      document.title = "Tamela Dashboard | Scans"
      this.$store.commit('currentPage', 'doctor')
      document.querySelector(".page-wrapper").style.paddingTop = '70px';
      this.$root.loadScripts();
      this.$root.showLoader = true;
      this.$root.resetSideBar();
      this.getScans();
    }
  },
  methods: {
    
    docNotExist(arr) {
      console.log(arr)
      const doc_uid = this.$store.state.doctor_username;
      if (!arr) {
        return true
      }
      return arr.findIndex(item => item.doctor_username === doc_uid) === -1
    },
    async refreshToken(callback) {
              try {
                  const response = await axios.post('api/token/refresh', {
                      refresh: this.$store.state.refresh,
                  });

                  if (response.data.access) {
                      this.$store.commit('setAccess', response.data.access);
                      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
                      await callback();  // Call the callback function after token refresh
                  } else {
                      this.$router.push('/login');  // Redirect to login if no access token is received
                  }
              } catch (error) {
                  console.error('Failed to refresh token', error);
                  this.$router.push('/login');  // Redirect to login on token refresh failure
              }
          },

    isMatch(scan) {
      const searchLowerCase = this.searchQuery.toLowerCase();
      return (
        scan.mother_data.tamelaID.includes(this.searchQuery.toUpperCase()) ||
        scan.scanID.includes(this.searchQuery.toUpperCase()) ||
        scan.mother_data.lastname.toLowerCase().includes(searchLowerCase) ||
        scan.mother_data.firstname.toLowerCase().includes(searchLowerCase) ||
        scan.mother_data.othername.toLowerCase().includes(searchLowerCase) ||
        scan.foetus_label.toLowerCase().includes(searchLowerCase) 
        
      );
    },
    async prefetchImages(){
      if(!this.areImagesPrefetched){
        this.areImagesPrefetched = true;
        for(let x=0; x < this.scans.data.length; x++){
          //console.log(this.scans[x])
          let data = this.scans.data[x]
          this.$root.preloadImage(data.image)
        }
      }
    },


    async getUserData(){
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      await axios
            .get("accounts/userdata", {headers})
            .then(response => {
              const data = response.data 
              if (data.status) {
                if (data.data) {
                  this.$store.commit('setUserdata', data.data)
                  const usercat = data.data.usercat
                  console.log(usercat)
                  this.$store.commit('setUsercat', usercat)
                  if (usercat === 'tamelaDoctor') {
                    this.$store.commit('setDoctorUid', this.username)
                    this.$router.push(this.$route.query.to || '/doctor')
                  } else if (usercat === 'admin' || usercat === 'hospitaladmin') {
                    this.$router.push(this.$route.query.to || '/')
                  } else if (usercat === 'doctor') {
                    this.$router.push(this.$route.query.to || '/models')
                  } else if (usercat === 'data-uploader') {
                    this.$router.push('/file-upload')
                  } else if (usercat === 'ai-developer') {
                    this.$router.push('/file-download')
                  } 
                  else {
                    toast.error("Unauthorized user", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                    axios.defaults.headers.common["Authorization"] = "";
                    this.$store.commit('resetStore');
                  }
                }
                
              }
            })
            .catch(error => {
              console.error(error)
            })
    },
    async getScans(page = null) {
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      try {
        let params = {
          role:this.$store.state.userdata.role
        };
        const total_pages = this.scans ? this.scans.pagination ? this.scans.pagination.total_pages : 1 : 1  
        if(page && page <= total_pages){
          params.page = page
        }
                  this.$root.showLoader = true;
                  const response = await axios.get('api/v1/double-blinded-study',{params}, {headers}, );
                  this.scans = response.data;
                  
                  this.prefetchImages();
                  this.$root.showLoader = false;
              } catch (error) {
                this.$root.showLoader = false;
                  if (error.response) {
                      const status = error.response.status;
                      if (status === 401) {
                          // Unauthorized access, attempt to refresh token
                          await this.refreshToken(this.getScans);
                      } else {
                          // Handle other status codes
                          console.error(`Error ${status}: ${error.response.data.message}`);
                          toast.error(`Error ${status}: ${error.response.data.message}`, {
                              autoClose: 3000,
                              position: toast.POSITION.TOP_RIGHT,
                          });
                      }
                  } else if (error.request) {
                      // Request made but no response received
                      console.error('No response received:', error.request);
                      toast.error('No response received. Please try again later.', {
                          autoClose: 3000,
                          position: toast.POSITION.TOP_RIGHT,
                      });
                  } else {
                      // Other errors
                      console.error('Error', error);
                      toast.error(`Error: ${error.message}`, {
                          autoClose: 3000,
                          position: toast.POSITION.TOP_RIGHT,
                      });
                  }
              }
      
    },
    async submitReport() {

      if(this.selectedScanData.study_category == 'ultrasound'){
        if(!this.orientation){
          toast.error('Please Provide a response for the Orientation', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        } else if (!this.placenta_location){
          toast.error('Please Provide a response for the Placenta Location', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        } else if (!this.presentation){
          toast.error('Please Provide a response for the Presentation', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        } else if(!this.fetal_planes){
          toast.error('Please Provide a response for the Fetal Planes', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        } else if (!this.liquor_volume){
          toast.error('Please Provide a response for the Liquor Volume', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        }
      }
      if(this.selectedScanData.study_category == 'ctg'){
        if(!this.baseline){
          toast.error('Please Provide a response for the Baseline', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        } else if (!this.variablility){
          toast.error('Please Provide a response for the Variability', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        } else if(!this.acceleration){
          toast.error('Please Provide a response for the Acceleration', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        } else if (!this.deceleration){
          toast.error('Please Provide a response for the Deceleration', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        } else if (!this.contraction){
          toast.error('Please Provide a response for the Contraction', {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          return
        }
      }
      
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const uuid = this.selectedScanData.uuid
      let data = null;

      if(this.selectedScanData.study_category == 'ultrasound'){
          data = {
          image_name: this.selectedScanData.image_name,
          results: {
            orientation: this.orientation || null,
            //anatomy: this.anatomy  || null,
            //planes: this.planes || null,
            fetal_planes: this.fetal_planes || null,
            presentation: this.presentation || null,
            liquor_volume: this.liquor_volume || null,
            placenta_location: this.placenta_location || null,
            isabnormalityFound: this.isabnormalityFound || null,
            abnormality_name:this.abnormality_name || null,
            abnormality_severity: this.abnormality_severity || null,
          }
        }
      } else if (this.selectedScanData.study_category == 'ctg'){
        data = {
          image_name: this.selectedScanData.image_name,
          results: {
            baseline: this.baseline || null,
            variability: this.variablility || null,
            acceleration: this.acceleration || null,
            deceleration: this.deceleration || null,
            contraction: this.contraction || null,
            isabnormalityFound: this.isabnormalityFound || null,
            abnormality_name:this.abnormality_name || null,
            abnormality_severity: this.abnormality_severity || null,
          }
        }
      }
     
        try{
          await axios
          .post('api/v1/double-blinded-study', data, { headers })
          .then(response => {
            if (response.data.status) {
              document.getElementById(`SCAN${uuid}`).remove();
              

              try{
                this.page_tick_count += 1;
              const scanCount = this.scans ? this.scans.data.length: null
              if(pageCount){
                if((this.page_tick_count/scanCount) * 100 >= 50){
                  this.getScans(this.scans.pagination.current_page || null)
                }
              }
              } catch (e){
                console.error(e)
              }


              console.log(response.data)
              toast.info(response.data.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
              this.selectedScanData = null;
              this.abnormality_name = '';
              this.abnormality_severity='';
              this.isabnormalityFound=false;
              this.$root.showLoader = false;
            } else {
              toast.error(response.data.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
              this.$root.showLoader = false;
            }
          })
        } catch (error){
          if (error.response) {
                      const status = error.response.status;
                      if (status === 401) {
                          // Unauthorized access, attempt to refresh token
                          await this.refreshToken(this.submitReport);
                      } else {
                          // Handle other status codes
                          console.error(`Error ${status}: ${error.response.data.message}`);
                          toast.error(`Error ${status}: ${error.response.data.message}`, {
                              autoClose: 3000,
                              position: toast.POSITION.TOP_RIGHT,
                          });
                      }
                  } else if (error.request) {
                      // Request made but no response received
                      console.error('No response received:', error.request);
                      toast.error('No response received. Please try again later.', {
                          autoClose: 3000,
                          position: toast.POSITION.TOP_RIGHT,
                      });
                  } else {
                      // Other errors
                      console.error('Error', error);
                      toast.error(`Error: ${error.message}`, {
                          autoClose: 3000,
                          position: toast.POSITION.TOP_RIGHT,
                      });
                  }
        }
        
          
          
      
    },
    saveAsPDF(mother) {
      let HTML_Width = document.querySelector("#scanReport").offsetWidth;
      let HTML_Height = document.querySelector("#scanReport").offsetHeight;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;

      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas(document.querySelector("#scanReport")).then(function (canvas) {
          let imgData = canvas.toDataURL("image/jpeg", 1.0);
          let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
          pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
          for (let i = 1; i <= totalPDFPages; i++) { 
              pdf.addPage(PDF_Width, PDF_Height);
            pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
              let htmlText = document.querySelector("#scanReport").innerHTML
            pdf.text(20, HTML_Height + 20, htmlText); // Adjust the position as needed
            
        }
          const filename = `${mother.lastname}_${mother.firstname}_${mother.tamelaID}_SCAN`
          pdf.save(`${filename}.pdf`);
          //document.querySelector("#scanReport").hide();
      });
    }

  }
}
</script>

<style lang="scss" scoped>

.loading-table{
  width:100%;
  height:100%;
  display: grid;
  place-items: center;
  background:rgba(0,0,0,.05);
  position:absolute;
  top:0;
  left:0;

  .spinner{
    width:45px;    height:45px;
    border-radius:50%;
    position: relative;
    border:5px solid var(--primary);
    border-color:var(--primary) transparent var(--primary) transparent;
    animation: loading 1s infinite;
  }
}

.pagination {
  margin-top: 30px;
  text-align: center;
}

.pagination ul {
  display: inline-block;
  display: flex;
}

.pagination ul li {
  display: inline-block;
  margin-right: 30px;
  width: 24px;
  height: 24px;
}

.pagination .active {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: white;
}
.pagination li{
  cursor:pointer;
}

.abnormality-bar{
  input, select{
    width:100%;
    padding:.5rem;
    font-size:16px;
    border:1px solid var(--dark);
    border-radius:var(--border-radius-1);
    margin-bottom:.5rem;
    &:focus{
      border:1px solid var(--primary);
    }

    &::placeholder{
      font-size:16px;
    }
  }
}

.card-title{
    font-size:20px;
  }
.add-scan {
    margin:1rem 0;
    border-radius:var(--border-radius-1);
    border:2px dotted rgb(224, 104, 58);
    padding:.5rem 1rem;
    font-size:14px;
    color:var(--white);
    background: rgb(224, 104, 58);
    outline:none;
    cursor: pointer;

    &:hover{
      color:rgb(224, 104, 58);
      background:var(--background);
    }
  }

  .search-box{
    margin-bottom:1rem;
    input{
      background-color:var(--light);
      color:var(--dark);
      width:100%;
      max-width:40rem;
      border:1px solid transparent;
      border-radius:var(--border-radius-1);
      padding:.5rem
    }
  }

  .wideTable{
    width:100% !important;
    max-width: fit-content;

    table th{
      color:var(--dark);
    }

    .table-responsive tbody tr:hover{
      background: var(--light);
    }
    tr td{
      text-transform: capitalize;
      color:var(--black);
    }
    tr td:first-child{
      color:var(--primary);
      font-weight:600;
      cursor:pointer;
    }
  }

  .content-modal{
    display: flex;
    justify-content: center;
    padding:1rem;
    padding-top:5rem;
    width: 100vw;
    height: 100vh;
    background: var(--modal-background);
    position: fixed;
    top:0;


    .modal-content{
      width:100%;
      max-width:60rem !important;
      height:fit-content;
      max-height: 90% !important;
      background: var(--white);
      padding:1rem;
      border-radius: var(--card-border-radius);
      overflow-y: auto !important;

      .report{
        position: relative;
        display: flex;
        overflow-y: auto !important;
        .left{
          display:flex;
          justify-content: center;
          align-items: center;
          width:70%;
          
          background: var(--light);
          margin:.5rem;
         
          border-radius: .5rem;
          position: relative;

          img.profile {
              width: 100%;
              background-color: var(--info-light);
              object-fit: cover;
            }
        }
        .right{
          width:30%;
          overflow-y: scroll !important;
          .item{
            
          }
        }
        .top-level{
          display:grid;
          grid-auto-flow: column;
          grid-template-columns: 40% 60%;

          .info{
            padding-left:2rem;
            margin-top:1rem;
            border-left:1px solid var(--info-dark);
          }
          .center-img {
            padding:1rem;
            align-items: flex-start;
            
            .imgbox{

              /*
              background-image: url('http://192.168.0.132:8000/media/mothers/avatar-95fb4ee46aae3dcff7a9f901dc0dd0f6.jpg');
              background-size:100%;
              background-repeat: no-repeat;
              */

              flex:1;
              width:100%;
              height:100%;
              position: relative;
              border-radius: 0%;
            }
          }
        }

        .report-info{
          padding-top:1rem;
          border-top:1px solid var(--info-dark);
        }
      }

      .close-button{
        

        .svg-icon{
          cursor: pointer;
          svg{
            fill:#e32;
            width:2rem;
            height:2rem;
          }
        }
      }

      .center-img{
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      button{
          border:1px solid transparent;
          padding:.5rem 1rem;
          font-size:16px;
          color:var(--white);
          background: var(--primary);
          border-radius:var(--border-radius-1);
          margin-top:2rem;

          &:hover{
            background:var(--white);
            color: var(--primary);
            border:1px solid var(--primary);
            
          }
        }

      .items, .info, .report-info{
        h3{
          color:var(--dark);
          text-transform: capitalize;
          font-size:14px;
          font-weight:400;

          &.title{
            font-size:20px;
            font-weight:600;
            text-align: center;
            text-transform: uppercase;
          }
          span{
            color:var(--info-dark);
            font-size:14px !important;
            font-weight:600 !important;
          }
        }

      }

      .imgbox{
          width:100%;
          position: relative;
          overflow: hidden;
          border-radius: 0%;
          background-color:var(--info-light);
          background:var(--info-light);
          margin-bottom:1rem;

          img.profile{
            width:100%;
            background-color:var(--info-light);
            object-fit: contain;
          }
        }
    }
  }

  button{
    cursor: pointer;
  }


  

  @media screen and (max-width:768px) {
    .content{
      width:100%;
      padding:0;
    }
  }
   @media screen and (min-width:800px) {
    .card-box{
      width:max-content;
    }
    .content{
      padding:1rem !important;
    }
  }
  @media screen and (max-width:1024px) {
    .modal-content{
      overflow-y: auto !important;
      max-height:80dvh;
      button{
        width:100%;
      }
      .report{
        flex-direction: column;
        
        .left{
          width:fit-content;
          display:flex;
          justify-content: center;
          align-items: center;
          background: transparent !important;
          text-align: center;
          //margin-left:-2rem;
          width:100% !important;
          padding:0;
          margin:0;
          
          
          .center-img{
            width: 15rem !important;
          }
          
        }
        .right{
          width:100% !important;
        }
        img.profile{
          margin-left:-1rem;
        }
      }
    }
  }
  
  @media screen and (min-width:768px) {
    *{
      font-size:1rem;
    }
  }

  
  
  .primary, span.primary{
    color:var(--primary) !important;
  }
  .secondary, span.secondary{
    color:var(--secondary) !important;
    background:var(--white) !important;
  }

  .awaiting, span.awaiting{
        color:var(--warning) !important;
    }
    .success, span.success{
        color:var(--success) !important;
        
    }
    .failed, span.failed{
        color:var(--danger) !important;
    }


  .item select{
    width:100%;
    padding:.5rem;
    font-size:1rem;
    border-radius: var(--border-radius-1);
  }
  .item label{
    text-transform: capitalize;
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  
</style>