<template>
  <main>
    <div class="content" v-if="userData">
      <span style="font-size: 24px;font-weight:600;display:flex;justify-content:flex-;">{{userData.hospitalAdminInfo.name}}</span>
      <h3
        style="
          text-transform: capitalize;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
      <span style="font-size: 20px;margin-top:2rem">Tamela AI Model Analysis</span>
       
       
        <div class="imgbox selectedPatient" v-if="selectedPatient" style="">
          <img :src="selectedPatient.mother_image" alt="" style="object-fit: cover;width:100%">
        </div>
      </h3>
      
        <div class="search-box">
          <div class="split-box">
            <label for="" style="font-size: 20px;margin-right:1rem;">Selected Patient: <span style="font-size: 20px;font-weight:600;text-transform: uppercase;" v-if="selectedPatient">{{selectedPatient.lastname}} {{selectedPatient.firstname}} {{selectedPatient.othername}} (<span style="color:var(--primary)">{{ selectedPatient.tamelaID }}</span>)</span></label>
            <div class="vitals-box" v-if="selectedPatient">
              <span class="vitalslink" :class="{'danger': !patientVitals}" @click="showVitalModal=true">get vitals</span>
            </div>
          </div>
          
          <input type="text" placeholder="Search for Mothers"  v-model="searchQuery" v-if="!selectedPatient">
          <div class="table-responsive" style="" v-if="!selectedPatient">
            <table class="table mb-0">
              <thead>
                <tr>
                    <th>Tamela ID</th>
                    <th>Surname / Lastname</th>
                    <th>Firstname</th>
                    <th>Othername</th>
                    <th>Age</th>
                    <th></th>
                </tr>
            </thead>
              <tbody v-if="mothers && mothers.length >= 1">
                <tr v-for="(patient, index) in mothers" :key="index" v-show="isMatch(patient)">
                    <td>{{ patient.tamelaID }}</td>
                    <td>{{ patient.lastname }}</td>
                    <td>{{ patient.firstname }}</td>
                    <td>{{ patient.othername }}</td>
                    <td>{{ patient.age }}</td>
                    <td><span @click="checkVitals(patient.uuid,true);selectPatient(patient)" style="padding:.5rem 1rem;cursor:pointer; background:var(--primary);color:#fff;border-radius:var(--border-radius-1);">Select Patient</span></td>
                </tr>

                
    
            </tbody>
            </table>
          </div>
        </div>
        <span v-if="selectedPatient" style="background:var(--danger);color:#fff;
          padding:.5rem 1rem;cursor:pointer; border-radius:var(--border-radius-1);" @click="clearSelectedPatient()">Clear Selection</span>
      

          
          

        <div class="row bulk" style="margin-top:2rem;">
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
        
        
            <div class="ag-courses-item_link " @click="selectModel('all')">
              <div class="ag-courses-item_bg"></div>
  
              <div class="ag-courses-item_title">
                Use All Available Models 
              </div>
              
              <div class="ag-courses-item_date-box">
                Average Model Accuracy:
                <span class="ag-courses-item_date"> 94% </span>
              </div>
              
            </div>
  
            
          </div>
        </div>
      
      <div class="row" style="margin-top: 2rem;">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <div class="ag-courses-item_link" @click="selectModel('fetal_planes')">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Fetal Planes 
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                97%
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <div class="ag-courses-item_link" @click="selectModel('fetal_orientation')">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Fetal Orientation
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                98%
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <div class="ag-courses-item_link" @click="selectModel('fetal_anatomy')">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Fetal Anatomy
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                98%
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <a href="#" class="ag-courses-item_link" @click="selectModel('presentation')">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Presentation
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                95%
              </span>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <a href="#" class="ag-courses-item_link" @click="selectModel('liquor_volume')">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Liquor volume 
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                93%
              </span>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <a href="#" class="ag-courses-item_link" @click="selectModel('placenta_location')">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Placenta Location 
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                94%
              </span>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          
          
          <div class="ag-courses-item_link ">
            <div class="ag-courses-item_bg"></div>

            <div class="ag-courses-item_title">
              Gestational Diabetes in Mother  <span class="not-available">(coming soon)</span>
            </div>
           
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date"> 98% </span>
            </div>
            
          </div>

          
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <a href="#" class="ag-courses-item_link">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Hepatitis-B  <span class="not-available">(coming soon)</span>
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                97%
              </span>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <a href="#" class="ag-courses-item_link">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Hepatitis-C  <span class="not-available">(coming soon)</span>
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                96%
              </span>
            </div>
          </a>
        </div>
        
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <a href="#" class="ag-courses-item_link">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              MHR  <span class="not-available">(coming soon)</span>
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                97%
              </span>
            </div>
          </a>
        </div>
        
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <div class="ag-courses-item_link">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              CTG model  <span class="not-available">(coming soon)</span>
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                96%
              </span>
            </div>
          </div>
        </div>
        
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <a href="#" class="ag-courses-item_link">
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Fetal Anaemia  <span class="not-available">(coming soon)</span>
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                98%
              </span>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3 model">
          <a href="#" class="ag-courses-item_link" >
            <div class="ag-courses-item_bg"></div>
    
            <div class="ag-courses-item_title">
              Hypertension  <span class="not-available">(coming soon)</span>
            </div>
    
            <div class="ag-courses-item_date-box">
              Model Accuracy:
              <span class="ag-courses-item_date">
                95%
              </span>
            </div>
          </a>
        </div>
        
       
      </div>


      <div class="row d-none">
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <div class="card">
            <div class="card-body">
              <div class="chart-title">
                <h4 v-if="userData.usercat === 'hospitaladmin'">Scans from AI models</h4>
                <span class="float-right"
                  ><i class="fa fa-caret-up" aria-hidden="true" v-if="graph_sign_up"></i
                  ><i class="fa fa-caret-down" aria-hidden="true" v-else></i
                  >{{ graph_month_remarks }}</span
                >
              </div>
              <canvas id="linegraph"></canvas>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <div class="card">
            <div class="card-body">
              <div class="chart-title">
                <h4>Patients Admitted</h4>
                <div class="float-right">
                  <ul class="chat-user-total" style="display: none">
                    <li>
                      <i class="fa fa-circle current-users" aria-hidden="true"></i>ICU
                    </li>
                    <li><i class="fa fa-circle old-users" aria-hidden="true"></i> OPD</li>
                  </ul>
                </div>
              </div>
              <canvas id="bargraph"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-none">
        <div class="col-12 col-md-6 col-lg-8 col-xl-8">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title d-inline-block">Recent AI Model Scans</h4>
              <router-link class="btn btn-primary float-right" to="/scans"
                >View all</router-link
              >
            </div>
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="d-none">
                    <tr>
                      <th>Mother's Name</th>
                      <th>Date and Time</th>
                      <th>Status</th>

                      <th class="text-right">Status</th>
                    </tr>
                  </thead>
                  <tbody v-if="scans && scans.length >= 1">
                    <tr v-for="(scan, index) in scans" :key="index">
                      <td style="min-width: 200px">
                        <p class="avatar"><img :src="scan.mother_data.image" alt="" /></p>
                        <h2 style="text-transform: capitalize">
                          <span class="mother-name"
                            >{{ scan.mother_data.lastname }}
                            {{ scan.mother_data.firstname }}
                            {{ scan.mother_data.othername
                            }}<span
                              >{{ scan.mother_data.city }},
                              {{ scan.mother_data.state }}</span
                            ></span
                          >
                        </h2>
                      </td>
                      <td>
                        <h5 class="time-title p-0">Scan ID</h5>
                        <p style="color: var(--primary)">{{ scan.scanID }}</p>
                      </td>
                      <td>
                        <h5 class="time-title p-0">Scanned on</h5>
                        <p>{{ moment(`${scan.date_added}`).fromNow() }}</p>
                      </td>
                      <td>
                        <h5 class="time-title p-0">Status</h5>
                        <p :class="scan.status" style="text-transform: uppercase">
                          {{ scan.status }}
                        </p>
                      </td>
                      <td class="text-right">
                        <p class="btn btn-outline-secondary take-btn">View Details</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4" style="">
          <div class="card member-panel">
            <div class="card-header bg-primary">
              <h4 class="card-title mb-0" style="color: #fff">Doctors</h4>
            </div>
            <div class="card-body">
              <ul class="contact-list" v-if="doctors">
                <li v-for="(doctor, index) in doctors" :key="index">
                  <div class="contact-cont">
                    <div class="float-left user-img m-r-10">
                      <a href="/" :title="doctor.staff_user_details.name">
                        <img
                          v-if="doctor.staff_user_details.image"
                          :src="doctor.staff_user_details.image"
                          alt=""
                          class="w-40 rounded-circle"
                        />
                        <img
                          v-else
                          :src="require('../assets/img/user.jpg')"
                          alt=""
                          class="w-40 rounded-circle"
                        />
                        <span
                          class="status online"
                          v-if="doctor.status == 'active'"
                        ></span>
                        <span
                          class="status offline"
                          v-if="doctor.status == 'inactive'"
                        ></span>
                        <span
                          class="status away"
                          v-if="doctor.status == 'pending'"
                        ></span>
                      </a>
                    </div>
                    <div class="contact-info">
                      <span
                        class="contact-name text-ellipsis"
                        style="text-transform: capitalize"
                        >{{ doctor.staff_user_details.name }}</span
                      >
                      <span class="contact-date" style="text-transform: capitalize">{{
                        doctor.role
                      }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-footer text-center bg-white">
              <a href="doctors.html" class="text-muted">View all Doctors</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-none">
        <div class="col-12 col-md-6 col-lg-8 col-xl-8">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title d-inline-block">Patients</h4>
              <router-link to="/mothers" class="btn btn-primary float-right"
                >View all</router-link
              >
            </div>
            <div class="card-block">
              <div class="table-responsive">
                <table class="table mb-0 new-patient-table">
                  <tbody v-if="mothers && mothers.length >= 1">
                    <tr v-for="(mother, index) in mothers" :key="index">
                      <td>
                        <img
                          width="28"
                          height="28"
                          class="rounded-circle"
                          :src="mother.mother_image || require('../assets/img/user.jpg')"
                          alt=""
                        />
                        <h2>
                          {{ mother.lastname }} {{ mother.firstname }}
                          {{ mother.othername }}
                        </h2>
                      </td>
                      <td class="primary" style="color: var(--primary)">
                        {{ mother.tamelaID }}
                      </td>
                      <td>{{ mother.city }}, {{ mother.state }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4" style="display: none">
          <div class="hospital-barchart">
            <h4 class="card-title d-inline-block">Hospital Management</h4>
          </div>
          <div class="bar-chart">
            <div class="legend">
              <div class="item">
                <h4>Level1</h4>
              </div>

              <div class="item">
                <h4>Level2</h4>
              </div>
              <div class="item text-right">
                <h4>Level3</h4>
              </div>
              <div class="item text-right">
                <h4>Level4</h4>
              </div>
            </div>
            <div class="chart clearfix">
              <div class="item">
                <div class="bar">
                  <span class="percent">16%</span>
                  <div class="item-progress" data-percent="16">
                    <span class="title">OPD Patient</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="bar">
                  <span class="percent">71%</span>
                  <div class="item-progress" data-percent="71">
                    <span class="title">New Patient</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="bar">
                  <span class="percent">82%</span>
                  <div class="item-progress" data-percent="82">
                    <span class="title">Laboratory Test</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="bar">
                  <span class="percent">67%</span>
                  <div class="item-progress" data-percent="67">
                    <span class="title">Treatment</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="bar">
                  <span class="percent">30%</span>
                  <div class="item-progress" data-percent="30">
                    <span class="title">Discharge</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-modal" style="z-index:1000" v-if="selectedModel">
      <div class="modal-content">
          <div class="close-button">
            <span class="svg-icon"><svg @click="selectedModel = null"
                    xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                    <path
                        d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
                </svg></span>
          </div>

          <h3 style="text-align:center;font-size:14px;color:#e32; margin-top:1rem;font-weight:600" v-if="!patientVitals">NO VITALS RECORDED IN THE LAST 24 HRS</h3>
          <h3 style="text-align:center;font-size:14px;color:var(--primary); margin-top:1rem;font-weight:600" v-else>PREVIOUS VITALS DATETIME: {{moment(`${patientVitals.date_taken}`).format("DD MMM, YYYY. HH:MM:SS")}} ({{moment(`${patientVitals.date_taken}`).fromNow()}})</h3>

          <div class="upload-div" v-if="selectedModel == 'fetal_orientation'">
            <h3 class="title">Fetal Orientation Model</h3>
            <div class="form-data" id="upload-form" ref="scanform">
              <div class="field">
                <label for="">Name</label>
                <input type="text" name="" id="" :value="selectedPatient.lastname + ' ' + selectedPatient.firstname + ' ' + selectedPatient.othername" disabled>
              </div>
              <div class="field">
                <label for="">Tamela ID</label>
                <input type="text" name="" id="" :value="selectedPatient.tamelaID" disabled>
              </div>
              <div class="field">
                <label for="">Foetus age in weeks</label>
                <input type="number" name="" id="" placeholder="" min="0" max="52" v-model="foetusAge" @input="foetusAge = foetusAge<0?0:foetusAge>52?52:foetusAge" required>
              </div>
              <div class="field">
                <label for="">Foetus label</label>
                <input type="text" name="" id="" value=""  v-model="foetusLabel">
              </div>
              
            <p>Image should not exceed {{maxSize/(1024*1024)}}MB</p>
              <div class="field">
                
                <label for="">Ultrasound Image</label>
                <input type="file" accept="image/jpeg, image/png" name="" id="" value="" @change="handleImageChange" required>
              </div>
            </div>
            <div class="image-show" v-if="imageUrl">
              <img :src="imageUrl" alt="">

            </div>
            <button  :disabled="!image" @click="uploadImage()">Upload Image</button>
          </div>

          <div class="upload-div" v-if="selectedModel == 'fetal_anatomy'">
            <h3 class="title">Fetal Anatomy Model</h3>
            <div class="form-data" id="upload-form" ref="scanform">
              <div class="field">
                <label for="">Name</label>
                <input type="text" name="" id="" :value="selectedPatient.lastname + ' ' + selectedPatient.firstname + ' ' + selectedPatient.othername" disabled>
              </div>
              <div class="field">
                <label for="">Tamela ID</label>
                <input type="text" name="" id="" :value="selectedPatient.tamelaID" disabled>
              </div>
              <div class="field">
                <label for="">Foetus age in weeks</label>
                <input type="number" name="" id="" placeholder="" min="0" max="52" v-model="foetusAge" @input="foetusAge = foetusAge<0?0:foetusAge>52?52:foetusAge" required>
              </div>
              <div class="field">
                <label for="">Foetus label</label>
                <input type="text" name="" id="" value=""  v-model="foetusLabel">
              </div>
              
            <p>Image should not exceed {{maxSize/(1024*1024)}}MB</p>
              <div class="field">
                
                <label for="">Ultrasound Image</label>
                <input type="file" accept="image/jpeg, image/png" name="" id="" value="" @change="handleImageChange" required>
              </div>
            </div>
            <div class="image-show" v-if="imageUrl">
              <img :src="imageUrl" alt="">

            </div>
            <button  :disabled="!image" @click="uploadImage()">Upload Image</button>
          </div>

          <div class="upload-div" v-if="selectedModel == 'fetal_planes'">
            <h3 class="title">Fetal Planes Model</h3>
            <div class="form-data" id="upload-form" ref="scanform">
              <div class="field">
                <label for="">Name</label>
                <input type="text" name="" id="" :value="selectedPatient.lastname + ' ' + selectedPatient.firstname + ' ' + selectedPatient.othername" disabled>
              </div>
              <div class="field">
                <label for="">Tamela ID</label>
                <input type="text" name="" id="" :value="selectedPatient.tamelaID" disabled>
              </div>
              <div class="field">
                <label for="">Foetus age in weeks</label>
                <input type="number" name="" id="" placeholder="" min="0" max="52" v-model="foetusAge" @input="foetusAge = foetusAge<0?0:foetusAge>52?52:foetusAge" required>
              </div>
              <div class="field">
                <label for="">Foetus label</label>
                <input type="text" name="" id="" value=""  v-model="foetusLabel">
              </div>
              
            <p>Image should not exceed {{maxSize/(1024*1024)}}MB</p>
              <div class="field">
                
                <label for="">Ultrasound Image</label>
                <input type="file" accept="image/jpeg, image/png" name="" id="" value="" @change="handleImageChange" required>
              </div>
            </div>
            <div class="image-show" v-if="imageUrl">
              <img :src="imageUrl" alt="">

            </div>
            <button  :disabled="!image" @click="uploadImage()">Upload Image</button>
          </div>

          <div class="upload-div" v-if="selectedModel == 'liquor_volume'">
            <h3 class="title">Liquor Volume Model</h3>
            <div class="form-data" id="upload-form" ref="scanform">
              <div class="field">
                <label for="">Name</label>
                <input type="text" name="" id="" :value="selectedPatient.lastname + ' ' + selectedPatient.firstname + ' ' + selectedPatient.othername" disabled>
              </div>
              <div class="field">
                <label for="">Tamela ID</label>
                <input type="text" name="" id="" :value="selectedPatient.tamelaID" disabled>
              </div>
              <div class="field">
                <label for="">Foetus age in weeks</label>
                <input type="number" name="" id="" placeholder="" min="0" max="52" v-model="foetusAge" @input="foetusAge = foetusAge<0?0:foetusAge>52?52:foetusAge" required>
              </div>
              <div class="field">
                <label for="">Foetus label</label>
                <input type="text" name="" id="" value=""  v-model="foetusLabel">
              </div>
              
            <p>Image should not exceed {{maxSize/(1024*1024)}}MB</p>
              <div class="field">
                
                <label for="">Ultrasound Image</label>
                <input type="file" accept="image/jpeg, image/png" name="" id="" value="" @change="handleImageChange" required>
              </div>
            </div>
            <div class="image-show" v-if="imageUrl">
              <img :src="imageUrl" alt="">

            </div>
            <button  :disabled="!image" @click="uploadImage()">Upload Image</button>
          </div>

          <div class="upload-div" v-if="selectedModel == 'presentation'">
            <h3 class="title">Presentation Model</h3>
            <div class="form-data" id="upload-form" ref="scanform">
              <div class="field">
                <label for="">Name</label>
                <input type="text" name="" id="" :value="selectedPatient.lastname + ' ' + selectedPatient.firstname + ' ' + selectedPatient.othername" disabled>
              </div>
              <div class="field">
                <label for="">Tamela ID</label>
                <input type="text" name="" id="" :value="selectedPatient.tamelaID" disabled>
              </div>
              <div class="field">
                <label for="">Foetus age in weeks</label>
                <input type="number" name="" id="" placeholder="" min="0" max="52" v-model="foetusAge" @input="foetusAge = foetusAge<0?0:foetusAge>52?52:foetusAge" required>
              </div>
              <div class="field">
                <label for="">Foetus label</label>
                <input type="text" name="" id="" value=""  v-model="foetusLabel">
              </div>
              
            <p>Image should not exceed {{maxSize/(1024*1024)}}MB</p>
              <div class="field">
                
                <label for="">Ultrasound Image</label>
                <input type="file" accept="image/jpeg, image/png" name="" id="" value="" @change="handleImageChange" required>
              </div>
            </div>
            <div class="image-show" v-if="imageUrl">
              <img :src="imageUrl" alt="">

            </div>
            <button  :disabled="!image" @click="uploadImage()">Upload Image</button>
          </div>

          <div class="upload-div" v-if="selectedModel == 'placenta_location'">
            <h3 class="title">Placenta Location Model</h3>
            <div class="form-data" id="upload-form" ref="scanform">
              <div class="field">
                <label for="">Name</label>
                <input type="text" name="" id="" :value="selectedPatient.lastname + ' ' + selectedPatient.firstname + ' ' + selectedPatient.othername" disabled>
              </div>
              <div class="field">
                <label for="">Tamela ID</label>
                <input type="text" name="" id="" :value="selectedPatient.tamelaID" disabled>
              </div>
              <div class="field">
                <label for="">Foetus age in weeks</label>
                <input type="number" name="" id="" placeholder="" min="0" max="52" v-model="foetusAge" @input="foetusAge = foetusAge<0?0:foetusAge>52?52:foetusAge" required>
              </div>
              <div class="field">
                <label for="">Foetus label</label>
                <input type="text" name="" id="" value=""  v-model="foetusLabel">
              </div>
              
            <p>Image should not exceed {{maxSize/(1024*1024)}}MB</p>
              <div class="field">
                
                <label for="">Ultrasound Image</label>
                <input type="file" accept="image/jpeg, image/png" name="" id="" value="" @change="handleImageChange" required>
              </div>
            </div>
            <div class="image-show" v-if="imageUrl">
              <img :src="imageUrl" alt="">

            </div>
            <button  :disabled="!image" @click="uploadImage()">Upload Image</button>
          </div>

          <div class="upload-div" v-if="selectedModel == 'all'">
            <h3 class="title">All Available Models</h3>
            <div class="form-data" id="upload-form" ref="scanform">
              <div class="field">
                <label for="">Name</label>
                <input type="text" name="" id="" :value="selectedPatient.lastname + ' ' + selectedPatient.firstname + ' ' + selectedPatient.othername" disabled>
              </div>
              <div class="field">
                <label for="">Tamela ID</label>
                <input type="text" name="" id="" :value="selectedPatient.tamelaID" disabled>
              </div>
              <div class="field">
                <label for="">Foetus age in weeks</label>
                <input type="number" name="" id="" placeholder="" min="0" max="52" v-model="foetusAge" @input="foetusAge = foetusAge<0?0:foetusAge>52?52:foetusAge" required>
              </div>
              <div class="field">
                <label for="">Foetus label</label>
                <input type="text" name="" id="" value=""  v-model="foetusLabel">
              </div>
              
            <p>Image should not exceed {{maxSize/(1024*1024)}}MB</p>
              <div class="field">
                
                <label for="">Ultrasound Image</label>
                <input type="file" accept="image/jpeg, image/png" name="" id="" value="" @change="handleImageChange" required>
              </div>
            </div>
            <div class="image-show" v-if="imageUrl">
              <img :src="imageUrl" alt="">

            </div>
            <button  :disabled="!image" @click="uploadImage()">Upload Image</button>
          </div>
      </div>
    </div>

    <div class="content-modal vitals" v-if="showVitalModal">
      <div class="modal-content">
        <div class="close-button">
          <span class="svg-icon"><svg @click="showVitalModal = false"
                  xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                  <path
                      d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
              </svg></span>
        </div>

        <h3 class="title" style="margin-top:1rem;font-size:18px;">Vitals for <span style="text-transform: capitalize;color:var(--primary)">{{selectedPatient.lastname}} {{selectedPatient.firstname}} {{selectedPatient.othername}} </span></h3>
        <!--
        <div class="add-field">
          <button>Add Field</button>
        </div>-->
        <div class="fields">
          <div class="field-item">
            <label for="">Temperature</label>
            <input type="number" name="" id="" placeholder="Temp (°C)" v-model="vitals.temperature">
          </div>
          <div class="field-item">
            <label for="">Blood Pressure</label>
            <input type="text" name="" id="" placeholder="Systolic / Diastolic" v-model="vitals.blood_pressure">
          </div>
          <div class="field-item">
            <label for="">Pulse</label>
            <input type="text" name="" id="" placeholder="" v-model="vitals.pulse">
          </div>
          <div class="field-item">
            <label for="">Weight</label>
            <input type="text" name="" id="" placeholder="Weight (Kg)" v-model="vitals.weight">
          </div>
          
          <button class="submit-vitals" @click="sendVitals">Submit Vitals</button>
        </div>
      </div>
    </div>




    <div class="notification-box">
      <div class="msg-sidebar notifications msg-noti">
        <div class="topnav-dropdown-header">
          <span>Messages</span>
        </div>
        <div class="drop-scroll msg-list-scroll" id="msg_list">
          <ul class="list-box">
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Richard Miles </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item new-message">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">John Doe</span>
                    <span class="message-time">1 Aug</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Tarah Shropshire </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Mike Litorus</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Catherine Manseau </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">D</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Domenic Houston </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">B</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Buster Wigton </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">R</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Rolland Webber </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">C</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author"> Claire Mapes </span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">M</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Melita Faucher</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">J</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Jeffery Lalor</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">L</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Loren Gatlin</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="chat.html">
                <div class="list-item">
                  <div class="list-left">
                    <span class="avatar">T</span>
                  </div>
                  <div class="list-body">
                    <span class="message-author">Tarah Shropshire</span>
                    <span class="message-time">12:28 AM</span>
                    <div class="clearfix"></div>
                    <span class="message-content"
                      >Lorem ipsum dolor sit amet, consectetur adipiscing</span
                    >
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <a href="chat.html">See all messages</a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
var moment = require("moment");
import { Chart } from "../assets/js/Chart.bundle";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
  data() {
    return {
      dashboardData: {
        patientCount: "...",
        scanCount: "...",
        lineChartData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        lineChartData2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        hospitalCount: "...",
        doctorCount: "...",
      },
      userData: null,
      graph_sign_up: true,
      graph_month_remarks: "",
      moment: moment,
      scans: [],
      mothers: [],
      selectedModel:null,
      selectedPatient:this.$store.state.selectedPatient ||  null,
      searchQuery:"",
      maxSize: 5 * 1024 * 1024,
      foetusAge: '',
      foetusLabel:'',
      imageFile: null,
      formData: null,
      imageUrl:null,
      image:null,
      showVitalModal:false,
      patientVitals:null,
      vitals:{
        temperature:'',
        blood_pressure:'',
        pulse:'',
        weight:'',
      }
    };
  },
  mounted() {
    document.title = "Tamela Dashboard";
    this.$root.showLoader = true;
    if(this.$store.state.selectedPatient){
      this.checkVitals(this.$store.state.selectedPatient.uuid,true);
    }
    if(this.$store.state.usercat !== "hospitaladmin" && this.$store.state.usercat !== "doctor"){
      this.$router.push("/login");
    }
    console.log(this.$store.state.currentPage);
    if(this.$store.state.currentPage !== 'models'){
      this.$store.commit('selectPatient', null);
      this.selectedPatient = null
    }
    this.$store.commit("currentPage", "models");
    this.getUserData();
    //this.getDoctors();
    
  },
  methods: {
    
    selectModel(model){
      if(!model){
        return false;
      }
      if(!this.selectedPatient){
        toast.error("Select a Patient First", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
      } else {
        this.selectedModel = model
      }
    },
    isMatch(patient) {
      const searchLowerCase = this.searchQuery.toLowerCase();
      return (
        patient.tamelaID.includes(this.searchQuery.toUpperCase()) ||
        patient.firstname.toLowerCase().includes(searchLowerCase) ||
        patient.lastname.toLowerCase().includes(searchLowerCase) ||
        patient.othername.toLowerCase().includes(searchLowerCase) ||
        patient.city.toLowerCase().includes(searchLowerCase) ||
        patient.state.toLowerCase().includes(searchLowerCase)
      );
    },
    selectPatient(patient){
      this.selectedPatient = patient
      this.$store.commit('selectPatient', patient)
    },
    clearSelectedPatient(){
      this.selectedPatient = null
      this.$store.commit('selectPatient', null)
    },
    async getUserData() {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      try {
        const response = await axios.get(
          "accounts/userdata?user=current&usercat="+this.$store.state.usercat,
          { headers }
        );
        this.userData = response.data.data;
        console.log(this.userData);

        if (
          this.userData.usercat === "hospitaladmin" ||
          this.userData.usercat === "doctor" 
        ) {
          this.$store.commit('setUsercat',this.userData.usercat)
          document.querySelector(".page-wrapper").style.paddingTop = "70px";
          this.$root.loadScripts();
          this.$root.resetSideBar();
          // Uncomment the following lines if these methods are needed
          //this.getScans();
          this.getMothers();
          //this.getDashboardData();
          //this.setChartData();
          this.$root.showLoader = false;
        } else {
          this.$root.showLoader = false;
          this.$router.push("/login");
        }


      } catch (error) {
        if (error.response) {
          // Error responses from the server
          const status = error.response.status;
          if (status === 401) {
            // Unauthorized access
            await this.refreshToken(this.getUserData);
          } else {
            // Handle other status codes
            console.error(`Error ${status}: ${error.response.data.message}`);
            toast.error(`Error ${status}: ${error.response.data.message}`, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (error.request) {
          // Request made but no response received
          console.error("No response received:", error.request);
          toast.error("No response received. Please try again later.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Other errors
          console.error("Error", error);
          toast.error(`Error: ${error}`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
    async refreshToken(callback) {
      try {
        const response = await axios.post("api/token/refresh", {
          refresh: this.$store.state.refresh,
        });

        if (response.data.access) {
          this.$store.commit("setAccess", response.data.access);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.access;
          await callback();
        } else {
          this.$router.push("/login");
        }
      } catch (error) {
        console.error("Failed to refresh token", error);
        this.$router.push("/login");
      }
    },
    async getDoctors() {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      try {
        const response = await axios.get("accounts/doctors?user=current", { headers });
        this.doctors = response.data.data;
        console.log(this.doctors);
      } catch (error) {
        if (error.response) {
          // Error responses from the server
          const status = error.response.status;
          if (status === 401) {
            // Unauthorized access
            await this.refreshToken(this.getDoctors);
          } else {
            // Handle other status codes
            console.error(`Error ${status}: ${error.response.data.message}`);
            toast.error(`Error ${status}: ${error.response.data.message}`, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (error.request) {
          // Request made but no response received
          console.error("No response received:", error.request);
          toast.error("No response received. Please try again later.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Other errors
          console.error("Error", error);
          toast.error(`Error: ${error}`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
    async uploadImage() {
      const scanForm = this.$refs.scanform;
      this.$root.showLoader = true;

        if (this.image) {
          console.log(this.selectedPatient)
          const formData = new FormData();
          formData.append('image', this.image);
          formData.append('uid', this.selectedPatient.uuid);
          formData.append('foetus_age', this.foetusAge);
          formData.append('foetus_label', this.foetusLabel || '-');
          formData.append('ai_model', this.selectedModel);
          formData.append('usercat', this.$store.state.usercat);

          const accessToken = this.$store.state.access;
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data', // Change content type to multipart/form-data
          };


          try {
            // Use await instead of .then to handle async operations
            toast.info("Processing... Please wait.", { autoClose: 8000, position: toast.POSITION.TOP_RIGHT });
            this.selectedModel = null

            const response = await axios.post('api/v1/scan', formData, { headers });
            console.log(response.data);
            if (response.data.status) {
              // Clear image and preview after successful upload
              this.$root.showLoader = false;  
      
      

              toast.success("Scan Uploaded", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
              this.image = null;
              this.imageUrl = null;
              //this.isScanOpen = false;
              this.selectedModel = null
            }
          } catch (error) {
            // Handle errors
            if (error.code != 'ERR_NETWORK') {
              if (error.response.status === 401) {
                this.refreshToken(this.uploadImage);
              }
            } else {
              console.error(error);
              const error_message = error.code === 'ERR_NETWORK' ? 'Unable to Connect. Check your network connection.' : error.code;
              toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
            }
          }
        } else {
          console.error('No image selected.');
        }
      
    },
    async sendVitals(){
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        patient:this.selectedPatient.uuid,
        usercat:this.$store.state.usercat,
        vital_data:this.vitals
      }

      try {
            // Use await instead of .then to handle async operations
            const response = await axios.post('api/v1/vitals', data, { headers });
            console.log(response.data);
            if (response.data.status) {
              // Clear image and preview after successful upload
              this.checkVitals(this.$store.state.selectedPatient.uuid,true);
              toast.success("Vitals Uploaded", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
              this.vitals = {
                  temperature:'',
                  blood_pressure:'',
                  pulse:'',
                  weight:'',
                }
                this.showVitalModal = false;
            }
      } catch (error) {
        // Handle errors
        if (error.code != 'ERR_NETWORK') {
          if (error.response.status === 401) {
            this.refreshToken(this.sendVitals);
          }
        } else {
          console.error(error);
          const error_message = error.code === 'ERR_NETWORK' ? 'Unable to Connect. Check your network connection.' : error.code;
          toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        }
      }

    },
    async checkVitals(mother=null, single_check=false){
      this.$root.showLoader = true;
      const accessToken = this.$store.state.access;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const extra = mother!==null ? `?mother=${mother}&single_check=${single_check}`:''
      try {
            // Use await instead of .then to handle async operations
            const response = await axios.get(`api/v1/vitals${extra}`, { headers });
            console.log(response.data);
            if (response.data.status) {
              // Clear image and preview after successful upload
              //toast.success("Vitals Uploaded", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
              this.patientVitals = response.data.data;
              this.$root.showLoader = false;
            }
      } catch (error) {
        // Handle errors
        if (error.code != 'ERR_NETWORK') {
          if (error.response.status === 401) {
            this.refreshToken(this.checkVitals(mother,single_check));
          }
        } else {
          console.error(error);
          const error_message = error.code === 'ERR_NETWORK' ? 'Unable to Connect. Check your network connection.' : error.code;
          toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
          this.$root.showLoader = false;
        }
      }
    },
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > this.maxSize) {
                    toast.error("Max file size exceeded. Please select a smaller image.", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })

          return;
        }
        this.image = file;
        // Display image preview
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    setChartData() {
      //this.dashboardData.lineChartData = [10,22,31,34,13,53,63,23,13,42,55,23]
      let currentMonth = new Date().getMonth();
      let prevMonth = currentMonth >= 1 ? currentMonth - 1 : 11;
      let currentMonthValue = this.dashboardData.lineChartData[currentMonth];
      let prevMonthValue =
        this.dashboardData.lineChartData[prevMonth] == 0
          ? 1
          : this.dashboardData.lineChartData[prevMonth];

      // Calculate the percentage raise or decrease
      let percentageRaise = ((currentMonthValue - prevMonthValue) * 100) / prevMonthValue;

      // Use Math.abs() to get the absolute value of the percentage raise
      percentageRaise = Math.abs(percentageRaise);

      //console.log(percentageRaise);

      if (currentMonthValue >= prevMonthValue) {
        this.graph_month_remarks = `${percentageRaise.toFixed(
          2
        )}% increase from last month`;
        this.graph_sign_up = true;
      } else {
        this.graph_month_remarks = `${percentageRaise.toFixed(
          2
        )}% decrease from last month`;
        this.graph_sign_up = false;
      }
      let lineChartData = {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Number of Scans",
            backgroundColor: "rgba(42, 45, 116, 0.5)",
            data: this.dashboardData.lineChartData,
          },
          {
            label: "No of Patients",
            backgroundColor: "rgba(224, 104, 58, 0.7)",
            fill: true,
            data: this.dashboardData.lineChartData2, //[10,22,31,34,13,53,63,23,13,42,55,23]
          },
        ],
      };

      let linectx = document.getElementById("linegraph").getContext("2d");
      window.myLine = new Chart(linectx, {
        type: "line",
        data: lineChartData,
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
        },
      });

      var barChartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
          {
            label: "Dataset 1",
            backgroundColor: "rgba(0, 158, 251, 0.5)",
            borderColor: "rgba(0, 158, 251, 1)",
            borderWidth: 1,
            data: [35, 59, 80, 81, 56, 55, 40],
          },
          {
            label: "Dataset 2",
            backgroundColor: "rgba(255, 188, 53, 0.5)",
            borderColor: "rgba(255, 188, 53, 1)",
            borderWidth: 1,
            data: [28, 48, 40, 19, 86, 27, 90],
          },
        ],
      };

      var ctx = document.getElementById("bargraph").getContext("2d");
      window.myBar = new Chart(ctx, {
        type: "bar",
        data: barChartData,
        options: {
          responsive: true,
          legend: {
            display: false,
          },
        },
      });
    },
    async getDashboardData() {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      await axios
        .get("api/v1/dashboard/data", { headers })
        .then((response) => {
          const data = response.data.data;

          this.dashboardData.patientCount = data.patient_count;
          this.dashboardData.scanCount = data.scan_count;
          this.dashboardData.lineChartData = data.scan_count_list;
          this.dashboardData.lineChartData2 = data.patient_count_list;
          this.dashboardData.hospitalCount = data.hospitalCount;

          this.setChartData();
          this.$root.showLoader = false;
        })
        .catch((error) => {
          if (error.code != "ERR_NETWORK") {
            if (error.response.status === 401) {
              this.refreshToken(this.getDashboardData);
            }
          } else {
            console.error(error);
            const error_message =
              error.code === "ERR_NETWORK"
                ? "Unable to Connect. Check your network connection."
                : error.code;
            toast.error(error_message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    },
    isIOS() {
      const userAgent = navigator.userAgent;
      return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    },
    async getScans(mother = null) {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      await axios
        .get("api/v1/scan?usercat=" + this.userData.usercat || "hospitalAdmin", {
          headers,
        })
        .then((response) => {
          this.scans = response.data.data;
          this.$root.showLoader = false;
        })
        .catch((error) => {
          if (error.code != "ERR_NETWORK") {
            if (error.response.status === 401) {
              this.refreshToken(this.getScans);
            }
          } else {
            console.error(error);
            const error_message =
              error.code === "ERR_NETWORK"
                ? "Unable to Connect. Check your network connection."
                : error.code;
            toast.error(error_message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    },
    async getMothers(mother = null) {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };
      let searchQuery = "accounts/mothers?&usercat="+ this.userData.usercat
      
      if(this.userData.usercat === "doctor"){
        searchQuery += "&hospital="+this.userData.hospitalAdminInfo.hospital_uuid
      } else {
        searchQuery += "&hospital=current"
      }
      console.log(searchQuery)
      await axios
        .get(searchQuery, { headers })
        .then((response) => {
          this.mothers = response.data.data;
          console.log(this.mothers)
          this.$root.showLoader = false;
        })
        .catch((error) => {
          if (error.code != "ERR_NETWORK") {
            if (error.response.status === 401) {
              this.refreshToken(this.getMothers);
            }
          } else {
            console.error(error);
            const error_message =
              error.code === "ERR_NETWORK"
                ? "Unable to Connect. Check your network connection."
                : error.code;
            toast.error(error_message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  color: var(--dark);
}
.awaiting {
  color: var(--primary);
}
.success {
  color: var(--success);
}
.failed {
  color: var(--danger);
}

.card {
  background: var(--white) !important;
  border-radius: var(--card-border-radius) !important;
  box-shadow: var(--box-shadow);
}

table {
  border: 1px solid var(--light);

  th{
    color:var(--primary);
  }

  .mother-name {
    font-size: 14px;
    color: var(--dark);
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }
  }

  th,
  tbody tr,
  tbody td {
    border-top: 1px solid var(--light) !important;
  }

  h5 {
    color: var(--dark);
  }
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: var(--primary);
  box-shadow:var(--box-shadow);
  color:var(--white) !important;

  overflow: hidden;
cursor: pointer;
  position: relative;
}

.ag-courses-item_link{
  background-color:var(--primary);
  box-shadow:none;

  span.not-available{
    font-size:20px;
    color:var(--light);
  }
  
}


.dark-theme-variables .ag-courses-item_link{
  background-color:var(--light);
  box-shadow:none;

  span.not-available{
    font-size:20px;
    color:var(--light);
  }
  
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 87px;
  margin: 0 0 25px;

  overflow: hidden;

  font-weight: bold;
  font-size: 30px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.model .ag-courses-item_link{
  border-radius: var(--card-border-radius);
  margin:1rem 0;
  
  
}
.model:nth-child(2n) .ag-courses-item_bg{
  background-color: #3ecd5e;
}
.model:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}
.model:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}
.model:nth-child(5n) .ag-courses-item_bg {
  background-color: #cd3e94;
}
.model:nth-child(6n) .ag-courses-item_bg {
  background-color: #4c49ea;
}
.model:nth-child(7n) .ag-courses-item_bg {
  background-color: #2d908b;
}


.model .ag-courses-item_link{
  transform-style: preserve-3d;
  transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  .overlay{
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:transparent;
    z-index:99;

    
  }
  &.flip{
    transform: rotateY(180deg);
    
  }

  
}



.content-modal{
  display: flex;
  justify-content: center;
  padding:1rem;
  padding-top:5rem;
  width: 100vw;
  height: 100vh;
  background: var(--modal-background);
  position: fixed;
  top:0;
  z-index:9999999 !important;
  left:0;


  .modal-content{
    width:100%;
    max-width:30rem;
    height:fit-content;
    background: var(--white);
    padding:1rem;
    border-radius: var(--card-border-radius);
    
    

    .close-button{
      

      .svg-icon{
        cursor: pointer;
        svg{
          fill:#e32;
          width:2rem;
          height:2rem;
        }
      }
    }

    .center-img{
      display: flex;
      width: 100%;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    button{
        border:1px solid transparent;
        padding:.5rem 1rem;
        font-size:16px;
        color:var(--white);
        background: var(--primary);
        border-radius: var(--card-border-radius);
        margin-top:2rem;

        &:hover{
          background:var(--white);
          color: var(--primary);
          border:1px solid var(--primary);
          
        }
      }

    .items{
      h3{
        color:var(--dark);
        text-transform: capitalize;
        font-size:14px;

        &.title{
          font-size:20px;
          font-weight:600;
          text-align: center;
          text-transform: uppercase;
        }
        span{
          color:var(--dark);
          font-weight: 600;
        }
      }

    }

    .imgbox{
        width:10rem;
        height:10rem;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        
        margin-bottom:1rem;

        img.profile{
          width:100%;
          
          object-fit: cover;
        }
      }
  }
}
.upload-div{

  .image-show{
    width:100%;
    justify-content: center;
    display: flex;
    position: relative;

    

    img{
      width:150px;
      height:150px;
    
      object-fit: cover;

    }
  }

  .title{
    margin:1rem 0 2rem 0;
    text-transform: capitalize;
    font-weight:600;
    color:var(--dark);
  }

  p{
    color:var(--dark);
    margin-top:2rem;
  }

  .form-data{
    width: 100%;
    position: relative;

    .field{
      width:100%;
      display:grid;
      grid-auto-flow: column;
      grid-template-columns: 30% 70%;
      margin:.5rem 0;
      align-items: center;

      &:not(:last-child){
        
      }

      input[type="text"], input[type="number"]{
        padding:.5rem;
        max-height: 40px;
        border:1px solid transparent;
        border-radius:var(--border-radius-1);
        background:var(--light);
        color:var(--dark);

        :disabled{
          text-transform: uppercase;
          font-weight: 600;
          
        }
        
      }
    }
  }
}

.table-responsive{
  max-width:45rem;
  max-height:10rem;
  margin-top:1rem;
}

.search-box{
  margin-bottom:1rem;
  input{
    background-color:var(--light);
    color:var(--dark);
    width:100%;
    max-width:40rem;
    border:1px solid transparent;
    border-radius:var(--border-radius-1);
    padding:.5rem
  }
}

.imgbox.selectedPatient{

  width:8rem; 
  height:8rem;
  border-radius: 50%;
  overflow: hidden;

}

.split-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  margin-bottom:1rem;
}

.vitalslink{
  margin-top:1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width:fit-content;
  height:fit-content;
  padding:.5rem 1rem;
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius-1);
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;

  &:hover{
    opacity: .6;
  }

  &.danger{
    background-color: #e32;
    color: #fff;
  }
}

.content-modal.vitals{
  .add-field{
    width:100%;
    display:flex;
    justify-content: flex-end;
    margin:-2rem 0 2rem 0;

    button{
      border:1px solid transparent;
      padding:.5rem 1rem;
      font-size:16px;
      color:var(--white);
      background: var(--primary);
      border-radius: var(--border-radius-1);

      &:hover{
        background:var(--white);
        color: var(--primary);
        border:1px solid var(--primary);
      }
    }
  }
  .fields{
    margin-top:1rem;
    .field-item{
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 40% 60%;
      margin:.2rem 0;
      align-items:center;

      input{
        border-radius:var(--border-radius-1);
        padding:.2rem .5rem;
        font-size:16px;
      }
    }

    .submit-vitals{
      width:100%;
      cursor:pointer;
    }
  }
}


@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
  .table-responsive{
    max-width:100%;
    max-height:10rem;
    margin-top:1rem;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
  .imgbox.selectedPatient{

    width:5rem; 
    height:5rem;
    border-radius: 50%;
    overflow: hidden;
  
  }
}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

@media screen and (min-width: 600px) {
  main {
    padding: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .content {
    padding: 0 1rem;
  }
}
@media screen and (max-width: 400px) {
  .content {
    padding: 0 1rem;
  }
}
</style>
