<template>
  <main>
    <div class="content" v-if="userData">
      <div class="row" v-if="$store.state.usercat == 'admin'">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg1"
              ><i class="fa-solid fa-user-md" aria-hidden="true"></i
            ></span>
            <div class="dash-widget-info text-right">
              <h3>1</h3>
              <span class="widget-title1"
                >Total Doctors<i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg2"><i class="fa-solid fa-person-pregnant"></i></span>
            <div class="dash-widget-info text-right">
              <h3>{{ dashboardData.patientCount }}</h3>
              <span class="widget-title2"
                > Total Mothers <i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg3"
              ><i class="fa-solid fa-hospital" aria-hidden="true"></i
            ></span>
            <div class="dash-widget-info text-right">
              <h3>{{ dashboardData.hospitalCount || '...' }}</h3>
              <span class="widget-title3"
                >Total Hospitals <i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg4"
              ><i class="fa-solid fa-expand" aria-hidden="true"></i
            ></span>
            <div class="dash-widget-info text-right">
              <h3>{{ dashboardData.scanCount }}</h3>
              <span class="widget-title4"
                >Total AI Model Scans<i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg5"
              ><i class="fa-solid fa-bug" aria-hidden="true"></i
            ></span>
            <div class="dash-widget-info text-right">
              <h3>{{ dashboardData.scanCount }}</h3>
              <span class="widget-title5"
                >Detected Abnormalities<i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg6"
              ><i class="fa-solid fa-notes-medical" aria-hidden="true"></i
            ></span>
            <div class="dash-widget-info text-right">
              <h3>{{ dashboardData.scanCount }}</h3>
              <span class="widget-title6"
                >Available AI Models<i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
      <h3
        style="
          text-transform: capitalize;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 1rem;
          display: flex;
          justify-content: space-between;
        "
      >
        <span style="font-size: 24px" v-if="$store.state.usercat == 'hospitaladmin'"
          >{{ userData.hospitalAdminInfo.name }},
          {{ userData.hospitalAdminInfo.lga }} </span
        ><span v-if="$store.state.usercat==='hospitalAdmin'">({{ userData.hospitalAdminInfo.hospital_id }})</span>
      </h3>
      <div class="row" v-if="$store.state.usercat === 'hospitaladmin'">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg1"
              ><i class="fa-solid fa-notes-medical" aria-hidden="true"></i
            ></span>
            <div class="dash-widget-info text-right">
              <h3>13</h3>
              <span class="widget-title1"
                >Tamela AI Models<i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg2"><i class="fa-solid fa-person-pregnant"></i></span>
            <div class="dash-widget-info text-right">
              <h3>{{ dashboardData.patientCount }}</h3>
              <span class="widget-title2"
                >Patients <i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg3"
              ><i class="fa-solid fa-user-md" aria-hidden="true"></i
            ></span>
            <div class="dash-widget-info text-right">
              <h3 v-if="doctors">{{ doctors.length }}</h3>
              <h3 v-else>...</h3>
              <span class="widget-title3"
                >Doctors <i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg4"
              ><i class="fa-solid fa-expand" aria-hidden="true"></i
            ></span>
            <div class="dash-widget-info text-right">
              <h3>{{ dashboardData.scanCount }}</h3>
              <span class="widget-title4"
                >Scans<i class="fa fa-check" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget">
            <span class="dash-widget-bg5"
              ><i class="fa-solid fa-triangle-exclamation" aria-hidden="true"></i
            ></span>
            <div class="dash-widget-info text-right">
              <h3>{{ dashboardData.abnormalitiesScanCount }}</h3>
              <span class="widget-title5"
                >Abnormalities<i class="fa-solid fa-triangle-exclamation" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
     
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <div class="card">
            <div class="card-body">
              <div class="chart-title">
                <h4 v-if="$store.state.usercat === 'hospitaladmin'">
                  Scans from AI models
                </h4>
                <h4 v-if="$store.state.usercat === 'admin'">
                  Total Scans from AI models from all Medical Centres
                </h4>
                <span class="float-right"
                  ><i class="fa fa-caret-up" aria-hidden="true" v-if="graph_sign_up"></i
                  ><i class="fa fa-caret-down" aria-hidden="true" v-else></i
                  ><span style="margin-left:0.2rem;">{{ graph_month_remarks }}</span></span
                >
              </div>
              <canvas id="linegraph"></canvas>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <div class="card">
            <div class="card-body">
              <div class="chart-title">
                <h4 v-if="$store.state.usercat === 'hospitaladmin'">Abnormalities Detected</h4>
                <h4 v-if="$store.state.usercat === 'admin'">Abnormalities Detected</h4>
                <div class="float-right">
                  <ul class="chat-user-total" style="display: none">
                    <li>
                      <i class="fa fa-circle current-users" aria-hidden="true"></i>ICU
                    </li>
                    <li><i class="fa fa-circle old-users" aria-hidden="true"></i> OPD</li>
                  </ul>
                </div>
              </div>
              <canvas id="bargraph"></canvas>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-6" v-if="$store.state.usercat === 'admin'">
          <div class="card">
            <div class="card-body">
              <div class="chart-title">
               
                <h4>
                  Medical Centres using Tamela.
                </h4>
                <span class="float-right"
                  ><i class="fa fa-caret-up" aria-hidden="true" v-if="graph_sign_up"></i
                  ><i class="fa fa-caret-down" aria-hidden="true" v-else></i
                  >{{ graph_month_remarks }}</span
                >
              </div>
              <canvas id="linegraph2"></canvas>
            </div>
          </div>
        </div>
        

      </div>
      
     
      <div class="row"  v-if="$store.state.usercat === 'hospitaladmin'">
        <div class="col-12 col-md-6 col-lg-8 col-xl-8">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title d-inline-block">Recent AI Model Scans</h4>
              <router-link class="btn btn-primary float-right" to="/scans"
                >View all</router-link
              >
            </div>
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="d-none">
                    <tr>
                      <th>Mother's Name</th>
                      <th>Date and Time</th>
                      <th>Status</th>

                      <th class="text-right">Status</th>
                    </tr>
                  </thead>
                  <tbody v-if="scans && scans.length >= 1">
                    <tr v-for="(scan, index) in scans.slice(0,10)" :key="index">
                      <td style="min-width: 200px">
                        <p class="avatar"><img :src="scan.mother_data.image" alt="" /></p>
                        <h2 style="text-transform: capitalize">
                          <span class="mother-name"
                            >{{ scan.mother_data.lastname }}
                            {{ scan.mother_data.firstname }}
                            {{ scan.mother_data.othername
                            }}<span
                              >{{ scan.mother_data.city }},
                              {{ scan.mother_data.state }}</span
                            ></span
                          >
                        </h2>
                      </td>
                      <td>
                        <h5 class="time-title p-0">Scan ID</h5>
                        <p style="color: var(--primary)">{{ scan.scanID }}</p>
                      </td>
                      <td>
                        <h5 class="time-title p-0">Scanned on</h5>
                        <p>{{ moment(`${scan.date_added}`).fromNow() }}</p>
                      </td>
                      <td>
                        <h5 class="time-title p-0">Status</h5>
                        <p :class="scan.status" style="text-transform: uppercase">
                          {{ scan.status }}
                        </p>
                      </td>
                     
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4" style="">
          <div class="card member-panel">
            <div class="card-header bg-primary">
              <h4 class="card-title mb-0" style="color: #fff">Doctors </h4>
            </div>
            <div class="card-body" style="margin-top: 1rem">
              <ul class="contact-list" v-if="doctors">
                <li v-for="(doctor, index) in doctors" :key="index">
                  <div class="contact-cont" style="vertical-align: middle">
                    <div class="float-left m-r-10">
                      <div class="dashboard imgbox">
                        <img
                          v-if="doctor.staff_user_details.image"
                          :src="doctor.staff_user_details.image"
                          alt=""
                          class=""
                        />
                        <img
                          v-else
                          :src="require('../assets/img/user.jpg')"
                          alt=""
                          class=""
                        />
                        <span
                          class="status online"
                          v-if="doctor.status == 'active'"
                        ></span>
                        <span
                          class="status offline"
                          v-if="doctor.status == 'inactive'"
                        ></span>
                        <span
                          class="status away"
                          v-if="doctor.status == 'pending'"
                        ></span>
                      </div>
                    </div>
                    <div class="contact-info">
                      <span
                        class="contact-name text-ellipsis"
                        style="text-transform: capitalize"
                        >{{ doctor.staff_user_details.name }}</span
                      >
                      <span class="contact-date" style="text-transform: capitalize">{{
                        doctor.role
                      }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-footer text-center">
              <router-link
                to="/managedoctors"
                class="text-muted"
                style="color: var(--primary) !important"
                >View all Doctors</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title d-inline-block">Activities</h4>
              <router-link to="/mothers" class="btn btn-primary float-right"
                >View all</router-link
              >
            </div>
            <div class="card-block">
              <div class="table-responsive">
                <table class="table mb-0 new-patient-table">
                 <ul class="activities" v-if="activities">
                  <li class="activity success" v-for="messages,index in activities.slice(0,10)" :key=index>
                    <h4 class="activity-title">{{messages.message}}</h4>
                    <small>{{ moment(`${messages.date_added}`).fromNow() }}</small>
                  </li>
                  
                 </ul>
                 <h3 style="color:var(--warning);text-align: center;padding:1rem;" v-else> No Activities Yet.</h3>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-6" v-if="$store.state.usercat==='admin'">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title d-inline-block">Medical Centres</h4>
              <router-link class="btn btn-primary float-right" to="/hospitals"
                >View all</router-link
              >
            </div>
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="d-none">
                    <tr>
                      <th>Centre Name</th>
                      
                      <th>Status</th>

                      <th class="text-right">Status</th>
                    </tr>
                  </thead>
                  <tbody v-if="hospitals && hospitals.length >= 1">
                    <tr v-for="(hospital, index) in hospitals" :key="index">
                      <td style="min-width: 200px">
                        <h2 style="text-transform: capitalize">
                          
                          <span class="mother-name" style="text-transform: capitalize;color:var(--primary);font-size: 16px;"
                            >
                            {{ hospital.hospitalAdminInfo.name }}
                            <span style="font-size: 12px;"
                              ><a :href="'tel:'+ hospital.contact_phone_number">{{ hospital.contact_phone_number }}</a>,
                              <a :href="'mailto:' + hospital.contactEmail">{{ hospital.contactEmail }}</a></span
                            ></span
                          >
                        </h2>
                      </td>
                      <td>
                        <h5 class="time-title p-0">Tamela ID</h5>
                        <p style="color: var(--primary)"> {{ hospital.hospitalAdminInfo.hospital_id }}</p>
                      </td>
                     
                      <td>
                        <h5 class="time-title p-0">Status</h5>
                        <p :class="{'success':hospital.isActive,'failed':!hospital.isActive}" style="text-transform: uppercase">
                          {{ hospital.isActive ? "active" : "inactive" }}
                        </p>
                      </td>
                     
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-4" style="display: none">
          <div class="hospital-barchart">
            <h4 class="card-title d-inline-block">Hospital Management</h4>
          </div>
          <div class="bar-chart">
            <div class="legend">
              <div class="item">
                <h4>Level1</h4>
              </div>

              <div class="item">
                <h4>Level2</h4>
              </div>
              <div class="item text-right">
                <h4>Level3</h4>
              </div>
              <div class="item text-right">
                <h4>Level4</h4>
              </div>
            </div>
            <div class="chart clearfix">
              <div class="item">
                <div class="bar">
                  <span class="percent">16%</span>
                  <div class="item-progress" data-percent="16">
                    <span class="title">OPD Patient</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="bar">
                  <span class="percent">71%</span>
                  <div class="item-progress" data-percent="71">
                    <span class="title">New Patient</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="bar">
                  <span class="percent">82%</span>
                  <div class="item-progress" data-percent="82">
                    <span class="title">Laboratory Test</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="bar">
                  <span class="percent">67%</span>
                  <div class="item-progress" data-percent="67">
                    <span class="title">Treatment</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="bar">
                  <span class="percent">30%</span>
                  <div class="item-progress" data-percent="30">
                    <span class="title">Discharge</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </main>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
var moment = require("moment");
import { Chart } from "../assets/js/Chart.bundle";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
  data() {
    return {
      dashboardData: {
        patientCount: "...",
        scanCount: "...",
        lineChartData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        lineChartData2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        lineChartData3:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        hospitalCount: "...",
        doctorCount: "...",
        abnormalitiesScanCount:"..."
      },
      userData: null,
      graph_sign_up: true,
      graph_month_remarks: "",
      moment: moment,
      scans: [],
      mothers: [],
      doctors: null,
      hospitals:null,
      activities:null,
    };
  },
  mounted() {
    document.title = "Tamela Dashboard";
    this.$root.showLoader = true;
    document.querySelector(".page-wrapper").style.paddingTop = "70px";
    this.$root.loadScripts();
    this.$root.resetSideBar();
    
    if (this.$store.state.usercat === "doctor") {
      this.$router.push("/models");
    }
    else if (this.$store.state.usercat !== "admin" && this.$store.state.usercat !== "hospitaladmin") {
      this.$router.push("/\n");
    } else {
      this.$store.commit("currentPage", "dashboard");
      this.getUserData();
      this.getDashboardData();
      this.getActivities();

      if(this.$store.state.usercat == 'hospitaladmin'){
        this.getMothers();
        this.getScans();
        this.getDoctors();
      } else if(this.$store.state.usercat == 'admin') {
        this.getHospitals();
      }
    }
  },
  methods: {
    async getUserData() {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      try {
        const response = await axios.get(
          "accounts/userdata?user=current&usercat="+this.$store.state.usercat,
          { headers }
        );
        this.userData = response.data.data;
        console.log(this.userData);
        
        this.$root.showLoader = false;

      } catch (error) {
        if (error.response) {
          // Error responses from the server
          const status = error.response.status;
          if (status === 401) {
            // Unauthorized access
            await this.refreshToken(this.getUserData);
          } else {
            // Handle other status codes
            console.error(`Error ${status}: ${error.response.data.message}`);
            toast.error(`Error ${status}: ${error.response.data.message}`, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (error.request) {
          // Request made but no response received
          console.error("No response received:", error.request);
          toast.error("No response received. Please try again later.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Other errors
          console.error("Error", error);
          toast.error(`Error: ${error}`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
    async refreshToken(callback) {
      try {
        const response = await axios.post("api/token/refresh", {
          refresh: this.$store.state.refresh,
        });

        if (response.data.access) {
          this.$store.commit("setAccess", response.data.access);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.access;
          await callback();
        } else {
          this.$router.push("/login");
        }
      } catch (error) {
        console.error("Failed to refresh token", error);
        this.$router.push("/login");
      }
    },
    async getDoctors() {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      try {
        const response = await axios.get("accounts/doctors?user=current", { headers });
        this.doctors = response.data.data;
        console.log(this.doctors);
      } catch (error) {
        if (error.response) {
          // Error responses from the server
          const status = error.response.status;
          if (status === 401) {
            // Unauthorized access
            await this.refreshToken(this.getDoctors);
          } else {
            // Handle other status codes
            console.error(`Error ${status}: ${error.response.data.message}`);
            toast.error(`Error ${status}: ${error.response.data.message}`, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (error.request) {
          // Request made but no response received
          console.error("No response received:", error.request);
          toast.error("No response received. Please try again later.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Other errors
          console.error("Error", error);
          toast.error(`Error: ${error}`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
    async getActivities() {
  const accessToken = this.$store.state.access;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get('api/v1/activities', { headers });
    this.activities = response.data.data;
    this.$root.showLoader = false;

  } catch (error) {
    if (error.response) {
      // Handle errors from the server
      const status = error.response.status;
      if (status === 401) {
        // Unauthorized access, refresh the token
        await this.refreshToken(this.getActivities);
      } else {
        // Handle other status codes
        console.error(`Error ${status}: ${error.response.data.message}`);
        toast.error(`Error ${status}: ${error.response.data.message}`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (error.request) {
      // Request made but no response received
      console.error("No response received:", error.request);
      toast.error("No response received. Please try again later.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (error.code === "ERR_NETWORK") {
      // Handle network errors
      console.error("Network error:", error);
      toast.error("Unable to connect. Check your network connection.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // Other errors
      console.error("Error", error);
      toast.error(`Error: ${error.message}`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
},
    addExtraChart(){
      let currentMonth = new Date().getMonth();
      let prevMonth = currentMonth >= 1 ? currentMonth - 1 : 11;
      let currentMonthValue = this.dashboardData.lineChartData[currentMonth];
      let prevMonthValue =
        this.dashboardData.lineChartData[prevMonth] == 0
          ? 1
          : this.dashboardData.lineChartData[prevMonth];

      // Calculate the percentage raise or decrease
      let percentageRaise = ((currentMonthValue - prevMonthValue) * 100) / prevMonthValue;

      // Use Math.abs() to get the absolute value of the percentage raise
      percentageRaise = Math.abs(percentageRaise);

      //console.log(percentageRaise);

      if (currentMonthValue >= prevMonthValue) {
        this.graph_month_remarks = `${percentageRaise.toFixed(
          2
        )}% increase from last month`;
        this.graph_sign_up = true;
      } else {
        this.graph_month_remarks = `${percentageRaise.toFixed(
          2
        )}% decrease from last month`;
        this.graph_sign_up = false;
      }
      let lineChartData = {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Number of Scans",
            backgroundColor: "rgba(42, 45, 116, 0.5)",
            data: [10,25,11,34,13,10,25,11,34,13,20,25]// this.dashboardData.lineChartData,
          },
          {
            label: "No of Patients",
            backgroundColor: "rgba(224, 104, 58, 0.7)",
            fill: true,
            data:[10,25,11,34,10,25,11,34,13,13,20,25]// this.dashboardData.lineChartData2, //[10,22,31,34,13,53,63,23,13,42,55,23]
          },
        ],
      };

      let linectx = document.getElementById("linegraph2").getContext("2d");
      window.myLine = new Chart(linectx, {
        type: "line",
        data: lineChartData,
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
        },
      });
    },
    setChartData() {

      if(this.$store.state.usercat === 'admin'){
        this.addExtraChart();
      }
      
      //this.dashboardData.lineChartData = [10,22,31,34,13,53,63,23,13,42,55,23]
      let currentMonth = new Date().getMonth();
      let prevMonth = currentMonth >= 1 ? currentMonth - 1 : 11;
      let currentMonthValue = this.dashboardData.lineChartData[currentMonth];
      let prevMonthValue =
        this.dashboardData.lineChartData[prevMonth] == 0
          ? 1
          : this.dashboardData.lineChartData[prevMonth];

      // Calculate the percentage raise or decrease
      let percentageRaise = ((currentMonthValue - prevMonthValue) * 100) / prevMonthValue;

      // Use Math.abs() to get the absolute value of the percentage raise
      percentageRaise = Math.abs(percentageRaise);

      //console.log(percentageRaise);

      if (currentMonthValue >= prevMonthValue) {
        this.graph_month_remarks = `${percentageRaise.toFixed(
          2
        )}% increase from last month`;
        this.graph_sign_up = true;
      } else {
        this.graph_month_remarks = `${percentageRaise.toFixed(
          2
        )}% decrease from last month`;
        this.graph_sign_up = false;
      }
      let lineChartData = {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Number of Scans",
            backgroundColor: "rgba(42, 45, 116, 0.5)",
            data: this.dashboardData.lineChartData,
          },
          {
            label: "No of Patients",
            backgroundColor: "rgba(224, 104, 58, 0.7)",
            fill: true,
            data: this.dashboardData.lineChartData2, //[10,22,31,34,13,53,63,23,13,42,55,23]
          },
        ],
      };

      let linectx = document.getElementById("linegraph").getContext("2d");
      window.myLine = new Chart(linectx, {
        type: "line",
        data: lineChartData,
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
        },
      });
      

      var barChartData = {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Abnormalities",
            backgroundColor: "rgba(0, 158, 251, 0.5)",
            borderColor: "rgba(0, 158, 251, 1)",
            borderWidth: 1,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            label: "Dataset 2",
            backgroundColor: "rgba(255, 188, 53, 0.5)",
            borderColor: "rgba(255, 188, 53, 1)",
            borderWidth: 1,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            label: "Dataset 3",
            backgroundColor: "rgba(231, 76, 60, 0.5)",
            borderColor: "rgba(231, 76, 60, 0.5)",
            borderWidth: 1,
           
            data:  this.dashboardData.lineChartData3,
          },
        ],
      };

      var ctx = document.getElementById("bargraph").getContext("2d");
      window.myBar = new Chart(ctx, {
        type: "bar",
        data: barChartData,
        options: {
          responsive: true,
          legend: {
            display: false,
          },
        },
      });
    },
    async getDashboardData() {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      try {
        const response = await axios.get("api/v1/dashboard/data", { headers });
        const data = response.data.data;
        console.log(data);

        this.dashboardData.patientCount = data.patient_count;
        this.dashboardData.scanCount = data.scan_count;
        this.dashboardData.lineChartData = data.scan_count_list;
        this.dashboardData.lineChartData2 = data.patient_count_list;
        this.dashboardData.lineChartData3 = data.abnormality_count_list;
        this.dashboardData.hospitalCount = data.hospitalCount;
        this.dashboardData.abnormalitiesScanCount = data.abnormalitiesScanCount;

        this.setChartData();
        this.$root.showLoader = false;

      } catch (error) {
        if (error.response) {
          // Handle errors from the server
          const status = error.response.status;
          if (status === 401) {
            // Unauthorized access, refresh the token
            await this.refreshToken(this.getDashboardData);
          } else {
            // Handle other status codes
            console.error(`Error ${status}: ${error.response.data.message}`);
            toast.error(`Error ${status}: ${error.response.data.message}`, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (error.request) {
          // Request made but no response received
          console.error("No response received:", error.request);
          toast.error("No response received. Please try again later.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (error.code === "ERR_NETWORK") {
          // Handle network errors
          console.error("Network error:", error);
          toast.error("Unable to connect. Check your network connection.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Other errors
          console.error("Error", error);
          toast.error(`Error: ${error.message}`, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
    isIOS() {
      const userAgent = navigator.userAgent;
      return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    },
    async getScans(mother = null) {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      await axios
        .get("api/v1/scan?usercat=" + this.$store.state.usercat, {
          headers,
        })
        .then((response) => {
          this.scans = response.data.data;
          this.$root.showLoader = false;
        })
        .catch((error) => {
          if (error.code != "ERR_NETWORK") {
            if (error.response.status === 401) {
              this.refreshToken(this.getScans);
            }
          } else {
            console.error(error);
            const error_message =
              error.code === "ERR_NETWORK"
                ? "Unable to Connect. Check your network connection."
                : error.code;
            toast.error(error_message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    },
    async getMothers(mother = null) {
      const accessToken = this.$store.state.access;
      const headers = { Authorization: `Bearer ${accessToken}` };

      await axios
        .get("accounts/mothers?hospital=current", { headers })
        .then((response) => {
          this.mothers = response.data.data;
          this.$root.showLoader = false;
        })
        .catch((error) => {
          if (error.code != "ERR_NETWORK") {
            if (error.response.status === 401) {
              this.refreshToken(this.getMothers);
            }
          } else {
            console.error(error);
            const error_message =
              error.code === "ERR_NETWORK"
                ? "Unable to Connect. Check your network connection."
                : error.code;
            toast.error(error_message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    },
    async getHospitals(pk = null) {
        const accessToken = this.$store.state.access;
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
  
        if (!pk) {
          await axios
            .get('accounts/hospitals', { headers })
            .then(response => {
  
              this.hospitals = response.data.data;
              console.log(this.hospitals);
              this.$root.showLoader = false;
            })
            .catch(error => {
               if (error.code != 'ERR_NETWORK') {
                    if (error.response.status === 401) {
                      this.refreshToken(this.getHospitals)
                    }
              } else {
                    console.error(error)
                    const error_message = error.code==='ERR_NETWORK'?'Unable to Connect. Check your network connection.':error.code
                      toast.error(error_message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, })
                  }
              //this.$root.showLoader = false;
            })
            
        }
      },
  },
};
</script>

<style lang="scss" scoped>
td {
  color: var(--dark);
}
.awaiting {
  color: var(--primary);
}
.success {
  color: var(--success);
}
.failed {
  color: var(--danger);
}

.card {
  background: var(--white) !important;
  border-radius: var(--card-border-radius) !important;
  box-shadow: var(--box-shadow);
}

table {
  border: 1px solid var(--light);

  .mother-name {
    font-size: 14px;
    color: var(--dark);
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }
  }

  th,
  tbody tr,
  tbody td {
    border-top: 1px solid var(--light) !important;
  }

  h5 {
    color: var(--dark);
  }
}

.dashboard.imgbox {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.activities{
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: var(--white);
  height: fit-content;
  max-height: 20rem !important;
  overflow: auto;
  .activity{
    padding: 1rem;
    background: var(--white);
    margin-bottom: .5rem;
    list-style:none;
    box-shadow:var(--box-shadow);
    border-left:1rem solid var(--primary);
    border-radius:.5rem;
  
    
    h4, small{
      color: var(--dark);
    }
    small{
      font-size:12px;
      color:#888;
    }

    &.success{
      border-left:1rem solid var(--success);
    }
    &.awaiting{
      border-left:1rem solid var(--primary);
    }
    &.failed{
      border-left:1rem solid var(--danger);
    }
  }
}

@media screen and (min-width: 600px) {
  main {
    padding: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .content {
    padding: 0 1rem;
  }
}
@media screen and (max-width: 400px) {
  .content {
    padding: 0 1rem;
  }
}
</style>
