import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
export default createStore({
  state: {
    refresh: null,
    access:null,
    currentPage:'dashboard',
    isDark: false,
    doctor_username: null,
    usercat:null,
    userdata:null,
    selectedPatient:null,
    userImage:null,
    botText:[
      {
        user:'model',
        message:'Hello, How can i be of assistance?'
      }
    ],
  },
  getters: {
  },
  mutations: {
    setRefresh(state, refresh) {
          state.refresh = refresh
      },
    setAccess(state, access) {
          state.access = access
      },
    setUsercat(state, usercat) {
          state.usercat = usercat
      },
    setUserdata(state, userdata) {
          state.userdata = userdata
      },
    setDoctorUid(state, doctor_username) {
          state.doctor_username = doctor_username
      },
    setDoctorUsercat(state, doctor_usercat) {
          state.doctor_usercat = doctor_usercat
      },
    currentPage(state, currentPage) {
          state.currentPage = currentPage
      },
    selectPatient(state, selectedPatient) {
          state.selectedPatient = selectedPatient
      },
    isDark(state, isDark) {
          state.isDark = isDark
    },
    setUserImage(state, userImage) {
          state.userImage = userImage
    },
    setChatMessages(state, chatMessages) {
          state.botText.push(chatMessages);
    },
    clearChatMessages(state) {
          state.botText = [
            {
              user:'model',
              message:'Hello, How can i be of assistance?'
            }
          ]
    },
    
    resetStore(state) {
      state.refresh = null;
      state.access = null;
      state.isDark = false;
      state.currentPage = 'dashboard';
      state.doctor_username = null;
      state.userImage = null;
      state.selectedPatient = null;
      state.botText=[
        {
          user:'model',
          message:'Hello, How can i be of assistance?'
        }
      ]
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      // Options to configure persistence
      storage: window.sessionStorage, //window.localStorage,
    })
  ]
})
